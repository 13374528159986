import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { taskStatusApi } from '../api/task'
import { taskStatus } from '../querykeys/task'

export const useChangeStatusTask = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(taskStatusApi.jobslipTask, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([taskStatus.JOBSLIPTASK])
      // navigate(`/job-slips-number`, { state: { activeTab: "1" } });
      // const data = res.data
      // navigate(`/job-slips-number/${data?.id}`)
      // console.log('data=====', data)
      navigate('/job-slips')
    },
    onError: (err) => {
      return err
      // const errData = err?.data;
      // toast.error(errData.message);
    }
  })
}
export const useOverdueStatusTask = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(taskStatusApi.overdueTask, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([taskStatus.OVERDUETASK])
      // navigate('/job-slips-number', { state: { activeTab: '3' } })
      // const data = res.data
      // navigate(`/job-slips-number/${data?.id}`)
      navigate('/dept-wise-task')
    },
    onError: (err) => {
      return err
      // const errData = err?.data;
      // toast.error(errData.message);
    }
  })
}

export const useDepartmentWiseKanban = (departmentId) => {
  return useQuery([taskStatus.DEPARTMENTWISEKANBAN, departmentId], taskStatusApi.getDepartmentWiseKanban, {
    onSuccess: (data) => {
      // queryClient.setQueryData([taskStatus.DEPARTMENTWISEKANBAN], 'hi')
    },
    keepPreviousData: true,
    // enabled: isEnabled,
    refetchOnWindowFocus: false
  })
}

export const useMachineWiseKanban = (id, isEnable, departmentId) => {
  return useQuery([taskStatus.MACHINEWISEKANBAN, id, departmentId], taskStatusApi.getMachineWiseKanbanView, {
    enabled: isEnable,
    onSuccess: (data) => {
      // queryClient.setQueryData([taskStatus.MACHINEWISEKANBAN], 'hi')
    },
    keepPreviousData: true,
    // enabled: isEnabled,
    refetchOnWindowFocus: false
  })
}

export const useRestoreTask = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(taskStatusApi.restoreTask, {
    onSuccess: () => {
      queryClient.invalidateQueries([taskStatus.TASKS])
      // ! TODO: for future implementation toast
      // queryClient.invalidateQueries([part.PART, id]);
      // toast.success(data.message);
      navigate('/deleted-items')
    }
    // ! TODO: for future implementation toast
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  })
}

export const useHardDeleteTask = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(taskStatusApi.hardDeleteTask
    , {
      onSuccess: () => {
        queryClient.invalidateQueries([taskStatus.TASKS])
        navigate('/deleted-items')
      }
    // ! TODO: for future implementation toast
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    })
}
