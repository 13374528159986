import React, { useState } from 'react'
import { Box, CircularProgress, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import WrapperLineChart from '../../leastcomponent/lineChart/WrapperLineChart'
import BarChart from '../../leastcomponent/barChart/BarChart'
import ProgressBar from '../../leastcomponent/progressBar/ProgressBar'
import '../dashboard/dashboard.css'
import CardInfo from '../../leastcomponent/cardInfo/cardInfo'
import Card from '../../leastcomponent/cardInfo/card'
import TableWrapper from '../../leastcomponent/dashboardTableWrapper/TableWrapper'
import Activity from '../../leastcomponent/Activity/Activity'
import RecentActivityCard from '../../leastcomponent/Activity/RecentActivityCard'
import DelayWrapper from '../../leastcomponent/DelayedJobCard/DelayWrapper'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import DateRange from '../../leastcomponent/daterabgepicker/customdaterangepicker'
import moment from 'moment'
import { useDashboardJobcardStatus, useDashboardJobcardTaskStatus, useDashboardProgressReport, useDashboardRecentActivity, useDashboardTaskStatus } from '../../hooks/dashboard'
import { useGetFullDepartments } from '../../hooks/departmentHooks'
import CustomSelect from '../../leastcomponent/customselect/customselect'

const DashBoard = () => {
  const currentDate = moment()
  const weekStart = currentDate.clone().startOf('today')
  const weekEnd = currentDate.clone().endOf('today')
  const [ganttDates, setGranttDatesState] = React.useState({
    startDate: moment(weekStart).format('DD MMM, YY'),
    endDate: moment(weekEnd).format('DD MMM, YY')
  })
  const setGranttDates = (e, { startDate, endDate }) => {
    setGranttDatesState({
      startDate: startDate.format('DD MMM, YY '),
      endDate: endDate.format(' DD MMM, YY')
    })
  }

  const [selectedDepartment, setSelectedDepartment] = useState('')

  const handleSelectChange = (selectedOption) => {
    setSelectedDepartment(selectedOption?.target?.value)
  }

  // dapartment full list api
  const { data: departmentData } = useGetFullDepartments()

  // dashboard recent activity api useDashboardRecentActivity

  const { data: dashboardRecentData, isFetching: isDashboardRecentData } = useDashboardRecentActivity(true, ganttDates)
  const dashboardRecent = dashboardRecentData?.data

  // dashboard progress bar api
  const { data: dashboardProgressData, isFetching: isDashboardProgressData } = useDashboardProgressReport(true, ganttDates)
  const dashboardProgress = dashboardProgressData?.data

  // Status wise Count
  const { data: useDashboardJobcardOngoing, isFetching: isUseDashboardJobcardOngoing } = useDashboardJobcardTaskStatus(true, 1, 'ongoing', ganttDates)
  const { data: useDashboardJobcardCompleted, isFetching: isUseDashboardJobcardCompleted } = useDashboardJobcardTaskStatus(true, 1, 'completed', ganttDates)
  // JobcardStatus wise table api
  const { data: dashboardTaskStatusApproved, isFetching: isDashboardTaskStatusApproved } = useDashboardJobcardStatus(true, 1, 'approved', ganttDates, selectedDepartment)
  const { data: dashboardTaskStatusCompleted, isFetching: isDashboardTaskStatusCompleted } = useDashboardJobcardStatus(true, 1, 'scheduled', ganttDates, selectedDepartment)
  // Task Status wise table api
  const { data: dashboardTaskStatusOngoing, isFetching: isDashboardTaskStatusOngoing } = useDashboardTaskStatus(true, 1, 'ongoing', ganttDates, selectedDepartment)
  const { data: dashboardTaskStatusPending, isFetching: isDashboardTaskStatusPending } = useDashboardTaskStatus(true, 1, 'pending', ganttDates, selectedDepartment)

  const rowsPending = dashboardTaskStatusPending?.map((data, index) => {
    return {
      no: index + 1,
      id: data?.id,
      name: data?.name ? data?.name : '-',
      part: data?.bomparts?.partName ? data?.bomparts?.partName : '-',
      date: data?.createdAt ? moment(data?.createdAt).format('DD/MM/YYYY') : '-'
    }
  })
  const rowsApproved = dashboardTaskStatusApproved?.map((data, index) => {
    return {
      no: index + 1,
      id: data?.id,
      name: data?.name ? data?.name : '-',
      part: data?.bomparts?.partName ? data?.bomparts?.partName : '-',
      date: data?.createdAt ? moment(data?.createdAt).format('DD/MM/YYYY') : '-'
    }
  })
  const rowsOngoing = dashboardTaskStatusOngoing?.map((data, index) => {
    return {
      no: index + 1,
      id: data?.id,
      name: data?.name ? data?.name : '-',
      part: data?.bomparts?.partName ? data?.bomparts?.partName : '-',
      date: data?.createdAt ? moment(data?.createdAt).format('DD/MM/YYYY') : '-'
    }
  })
  const rowsCompleted = dashboardTaskStatusCompleted?.map((data, index) => {
    return {
      no: index + 1,
      id: data?.id,
      name: data?.name ? data?.name : '-',
      part: data?.bomparts?.partName ? data?.bomparts?.partName : '-',
      date: data?.createdAt ? moment(data?.createdAt).format('DD/MM/YYYY') : '-'
    }
  })

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('lg'))
  const barChartData = [
    {
      name: 'Planned',
      color: '#BF0B4C',
      data: [
        {
          name: '',
          y: 70
        }
      ]
    },
    {
      name: 'Actual',
      color: '#ABE6FF',
      data: [
        {
          name: '',
          y: 80
        }
      ]
    }
  ]
  const columnChartData = [
    {
      name: 'Time (hrs)',
      color: '#ABE6FF',
      data: [
        {
          name: 'week 1',
          y: 28
        },
        {
          name: 'week 2',
          y: 17
        },
        {
          name: 'week 3',
          y: 15
        },
        {
          name: 'week 4',
          y: 24
        },
        {
          name: 'week 5',
          y: 20
        },
        {
          name: 'week 6',
          y: 28
        }
        // {
        //   name: 'week 7',
        //   y: 80
        // }
      ]
    }
  ]

  const columns = [
    { field: 'no', headerName: '#', width: 70, flex: matches ? 0.2 : 0 },
    { field: 'part', headerName: 'Part', width: 150, flex: matches ? 1 : 0.7 },
    {
      field: 'name',
      headerName: 'Job Card',
      width: 150,
      flex: matches ? 1 : 0.7
    },
    { field: 'date', headerName: 'Due Date', flex: matches ? 1 : 0.7 }
  ]
  const delayWrapper = [
    {
      title: '1. Job Card name',
      number: '',
      time: '-',
      flex: matches ? 0.5 : 0.2
    },
    {
      title: '2. Job new name',
      number: '-',
      time: '-',
      flex: matches ? 1 : 0.7
    },
    {
      title: '3. Job Test name',
      number: '-',
      time: '-',
      flex: matches ? 1 : 0.7
    },
    {
      title: '4. Job Test name',
      number: '-',
      time: '-',
      flex: matches ? 1 : 0.7
    },
    {
      title: '5. Job Test14 name',
      number: '-',
      time: '-',
      flex: matches ? 1 : 0.7
    }
  ]
  const options = departmentData?.data?.map((item) => {
    return {
      value: item?.id,
      label: item?.name
    }
  })
  return (
    <React.Fragment>
      <Box className="dash-border-main-box">
        <Box>
          <BreadcrumbNavigation
            breadCrumbData={null}
            pageTitle={'Dashboard'}
            backToLink={null}
          />
        </Box>
        <Box className="dash-border-box-over">
          <Box sx={{ padding: '16px 20px', display: 'flex', gap: '10px' }}>
            <Box>
              <DateRange
                setDatesState={setGranttDatesState}
                dates={ganttDates}
                setDates={setGranttDates}
              />
            </Box>
            <Box sx={{ width: '228px' }}>
        <CustomSelect
        name="department"
        isRequired={false}
        islabel={false}
        placeholder="Select Department"
        className="default-select-new"
        options={options}
        value={selectedDepartment}
        handleSelectChange={handleSelectChange}
      />
            </Box>
          </Box>
          <Box sx={{ padding: '16px 20px' }}>
            <Grid container className="parent-details-info">
              {/* //! 8-part here */}

              <Grid item xs={4} xl={8.1} md={8}>
                <Grid container spacing={2} className="parent-details-info">
                  <Grid item xs={4} xl={10} md={8}>
                    {!isUseDashboardJobcardOngoing
                      ? <Box className="parent-job-data">
                      <Typography className="ongoing-title">
                        {' '}
                        Ongoing{' '}
                      </Typography>
                      <CardInfo title="Job Cards" count={useDashboardJobcardOngoing?.jobCardCount ? useDashboardJobcardOngoing?.jobCardCount : '0'}
                      // data={useDashboardJobcardOngoing?.onTimeJobCard ? useDashboardJobcardOngoing?.onTimeJobCard : '0'}
                      data={'-'}
                       />
                      <CardInfo title="Tasks" count={useDashboardJobcardOngoing?.taskCount ? useDashboardJobcardOngoing?.taskCount : '0'}
                      // data={useDashboardJobcardOngoing?.onTimeTask ? useDashboardJobcardOngoing?.onTimeTask : '0'}
                      data={'-'}
                      />
                    </Box>
                      : <Box className='progress-parent-card-info'>
                <CircularProgress />
              </Box>}
                  </Grid>
                  <Grid item xs={4} xl={2} md={4} className="single-job-parent">
                    <Card title="Average Throughput Time" data="-" />
                  </Grid>
                  <Grid item xs={4} xl={10} md={8}>
                    {!isUseDashboardJobcardCompleted
                      ? <Box className="parent-job-data">
                      <Typography className="ongoing-title">
                        {' '}
                        Completed{' '}
                      </Typography>
                      <CardInfo title="Job Cards" count={useDashboardJobcardCompleted?.jobCardCount ? useDashboardJobcardCompleted?.jobCardCount : '0'}
                      // data={useDashboardJobcardCompleted?.onTimeJobCard ? useDashboardJobcardCompleted?.onTimeJobCard : '0'}
                      data={'-'}
                      />
                      <CardInfo title="Tasks" count={useDashboardJobcardCompleted?.taskCount ? useDashboardJobcardCompleted?.taskCount : '0'}
                      // data={useDashboardJobcardCompleted?.onTimeTask ? useDashboardJobcardCompleted?.onTimeTask : '0'}
                      data={'-'}
                      />
                    </Box>
                      : <Box className='progress-parent-card-info'>
                <CircularProgress />
              </Box>}
                  </Grid>
                  <Grid item xs={4} xl={2} md={4} className="single-job-parent">
                    <Card title="Resource Efficiency" data="-" />
                  </Grid>

                  <Grid item xs={4} xl={10} md={8}>
                  {!isDashboardProgressData
                    ? <Box className="parent-job-details">
                      <Typography className="job-title">
                        Job Card Status
                      </Typography>
                      <Grid container spacing={1.5}>
                        <Grid item xs={6} xl={6} md={12}>
                          <ProgressBar
                            title="Orders in progress"
                            value={dashboardProgress?.orderInProgress}
                            color="#54D14D"
                          />
                        </Grid>
                        <Grid item xs={6} xl={6} md={12}>
                          <ProgressBar
                            title="Orders delayed"
                            value={dashboardProgress?.orderDelayed}
                            color="#0160B9"
                          />
                        </Grid>
                        <Grid item xs={6} xl={6} md={12}>
                          <ProgressBar
                            title="Orders not started"
                            value={dashboardProgress?.orderNotStarted}
                            color="#EF7E5D"
                          />
                        </Grid>
                        <Grid item xs={6} xl={6} md={12}>
                          <ProgressBar
                            title="Orders on hold"
                            value={dashboardProgress?.orderOnHold}
                            color="#6395E1"
                          />
                        </Grid>
                        <Grid item xs={6} xl={6} md={12}>
                          <ProgressBar
                            title="Orders completed"
                            value={dashboardProgress?.orderCompleted}
                            color="#EF1060"
                          />
                        </Grid>
                      </Grid>
                      </Box>
                    : <Box className='progress-parent-dashboard-jobcard-status'>
                <CircularProgress />
              </Box>}

                  </Grid>
                  <Grid item xs={4} xl={2} md={4} sx={{ paddingRight: '16px' }}>
                    <Box className="parent-product-data">
                      <Card title="Product Defects" data="-" />
                      <Card title="Rejections" data="-" />
                      <Card title="Process Deviations" data="-" />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {/* //! 4-part here */}
               <Grid
                item
                xs={4}
                xl={3.9}
                md={4}
                sx={{ border: '1px solid #DEDEDE' }}
              >
              {!isDashboardRecentData
                ? <Activity
                  Component={RecentActivityCard}
                  height={'533px'}
                  title={'Recent Activity'}
                  isViewAll={true}
                  viewAllLink={'/recent-activity'}
                  data={dashboardRecent || []}
                />
                : <Box className='progress-parent12'>
                <CircularProgress />
              </Box>}
              </Grid>

            </Grid>
            <Grid
              container
              spacing={2}
              // rowSpacing={2}
              // columnSpacing={2}
              className="parent-details-one"
            >
              <Grid item xs={4} xl={4} md={12} lg={6}>
              { !isDashboardTaskStatusApproved
                ? <TableWrapper
                  rows={rowsApproved}
                  columns={columns}
                  isShowFiveRecords={true}
                  title="Approved Tasks"
                />
                : <Box className='progress-parent-dashboard-table'>
                <CircularProgress />
              </Box>}
              </Grid>
              <Grid item xs={4} xl={4} md={12} lg={6}>
                {!isDashboardTaskStatusPending
                  ? <TableWrapper
                  rows={rowsPending}
                  columns={columns}
                  isShowFiveRecords={true}
                  title="Pending Tasks"
                />
                  : <Box className='progress-parent-dashboard-table'>
                <CircularProgress />
              </Box>}
              </Grid>
              <Grid item xs={4} xl={4} md={12} lg={6}>
                <BarChart
                  title="Workstation utilization"
                  type="bar"
                  data={barChartData}
                  barChartData={true}
                  height={160}
                />
              </Grid>
              <Grid item xs={4} xl={4} md={12} lg={6}>
                {!isDashboardTaskStatusCompleted
                  ? <TableWrapper
                  rows={rowsCompleted}
                  columns={columns}
                  isShowFiveRecords={true}
                  title="Scheduled Tasks"
                />
                  : <Box className='progress-parent-dashboard-table'>
                <CircularProgress />
              </Box>}
              </Grid>
              <Grid item xs={4} xl={4} md={12} lg={6}>
                {!isDashboardTaskStatusOngoing
                  ? <TableWrapper
                  rows={rowsOngoing}
                  columns={columns}
                  isShowFiveRecords={true}
                  title="Ongoing Tasks"
                />
                  : <Box className='progress-parent-dashboard-table'>
                <CircularProgress />
              </Box>}
              </Grid>
              <Grid item xs={4} xl={4} md={12} lg={6}>
                <WrapperLineChart title="WIP Levels" height={216} />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="parent-details-info">
              <Grid
                item
                xs={4}
                xl={6}
                md={12}
                lg={6}
                className="parent-spacing"
              >
                <DelayWrapper data={delayWrapper} />
              </Grid>
              <Grid
                item
                xs={4}
                xl={6}
                md={12}
                lg={6}
                className="parent-spacing"
              >
                <BarChart
                  title="Average Throughput Time (hrs)"
                  type="column"
                  data={columnChartData}
                  isGridLine={true}
                  height="278"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default DashBoard
