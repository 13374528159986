import React, { useEffect, useState } from 'react'
import './jobcard.css'
import { Box, Divider, Grid, TextField, Typography } from '@mui/material'
import { ErrorMessage, Form, Formik, useFormikContext } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import * as Yup from 'yup'
import ProcessDetails from '../../modals/processdetails'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import { useGetSingleJobcardSales, useUpdateJobcard, useValidateJobcardNumber } from '../../hooks/jobcardHooks'
import ErrorPopup from '../../modals/errorpopup'
import { useParams } from 'react-router-dom'
import JobcardChengeConfirmModel from '../../modals/jobcardmodal/jobcardChengeConfirmModel'
import { styled } from '@mui/material/styles'
import Required from '../commoncomponents/required'
import AddPartForJobCardNew from './AddPartForJobCardNew'

const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '38px',
    padding: '1px 10px',
    fontSize: '14px !important'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px'
    }
  }
}))
const CountChangedFields = ({ changedCount, setChangedCount, setModifiedFields }) => {
  const { values, initialValues, dirty } = useFormikContext()

  const compareFields = (currentValues, initialValues, path = '') => {
    let count = 0
    const modifiedFieldsSet = new Set()

    const recursiveCompare = (currentValues, initialValues, path = '') => {
      for (const key in currentValues) {
        const currentPath = path ? `${path}.${key}` : key
        if (Array.isArray(currentValues[key])) {
          currentValues[key].forEach((item, index) => {
            recursiveCompare(item, initialValues[key][index], `${currentPath}[${index}]`)
          })
        } else if (typeof currentValues[key] === 'object' && currentValues[key] !== null) {
          recursiveCompare(currentValues[key], initialValues[key], currentPath)
        } else {
          if (currentValues[key] !== initialValues[key]) {
            modifiedFieldsSet.add(currentPath)
            count++
          } else {
            modifiedFieldsSet.delete(currentPath)
          }
        }
      }
    }

    recursiveCompare(currentValues, initialValues)
    return { count, modifiedFieldsSet }
  }

  useEffect(() => {
    if (dirty) {
      const { count, modifiedFieldsSet } = compareFields(values, initialValues)
      setChangedCount(count)
      setModifiedFields(Array.from(modifiedFieldsSet))
    } else {
      setChangedCount(0)
      setModifiedFields([])
    }
  }, [values, initialValues, dirty])

  return <div></div>
}
const ApproveJobcardform = () => {
  const { id } = useParams()
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  // const handleErrorModalOpen = () => setIsErrorModalOpen(true)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const [open, setOpen] = useState(false)
  const [activeProcess, setActiveProcess] = useState(-1)
  const [deletedProcess, setDeletedProcess] = useState([])
  const [deletedPart, setDeletedPart] = useState([])
  const [isApproved, setIsApproved] = React.useState(false)
  const [approveId, setApproveId] = React.useState('')
  const [dirty, setDirty] = useState(false)
  const [state, setState] = useState(false)
  const [modifiedFields, setModifiedFields] = useState([])
  const [changedCount, setChangedCount] = useState()

  const handleDetailsClose = () => {
    setOpen(false)
  }
  const breadCrumbData = [
    {
      title: 'Job Cards',
      link: '/job-card'
    },
    {
      title: 'Add  Job Card',
      link: ''
    }
  ]
  const icon = <AiOutlineInfoCircle color="red" />

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    jobCardNumber: Yup.string().required('Job Card Number is required'),
    ocNumber: Yup.string(),
    saleOrderNumber: Yup.string(),
    // .transform((value, originalValue) => {
    //   const parsedValue = parseFloat(originalValue)
    //   return isNaN(parsedValue) ? undefined : parsedValue
    // }),
    customerName: Yup.string().required('Customer Name is required'),
    customerReference: Yup.string(),
    productCode: Yup.string().required('Product Code is required'),
    productName: Yup.string().required('Product Name is required'),
    description: Yup.string(),
    quantity: Yup.number()
      .transform((value, originalValue) => {
        const parsedValue = parseFloat(originalValue)
        return isNaN(parsedValue) ? undefined : parsedValue
      })
      .required('Quantity is required')
      .positive('Quantity must be a positive number'),
    // producedQuantity: Yup.number().transform((value, originalValue) => {
    //   const parsedValue = parseFloat(originalValue)
    //   return isNaN(parsedValue) ? undefined : parsedValue
    // }),
    // balanceQuantity: Yup.number().transform((value, originalValue) => {
    //   const parsedValue = parseFloat(originalValue)
    //   return isNaN(parsedValue) ? undefined : parsedValue
    // }),
    bomDetails: Yup.array().of(
      Yup.object().shape({
        itemCode: Yup.string().required('Item Code is required'),
        partName: Yup.string().required('Part Name is required'),
        description: Yup.string().required('Part Description is required'),
        drawingNumber: Yup.string().required('Drawing Number is required'),
        numberOf: Yup.string().required('Number of is required'),
        sizeOd: Yup.number().required('Raw Material final Size OD is required'),
        sizeId: Yup.number().required('Raw Material final Size ID is required'),
        sizeL: Yup.number().required('Raw Material final Size L is required'),
        weight: Yup.number().required('Weight is required'),
        quantity: Yup.number()
          .transform((value, originalValue) => {
            const parsedValue = parseFloat(originalValue)
            return isNaN(parsedValue) ? undefined : parsedValue
          })
          .required('Quantity is required')
          .positive('Quantity must be a positive number')
      })
    )
  })
  // const {
  //   mutate: addJobCard, isError: isAddJobcardError,
  //   error: addJobcardError
  // } = useAddJobcard()
  const {
    mutate: updateJobCard, isError: isUpdateJobcardError,
    error: updateJobcardError
  } = useUpdateJobcard(true)
  const {
    mutate: validateJobcardNumber,
    data: validateData,
    isLoading: isValidating
    // isError: isAddPartError,
    // error: addPartError,
    // isLoading,
  } = useValidateJobcardNumber()
  const { data: getSingleJobcardSales, isSuccess, isFetching } = useGetSingleJobcardSales(id, !!id)
  const singleJobcardData = getSingleJobcardSales?.data
  // console.log('singleJobcardData====>', singleJobcardData)

  // const columns = [
  //   { field: 'id', headerName: '#', flex: 0.2 },
  //   { field: 'name', headerName: 'Process/Department  Name', flex: 1 },
  //   {
  //     field: 'code',
  //     headerName: 'Process/Department Code',
  //     flex: 1
  //   }
  //   // {
  //   //   field: 'departmentGroup',
  //   //   headerName: 'Process/Department Code',
  //   //   flex: 1
  //   // }
  // ]
  const columns = ['#',
    'Process/department Name',
    'Process/department Code'
    // 'Department Group'
  ]
  useEffect(() => {
    if (isUpdateJobcardError) {
      setErrorMessage(
        // isAddJobcardError:
        // ? addJobcardError?.data?.message
        updateJobcardError?.data?.message
      )
      setIsErrorModalOpen(true)
    }
  }, [
    // isAddJobcardError,
    isUpdateJobcardError,
    // addJobcardError,
    updateJobcardError
  ])

  useEffect(() => {
    localStorage.clear()
  }, [])

  useEffect(() => {
    if (isSuccess && id) {
      singleJobcardData?.bomParts?.forEach((data, ind) => {
        const processes = data?.bomprocesses?.map((val) => ({ department: val?.department?.id, proccessId: val?.id, name: val?.department?.name, code: val?.department?.code }))
        localStorage.setItem(`processData-${ind}`, JSON.stringify(processes))
      })
      // tosod
    }
  }, [isSuccess, id, isFetching])
  React.useEffect(() => {
    if (id && isSuccess) {
      if (singleJobcardData?.jobCardNumber) {
        validateJobcardNumber({
          jobCardNumber: singleJobcardData.jobCardNumber,
          id
        })
      }
    }
  }, [
    id,
    isSuccess,
    singleJobcardData?.jobCardNumber
  ])
  const handlejobCardFormSubmit = (processes) => {}
  const handleSubmit = (values) => {
    const bomDetails = values.bomDetails.map((detail, ind) => {
      let department = localStorage.getItem(`processData-${ind}`)
      if (department) {
        department = JSON.parse(department)
      } else {
        // department = []
      }
      if (department && department.length > 0) {
        return {
          ...detail,
          process: department?.map((process) => {
            if (process?.proccessId) {
              return {
                id: process.proccessId,
                department: process.department
              }
            } else {
              return {
                department: process.department

              }
            }
          })
        }
      } else {
        return {
          ...detail,
          process: detail?.process?.map((process) => {
            if (process?.id) {
              return {
                id: process.id,
                department: process.department.id

              }
            } else {
              return {
                department: process.department.id
              }
            }
          })
          // department: []
        }
      }
    })
    // console.log('bomDetails==>', bomDetails)
    // if (id) {
    setState({ ...values, bomDetails, id, deletedProcess, deletedPart })
    // } else {
    //   addJobCard({ ...values, bomDetails })
    // }
    // addjobcardClone({ ...values, id, bomDetails, deletedProcess, deletedPart })
  }
  // const isNameFieldChanged = formik.dirty && values.name !== initialValues.name;
  return (
    <React.Fragment>
      <Box className="add-job-card-overflow-hidden">
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={'Add Job Card'}
          backToLink={'/job-card'}
        />
        <Box className="">
          {/* <Box className="form-wrapper"> */}
          <Typography className="job-form-heading">
            Enter Job Card Details{' '}
          </Typography>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: singleJobcardData?.name,
              // date: moment().format('DD MMM, YYYY'),
              // date: '2024-04-02',
              jobCardNumber: singleJobcardData?.jobCardNumber,
              ocNumber: singleJobcardData?.ocNumber,
              saleOrderNumber: singleJobcardData?.saleOrderNumber,
              customerName: singleJobcardData?.customerName,
              customerReference: singleJobcardData?.customerReference,
              productCode: singleJobcardData?.productCode,
              productName: singleJobcardData?.productName,
              description: singleJobcardData?.description,
              quantity: singleJobcardData?.quantity,
              // producedQuantity: null,
              // balanceQuantity: 0,
              bomDetails:
                (singleJobcardData?.bomParts || []).map(part => ({
                  itemCode: part.itemCode || '',
                  id: part.id || '',
                  partName: part.partName || '',
                  description: part.description || '',
                  drawingNumber: part.drawingNumber || '',
                  numberOf: part.numberOf || null,
                  sizeOd: part.sizeOd ?? '',
                  sizeId: part.sizeId ?? '',
                  sizeL: part.sizeL ?? '',
                  weight: part.weight ?? '',
                  quantity: part.quantity ?? '',
                  process: part.bomprocesses || []
                }))
                // : [
                //     {
                //       itemCode: '',
                //       // id: null,
                //       partName: '',
                //       description: '',
                //       drawingNumber: '',
                //       numberOf: null,
                //       sizeOd: null,
                //       sizeId: null,
                //       sizeL: null,
                //       weight: null,
                //       quantity: null,
                //       process: []
                //     }
                //   ]
              // bomDetails: [
              //   {
              //     itemCode: '',
              //     partName: '',
              //     description: '',
              //     drawingNumber: '',
              //     numberOf: null,
              //     sizeOd: null,
              //     sizeId: null,
              //     sizeL: null,
              //     weight: null,
              //     quantity: null,
              //     process: []
              //   }
              // ]
            }}
            onSubmit={(values) =>
              handleSubmit(values, values.maintenanceDetails)
            }

            validationSchema={validationSchema}
            >

            {({ errors, handleChange, touched, values, setFieldValue, dirty }) => (
              <React.Fragment>
                <Form className="form-perent">
            {/* {console.log('dirty====>', dirty)} */}
            {id && <CountChangedFields changedCount={changedCount} setChangedCount={setChangedCount} setModifiedFields={setModifiedFields}/>}

                  <Box className="form-child-jobcard">
                    <Box className="padding-y-50">
                      <Grid spacing={2} container>
                        <Grid item xs={12} md={4} xl={4}>
                          <CustomInput
                            label="Job Card Name"
                            placeholder="Enter Job Card Name"
                            handleChange={handleChange}
                            value={values?.name}
                            name="name"
                            isRequired={false}
                            // InputProps={{
                            //   endAdornment:
                            //     touched?.name && errors?.name && icon
                            // }}
                            // errors={errors?.name}
                            touched={touched?.name}
                          />
                          {modifiedFields?.includes('name')
                            ? <Typography className='modified-Fields-color'>
                        {'Job Card Name Updated'}
                      </Typography>
                            : ''}
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                          <Typography className='field-title'>
                          Job Card Number
                      <Required />
                    </Typography>
                           <Input
                      // error={
                      //   (!validateData?.data.isValid && values?.jobCardNumber && !isValidating) ||
                      //   (!(!errors.jobCardNumber || !touched.jobCardNumber))
                      // }
                      placeholder='Enter Job Card Number'
                      fullWidth
                      onChange={(e) => {
                        // console.log('Enter Job Card Number', e.target.value, values?.jobCardNumber)
                        validateJobcardNumber({
                          jobCardNumber: e.target.value,
                          ...(id && { id })
                        })
                        handleChange(e)
                      }}
                      value={values.jobCardNumber || ''}
                      name='jobCardNumber'
                      InputProps={{
                        style: { fontSize: 14, padding: '0px !important', height: '40px' },
                        endAdornment: touched.jobCardNumber && errors.jobCardNumber && icon
                      }}
                    />
                    {!validateData?.data.isValid && values?.jobCardNumber && !isValidating
                      ? (
                      <Typography className='validation-text'>
                        {'Job Card Number already taken'}
                      </Typography>
                        )
                      : (
                      <ErrorMessage
                        name='jobCardNumber'
                        render={(msg) => <Typography className='validation-text'>{msg}</Typography>}
                      />
                        )}
                           {modifiedFields?.includes('jobCardNumber')
                             ? <Typography className='modified-Fields-color'>
                        {'Job Card Number Updated'}
                      </Typography>
                             : ''}
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                          <CustomInput
                            label="OC Number"
                            placeholder="Enter OC Number"
                            handleChange={handleChange}
                            name="ocNumber"
                            value={values?.ocNumber}
                            isRequired={false}
                            InputProps={{
                              endAdornment:
                                touched?.ocNumber && errors?.ocNumber && icon
                            }}
                            errors={errors?.ocNumber}
                            touched={touched?.ocNumber}
                          />
                           {modifiedFields?.includes('ocNumber')
                             ? <Typography className='modified-Fields-color'>
                        {'OC Number Updated'}
                      </Typography>
                             : ''}
                        </Grid>
                      </Grid>
                      <Grid spacing={2} paddingTop={3} container>
                        <Grid item xs={12} md={4} xl={4}>
                          <CustomInput
                            label="Sale Order Number"
                            placeholder="Enter Sale Order Number"
                            handleChange={handleChange}
                            name="saleOrderNumber"
                            // type="number"
                            value={values?.saleOrderNumber}
                            isRequired={false}
                            InputProps={{
                              endAdornment:
                                touched.saleOrderNumber &&
                                errors.saleOrderNumber &&
                                icon
                            }}
                            errors={errors.saleOrderNumber}
                            touched={touched.saleOrderNumber}
                          />
                           {modifiedFields?.includes('saleOrderNumber')
                             ? <Typography className='modified-Fields-color'>
                        {'Sale Order Number Updated'}
                      </Typography>
                             : ''}
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                          <CustomInput
                            label="Customer Name"
                            placeholder="Enter Customer Name"
                            handleChange={handleChange}
                            name="customerName"
                            value={values?.customerName}
                            InputProps={{
                              endAdornment:
                                touched.customerName &&
                                errors.customerName &&
                                icon
                            }}
                            errors={errors.customerName}
                            touched={touched.customerName}
                          />
                           {modifiedFields?.includes('customerName')
                             ? <Typography className='modified-Fields-color'>
                        {'Customer Name Updated'}
                      </Typography>
                             : ''}
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                          <CustomInput
                            label="Customer Reference"
                            placeholder="Enter Customer Reference"
                            isRequired={false}
                            handleChange={handleChange}
                            value={values?.customerReference}
                            name="customerReference"
                            // type="number"
                            InputProps={{
                              endAdornment:
                                touched?.customerReference &&
                                errors?.customerReference &&
                                icon
                            }}
                            errors={errors?.customerReference}
                            touched={touched?.customerReference}
                          />
                             {modifiedFields?.includes('customerReference')
                               ? <Typography className='modified-Fields-color'>
                        {'Customer Reference Updated'}
                      </Typography>
                               : ''}
                        </Grid>
                      </Grid>
                      <Grid spacing={2} paddingTop={3} container>
                        <Grid item xs={12} md={4} xl={4}>
                          <CustomInput
                            label="Product Code"
                            placeholder="Enter Product Code"
                            handleChange={handleChange}
                            value={values?.productCode}
                            name="productCode"
                            InputProps={{
                              endAdornment:
                                touched?.productCode &&
                                errors?.productCode &&
                                icon
                            }}
                            errors={errors?.productCode}
                            touched={touched?.productCode}
                          />
                            {modifiedFields?.includes('productCode')
                              ? <Typography className='modified-Fields-color'>
                        {'Product Code Updated'}
                      </Typography>
                              : ''}
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                          <CustomInput
                            label="Product Name"
                            placeholder="Enter Product Name"
                            handleChange={handleChange}
                            value={values?.productName}
                            name="productName"
                            InputProps={{
                              endAdornment:
                                touched?.productName &&
                                errors?.productName &&
                                icon
                            }}
                            errors={errors?.productName}
                            touched={touched?.productName}
                          />
                            {modifiedFields?.includes('productName')
                              ? <Typography className='modified-Fields-color'>
                        {'Product Name Updated'}
                      </Typography>
                              : ''}
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                          <CustomInput
                            label="Description"
                            placeholder="Enter Description"
                            handleChange={handleChange}
                            value={values?.description}
                            name="description"
                            isRequired={false}
                            InputProps={{
                              endAdornment:
                                touched?.description &&
                                errors?.description &&
                                icon
                            }}
                            errors={errors?.description}
                            touched={touched?.description}
                          />
                            {modifiedFields?.includes('description')
                              ? <Typography className='modified-Fields-color'>
                        {'Description Updated'}
                      </Typography>
                              : ''}
                        </Grid>
                      </Grid>
                      <Grid
                        spacing={2}
                        paddingTop={3}
                        // paddingBottom={2.5}
                        container
                      >
                        <Grid item xs={12} md={4} xl={4}>
                          <CustomInput
                            label="Quantity"
                            type="number"
                            placeholder="Enter Quantity"
                            handleChange={(e) => {
                              handleChange(e)
                              setFieldValue(
                                'balanceQuantity',
                                e.target.value - (values.producedQuantity || 0)
                              )
                            }}
                            value={values?.quantity}
                            name="quantity"
                            InputProps={{
                              endAdornment:
                                touched?.quantity && errors?.quantity && icon
                            }}
                            errors={errors?.quantity}
                            touched={touched?.quantity}
                          />
                           {modifiedFields?.includes('quantity')
                             ? <Typography className='modified-Fields-color'>
                        {'Quantity Updated'}
                      </Typography>
                             : ''}
                        </Grid>
                        {/* <Grid item xs={12} md={4} xl={4}>
                          <CustomInput
                            label="Produced Quantity"
                            placeholder="Enter Produced Quantity"
                            handleChange={(e) => {
                              handleChange(e)
                              setFieldValue(
                                'balanceQuantity',
                                (values.quantity || 0) - e.target.value > 0
                                  ? (values.quantity || 0) - e.target.value
                                  : 0
                              )
                            }}
                            value={values?.producedQuantity}
                            name="producedQuantity"
                            type="number"
                            isRequired={false}
                            InputProps={{
                              endAdornment:
                                touched?.producedQuantity &&
                                errors?.producedQuantity &&
                                icon
                            }}
                            errors={errors?.producedQuantity}
                            touched={touched?.producedQuantity}
                          />
                        </Grid> */}
                        {/* <Grid item xs={12} md={3} xl={2}>
                          <CustomInput
                            label="Balance Quantity"
                            placeholder="--"
                            titleClassName="disabled-balance-Quantity"
                            disabled={true}
                            handleChange={handleChange}
                            value={values?.balanceQuantity}
                            name="balanceQuantity"
                            isRequired={false}
                            InputProps={{
                              endAdornment:
                                touched?.balanceQuantity &&
                                errors?.balanceQuantity &&
                                icon
                            }}
                            errors={errors?.balanceQuantity}
                            touched={touched?.balanceQuantity}
                          />
                        </Grid> */}
                      </Grid>
                      <Divider />
                    </Box>
                    <Typography
                      className="job-form-heading-1 padding-y-50 pb-20"
                      mb={'10px'}
                    >
                      BOM details
                    </Typography>
                    <AddPartForJobCardNew
                   values={values}
                   setDeletedPart={setDeletedPart}
                   handleChange={handleChange}
                   touched={touched}
                   errors={errors}
                   icon={icon}
                   columns={columns}
                   setActiveProcess={setActiveProcess}
                   setOpen={setOpen}
                   modifiedFields={modifiedFields}

                   />
                  </Box>
                  {/* <Box className="button-child" gap={1}>
                    <CustomButton variant='outlined' title="Save as Draft" type="button"
                     onClick={() => {
                       addDraftJobCard({ ...values })
                       //  handleClose()
                       // navigate(`/edit-job-card/${id}`)
                     }}
                    // onClick={addDraftJobCard({ ...values })}
                    />
                    <CustomButton title="Save Job Card" type="submit" />
                  </Box> */}
                  <Box className="button-child">
                    <CustomButton title="Approve Job Card" type="submit"
                    onClick={() => {
                      setApproveId(id)
                      // setNumber(params.row.number)
                      // setCurrentRowData({
                      //   id: params.row.id
                      // })
                      setIsApproved(true)
                      setDirty(dirty)
                      // setstate
                      setState(setState)
                    }} />
                  </Box>
                  {open && (
                    <ProcessDetails
                      setDeletedProcess={setDeletedProcess}
                      deletedProcess={deletedProcess}
                      activeProcess={activeProcess}
                      open={open}
                      setOpen={() => setOpen(false)}
                      setFieldValue={setFieldValue}
                      onSubmit={handlejobCardFormSubmit}
                      DetailsHandleClose={handleDetailsClose}
                    />
                  )}
                </Form>
              </React.Fragment>
            )}
          </Formik>
          {isApproved &&
            <JobcardChengeConfirmModel
            open={isApproved}
            id={approveId}
            handleClose={() => setIsApproved(false)}
            dirty={dirty}
            changedCount={changedCount}
            handleUpdate={() => {
              updateJobCard(state)
            }}
            />
          }
             {/* {isApproved && (
          <ApprovedModal
            open={isApproved}
            title="Approved"
            handleClose={() => setIsApproved(false)}
            data={currentRowData}
            number={number}
          />
        )} */}
          {isErrorModalOpen && (
            <ErrorPopup
              isOpen={isErrorModalOpen}
              handleClose={handleErrorModalClose}
              errorDetails={errorMessage}
            />
          )}
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default ApproveJobcardform
