import * as React from 'react'
import { Box } from '@mui/system'
import {
  Modal,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  Select
} from '@mui/material'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import {
  useGetJobDetailsSingleTask,
  useScheduleTask
} from '../../hooks/jobcardHooks'
import { useQueryClient } from '@tanstack/react-query'
import { user } from '../../querykeys/usermanagement'
import * as Yup from 'yup'
// import CustomButton from '../../leastcomponent/custombutton/custombutton'
import Required from '../commoncomponents/required'
import { Form, Formik } from 'formik'
import { useGetFullresource } from '../../hooks/resourceHooks'
import CustomInput from '../../leastcomponent/custominput/input'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import CustomButton from '../../leastcomponent/custombutton/custombutton'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  height: 500,
  overflow: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  overFlow: 'auto',
  padding: '20px 20px 20px 20px'
}
// const TextFiled = styled(Typography)(() => ({
//   color: 'gray',
//   paddingTop: '10px',
//   fontSize: '14px !important',
//   padding: '24px 0px 0px 24px'
// }))
const HeadBox = styled(Box)(() => ({
  '.btn-box': {
    display: 'flex',
    justifyContent: 'end'
    // margin: '3px 20px 30px 0px'
  },
  '.customFormControl': {
    fontSize: '1px !important',
    fontWeight: '800'
  },
  '.customRadioGroup': {
    fontSize: '14px',
    fontWeight: '400',
    display: 'flex'
  },
  '.css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
    borderRadius: '32px !important'
  },
  '.validation-error': {
    color: 'red'
  },
  '.validation-errors': {
    color: 'red',
    paddingTop: '20px !important',
    fontSize: '12px !important'
  },
  '.jobcardform': {
    // padding: '24px 0px 0px 24px',
    display: 'flex',
    justifyContent: 'end'
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A'
      }
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px !important'
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px 12px !important'
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important'
    }
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px 12px'
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px'
    }
  },
  '.btn-text-transform': {
    textTransform: 'capitalize !important'
  }
}))
const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '8.5px 14px',
    fontSize: '14px !important'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8'
  }
}))

const JobcardSchedule = (props) => {
  const { DetailsHandleClose, id, partId } = props
  const icon = <AiOutlineInfoCircle color="red" />
  const { mutate: addScheduleTask } = useScheduleTask(true)
  const { data: getJobDetails } = useGetJobDetailsSingleTask(id, !!id)
  const singleJobDetails = getJobDetails?.data
  const selectDepartmentid = localStorage.getItem('selectDepartmentid')
  const queryClient = useQueryClient()
  const userData = queryClient.getQueryData([user.LOGGED_IN_USER])
  const departmentGroupTitle =
    userData?.data?.data?.user?.roleId?.departmentGroup?.title
  console.log('departmentGroupTitle ==>', departmentGroupTitle)
  const finalBompartData = singleJobDetails?.bompart
    .map((data) => ({
      ...data,
      bomprocess: data.bomprocess.filter(
        (bomproces) => bomproces.department.id === selectDepartmentid
      )
    }))
    .filter((data) => data.id === partId && data.bomprocess.length > 0)
  const taskArr = finalBompartData?.[0]?.bomprocess
    ?.map((data) => data?.task)
    ?.flat()
    ?.map((val) => {
      return {
        resource: '',
        // jobSlip: '',
        setupTime: '',
        productionTime: '',
        changeoverTime: '',
        priority: '',
        id: val?.id
      }
    })
  const [show, setShow] = React.useState(true)
  console.log(setShow)
  const [reso, setReso] = React.useState('')
  const { data: resourcesData } = useGetFullresource(reso, !!reso, true)
  // const { data: jobSlipsData } = useGetJobSlipsFullList()
  const taskSchema = Yup.object().shape({
    resource: Yup.string().required('Resource is required'),
    // jobSlip: Yup.string().required('Job Slip is required'),
    priority: Yup.string().required('Job Slip is required'),
    setupTime: Yup.number()
      .typeError('Setup Time must be a number')
      .required('Setup Time is required')
      .positive('Setup Time must be a positive number'),
    productionTime: Yup.number()
      .typeError('Production Time must be a number')
      .required('Production Time is required')
      .positive('Production Time must be a positive number'),
    changeoverTime: Yup.number()
      .typeError('Changeover Time must be a number')
      .required('Changeover Time is required')
      .positive('Changeover Time must be a positive number')
  })

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={DetailsHandleClose}
        aria-labelledby=""
        aria-describedby="modal-modal-description"
      >
        <HeadBox sx={style}>
          <Box className="title-head-box">
            <Typography className="top-up-name">Schedule Tasks</Typography>
            <CloseIcon className="close-icon" onClick={DetailsHandleClose} />
          </Box>
          <Box mx={'25px !important'}>
            <Grid container spacing={2} paddingLeft={'25px'} paddingTop={'5px'}>
              {/* Row 1 */}
              <Grid item xs={6} container alignItems="center">
                <Typography variant="subtitle2" color="textSecondary">
                  Job Card Name
                </Typography>
                <Typography
                  className="span-color pl-sub-text"
                  fontWeight="bold"
                >
                  {singleJobDetails?.name ? singleJobDetails?.name : '-'}
                </Typography>
              </Grid>
              <Grid item xs={6} container alignItems="center">
                <Typography variant="subtitle2" color="textSecondary">
                  Job Card Number
                </Typography>
                <Typography
                  className="span-color pl-sub-text"
                  fontWeight="bold"
                >
                  {singleJobDetails?.jobCardNumber
                    ? singleJobDetails?.jobCardNumber
                    : '-'}
                </Typography>
              </Grid>

              <Grid item xs={6} container alignItems="center">
                <Typography variant="subtitle2" color="textSecondary">
                  Product Name
                </Typography>
                <Typography
                  className="span-color pl-sub-text"
                  fontWeight="bold"
                >
                  {singleJobDetails?.productName
                    ? singleJobDetails?.productName
                    : '-'}
                </Typography>
              </Grid>
              <Grid item xs={6} container alignItems="center">
                <Typography variant="subtitle2" color="textSecondary">
                  Quantity
                </Typography>
                <Typography
                  className="span-color pl-sub-text"
                  fontWeight="bold"
                >
                  {singleJobDetails?.quantity
                    ? singleJobDetails?.quantity
                    : '-'}
                </Typography>
              </Grid>
              <Grid item xs={6} container alignItems="center">
                <Typography variant="subtitle2" color="textSecondary">
                  Customer name
                </Typography>
                <Typography
                  className="span-color pl-sub-text"
                  fontWeight="bold"
                >
                  {singleJobDetails?.customerName
                    ? singleJobDetails?.customerName
                    : '-'}
                </Typography>
              </Grid>
              <Grid item xs={6} container alignItems="center">
                <Grid
                  item
                  xs={12}
                  className="align-center-with-gap"
                  sx={{ justifyContent: 'flex-start' }}
                >
                  Status:{' '}
                  {singleJobDetails?.jobCardStatus === 'approved' && (
                    <>
                      <span className="align-center">
                        <FiberManualRecordIcon
                          sx={{ fontSize: '14px', color: '#C89AFC' }}
                        />
                      </span>{' '}
                      <span className="font-size-14-with-semibold">
                        Approved
                      </span>
                    </>
                  )}
                  {singleJobDetails?.jobCardStatus === 'completed' && (
                    <>
                      <span className="align-center">
                        <FiberManualRecordIcon
                          sx={{ fontSize: '14px', color: '#1AD04C' }}
                        />
                      </span>{' '}
                      <span className="font-size-14-with-semibold">
                        Completed
                      </span>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box
            padding={'10px 24px'}
            sx={{
              borderBottom: '1px solid #dedede',
              padding: '10px 10px 20px 10px'
            }}
          ></Box>
          <Box>
            <Formik
              // key={partIndex}
              enableReinitialize={true}
              initialValues={{
                task: taskArr?.slice(0, 1)
              }}
              validationSchema={Yup.object().shape({
                task: Yup.array().of(taskSchema)
              })}
              onSubmit={(values) => {
                if (values?.task?.length > 0) {
                  addScheduleTask({ task: values?.task })
                } else {
                  console.log('No data to submit')
                }
              }}
            >
              {({ errors, handleChange, touched, values }) => (
                <React.Fragment>
                  <Form>
                    <Box>
                      {finalBompartData?.map((data, ind) => {
                        let allTasks = []
                        data.bomprocess.forEach((process) => {
                          // Concatenate the tasks from each process to the allTasks array
                          allTasks = allTasks.concat(process.task)
                        })
                        return (
                          <Box
                            // className="form-child-border"
                            // style={{ padding: '0px 20px' }}
                            key={ind}
                          >
                            <Grid
                              container
                              className="padding-20-75-border-bottom"
                            >
                              <Grid item lg={3} md={2}>
                                <Box className="add-task-header">Part Name</Box>
                                <Box className="task-type-form-heading">
                                  {data.partName ? data.partName : '-'}
                                </Box>
                              </Grid>
                              <Grid item lg={3} md={2}>
                                {' '}
                                <Box className="add-task-header">Part Code</Box>
                                <Box className="task-type-form-heading">
                                  {data.itemCode ? data.itemCode : '-'}
                                </Box>
                              </Grid>
                              <Grid item lg={3} md={2}>
                                {' '}
                                <Box className="add-task-header">Quantity.</Box>
                                <Box className="task-type-form-heading">
                                  {data?.quantity ? data?.quantity : '-'}
                                </Box>
                              </Grid>
                              <Grid item lg={3} md={2}>
                                {' '}
                                <Box className="add-task-header">
                                  Drawing no.
                                </Box>
                                <Box className="task-type-form-heading">
                                  {' '}
                                  {data.drawingNumber
                                    ? data.drawingNumber
                                    : '-'}
                                </Box>
                              </Grid>
                            </Grid>
                            {show && (
                              <Box
                                className="form-child-border11"
                                style={{
                                  borderTop: '1px solid #dedede'
                                }}
                              >
                                <React.Fragment>
                                  <Box className="gap-20">
                                    <Box padding={'20px'}>
                                      {allTasks?.slice(0, 1)?.map((task, index) => {
                                        const findData =
                                          resourcesData?.data?.find(
                                            (val) =>
                                              val?.id === task?.taskType?.id
                                          )?.machine
                                        return (
                                          <Box
                                            borderBottom={
                                              index === allTasks?.length - 1
                                                ? ''
                                                : '1px solid #dedede'
                                            }
                                            sx={{ paddingTop: '14px' }}
                                            key={index}
                                          >
                                            <Grid
                                              container
                                              className="padding-top-bottom"
                                            >
                                              <Grid
                                                container
                                                xs={12}
                                                spacing={2}
                                                paddingBottom={2}
                                                alignItems="center"
                                              >
                                                <Grid
                                                  item
                                                  lg={6}
                                                  md={3}
                                                  sx={{
                                                    display: 'flex',
                                                    gap: '20px'
                                                  }}
                                                >
                                                  <Typography className="add-task-type-header">
                                                    Task Type
                                                  </Typography>
                                                  <Typography className="task-type-form-heading">
                                                    {task?.taskType?.name
                                                      ? task?.taskType?.name
                                                      : '-'}
                                                  </Typography>
                                                </Grid>

                                                <Grid
                                                  item
                                                  lg={6}
                                                  md={3}
                                                  sx={{
                                                    display: 'flex',
                                                    gap: '20px'
                                                  }}
                                                >
                                                  <Typography className="add-task-type-header">
                                                    Task Description
                                                  </Typography>
                                                  <Typography className="task-type-form-heading">
                                                    {task?.description
                                                      ? task?.description
                                                      : '-'}
                                                  </Typography>
                                                </Grid>
                                              </Grid>

                                              <Grid item lg={4} md={3}>
                                                <Typography className="form-heading-1 pb-20">
                                                  {index + 1}
                                                  {'.'} {task?.taskNickName}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                            {/* ))} */}
                                            {/* //! here grid start-------------- */}
                                            <Grid
                                              spacing={2}
                                              paddingBottom={'20px'}
                                              container
                                            >
                                              <Grid
                                                item
                                                xs={12}
                                                lg={4}
                                                md={6}
                                                xl={4}
                                              >
                                                <Typography className="field-title">
                                                  Machine/Workstation
                                                  <Required />
                                                </Typography>

                                                <FormControl
                                                  fullWidth
                                                  onMouseDown={(e) => {
                                                    e.stopPropagation()
                                                    const allIds =
                                                      allTasks?.map(
                                                        (val) =>
                                                          val?.taskType?.id
                                                      )
                                                    setReso(allIds?.join(','))
                                                  }}
                                                >
                                                  <Selects
                                                    name={`task.${index}.resource`}
                                                    variant="outlined"
                                                    value={
                                                      resourcesData?.data
                                                        ?.length > 0
                                                        ? values?.task?.[index]
                                                          ?.resource
                                                        : ''
                                                    }
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    errors={
                                                      errors?.task?.[index]
                                                        ?.resource
                                                    }
                                                    touched={
                                                      touched?.task?.[index]
                                                        ?.resource
                                                    }
                                                  >
                                                    <MenuItem value="">
                                                      <span className="menu-item-span">
                                                        Enter Name/code
                                                      </span>
                                                    </MenuItem>
                                                    {findData?.map((data) => {
                                                      return (
                                                        <MenuItem
                                                          value={data?.id}
                                                          key={index}
                                                        >
                                                          {data?.name} -
                                                          {data?.code}
                                                        </MenuItem>
                                                      )
                                                    })}
                                                  </Selects>
                                                </FormControl>

                                                {touched?.task?.[index]
                                                  ?.resource &&
                                                  errors?.task?.[index]
                                                    ?.resource && (
                                                    <Typography className="validation-text">
                                                      {
                                                        errors?.task?.[index]
                                                          ?.resource
                                                      }
                                                    </Typography>
                                                )}
                                              </Grid>
                                              <Grid
                                                item
                                                xs={12}
                                                lg={4}
                                                md={6}
                                                xl={4}
                                              >
                                                <Typography className="field-title">
                                                  Priority
                                                  <Required />
                                                </Typography>

                                                <FormControl fullWidth>
                                                  <Selects
                                                    name={`task.${index}.priority`}
                                                    variant="outlined"
                                                    value={
                                                      values?.task?.[index]
                                                        ?.priority
                                                    }
                                                    onChange={handleChange}
                                                    displayEmpty
                                                  >
                                                    <MenuItem value="">
                                                      <span className="menu-item-span">
                                                        Select Priority
                                                      </span>
                                                    </MenuItem>

                                                    <MenuItem value="Regular">
                                                      Regular
                                                    </MenuItem>
                                                    <MenuItem value="High">
                                                      High
                                                    </MenuItem>
                                                    <MenuItem value="Immediate">
                                                      Immediate
                                                    </MenuItem>
                                                  </Selects>
                                                </FormControl>

                                                {touched?.task?.[index]
                                                  ?.priority &&
                                                  errors?.task?.[index]
                                                    ?.priority && (
                                                    <Typography className="validation-text">
                                                      {
                                                        errors?.task?.[index]
                                                          ?.priority
                                                      }
                                                    </Typography>
                                                )}
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              spacing={2}
                                              paddingBottom={'30px'}
                                              container
                                            >
                                              <Grid
                                                item
                                                xs={12}
                                                lg={4}
                                                md={6}
                                                xl={4}
                                              >
                                                <CustomInput
                                                  label="Setup time per piece (min)"
                                                  placeholder="Enter Time (min)"
                                                  handleChange={handleChange}
                                                  value={
                                                    values?.task?.[index]
                                                      ?.setupTime
                                                  }
                                                  name={`task.${index}.setupTime`}
                                                  InputProps={{
                                                    endAdornment:
                                                      touched?.task?.[index]
                                                        ?.setupTime &&
                                                      errors?.task?.[index]
                                                        ?.setupTime &&
                                                      icon
                                                  }}
                                                  errors={
                                                    errors?.task?.[index]
                                                      ?.setupTime
                                                  }
                                                  touched={
                                                    touched?.task?.[index]
                                                      ?.setupTime
                                                  }
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                xs={12}
                                                lg={4}
                                                md={6}
                                                xl={4}
                                              >
                                                <CustomInput
                                                  label="Production time per piece (min)"
                                                  placeholder="Enter Time (min)"
                                                  handleChange={handleChange}
                                                  value={
                                                    values?.task?.[index]
                                                      ?.productionTime
                                                  }
                                                  name={`task.${index}.productionTime`}
                                                  InputProps={{
                                                    endAdornment:
                                                      touched?.task?.[index]
                                                        ?.productionTime &&
                                                      errors?.task?.[index]
                                                        ?.productionTime &&
                                                      icon
                                                  }}
                                                  errors={
                                                    errors?.task?.[index]
                                                      ?.productionTime
                                                  }
                                                  touched={
                                                    touched?.task?.[index]
                                                      ?.productionTime
                                                  }
                                                />
                                              </Grid>{' '}
                                              <Grid
                                                item
                                                xs={12}
                                                lg={4}
                                                md={6}
                                                xl={4}
                                              >
                                                <CustomInput
                                                  label="Changeover time per piece (min)"
                                                  placeholder="Enter Time (min)"
                                                  handleChange={handleChange}
                                                  value={
                                                    values?.task?.[index]
                                                      ?.changeoverTime
                                                  }
                                                  name={`task.${index}.changeoverTime`}
                                                  InputProps={{
                                                    endAdornment:
                                                      touched?.task?.[index]
                                                        ?.changeoverTime &&
                                                      errors?.task?.[index]
                                                        ?.changeoverTime &&
                                                      icon
                                                  }}
                                                  errors={
                                                    errors?.task?.[index]
                                                      ?.changeoverTime
                                                  }
                                                  touched={
                                                    touched?.task?.[index]
                                                      ?.changeoverTime
                                                  }
                                                />
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        )
                                      })}
                                    </Box>
                                  </Box>
                                </React.Fragment>
                              </Box>
                            )}
                          </Box>
                        )
                      })}
                    </Box>
                    <Box className="btn-box">
                      <CustomButton title="Submit" type="submit" />
                    </Box>
                  </Form>
                </React.Fragment>
              )}
            </Formik>
          </Box>
        </HeadBox>
      </Modal>
    </React.Fragment>
  )
}
export default JobcardSchedule
