import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import TableComponent from '../../leastcomponent/customtable/customtable'
import _ from 'lodash'
import { useLocation, useParams } from 'react-router-dom'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import { useGetDeletedResource, useGetResourcesSingle, useHardDeleteResource, useRestoreResource } from '../../hooks/resourceHooks'
import moment from 'moment'
import MaintenanceScheduleModal from '../../modals/resourcemodal/maintenanceModal'
import ErrorPopup from '../../modals/errorpopup'
import EditIconNew from '../../assets/svg/EditIconNew'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import DeleteWarningModal from '../../modals/deletewarningmodal'
const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px'
}))
const ResourceDetails = () => {
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  //  delete items

  const location = useLocation()
  const isDeleted = location.pathname.startsWith('/deleted-items')
  const isRestore = location.pathname.startsWith('/deleted-items')
  const [open, setOpen] = useState(false)
  const handleMaintenanceFormSubmit = (maintenanceDetails) => {
    console.log('values:', maintenanceDetails)
  }
  const handleDetailsClose = () => {
    setOpen(false)
  }
  // TODO::  temporary comment
  const [deleteRecord, setDeleteRecord] = useState(false)
  const handleDeleteOpen = () => setDeleteRecord(true)
  const handleDeleteClose = () => setDeleteRecord(false)
  const { id } = useParams()
  const { mutate: deleteResource } = useHardDeleteResource()

  const { mutate: restoreResource } = useRestoreResource()

  const handlePartDelete = () => {
    if (isDeleted) {
      deleteResource(id)
    } else {
      // deleteSinglePart(id)
    }
  }
  const handlePartRestore = () => {
    if (isRestore) {
      restoreResource(id)
    } else {
      // nothing to restore
    }
  }
  const { data: getResourceDetails, error, isFetching: isGetResourceDetails } = useGetResourcesSingle(id, !!id && !isDeleted)
  const { data: getResourceData } = useGetDeletedResource(
    id,
    !!id && isDeleted
  )
  // const { data: getBreakdown } = useGetBreakdown(!!id, null)

  const singleResource = isDeleted ? getResourceData?.data : getResourceDetails?.data
  const rows = singleResource?.maintenanceDetails?.map((data, ind) => {
    return {
      no: ind + 1,
      id: data?._id,
      name: data?.name ? data?.name : '-',
      maintenanceCode: data?.maintenanceCode ? data?.maintenanceCode : '-',
      pmFrequency: data?.pmFrequency ? data?.pmFrequency : '-',
      lastDate: data?.lastDate
        ? moment(data?.lastDate)?.format('DD/MM/YYYY')
        : '-',
      nextDate: data?.nextDate
        ? moment(data?.nextDate)?.format('DD/MM/YYYY')
        : '-'
    }
  })

  const columns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'name', headerName: 'Maintenance Name ', flex: 1 },
    { field: 'maintenanceCode', headerName: 'Maintenance Code', flex: 1 },
    { field: 'pmFrequency', headerName: 'PM Frequency', flex: 1 },
    { field: 'lastDate', headerName: 'Last performed date', flex: 1 },
    { field: 'nextDate', headerName: 'Next due date', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: () => {
        return (
          <Box className="resource-table-btn-main">
            <span className="process-delete" onClick={() => setOpen(true)}>
              <EditIconNew />
            </span>
            <span
              onClick={() => {
                handleDeleteOpen()
              }}
              className="process-delete"
            >
              <DeleteIconNew />
            </span>
          </Box>
        )
      }
    }
  ]
  const TaskRows = singleResource?.taskHistory?.map((data, ind) => {
    return {
      no: ind + 1,
      id: data?.id,
      name: data?.taskNickName ? data?.taskNickName : '-',
      taskType: data?.taskType?.name ? data?.taskType?.name : '-',
      taskTaken: data?.taskType?.taskTaken ? data?.taskType?.taskTaken : '-',
      partName: data?.bomPartId?.partName ? data?.bomPartId?.partName : '-',
      itemCode: data?.bomPartId?.itemCode ? data?.bomPartId?.itemCode : '-',
      jobCardNumber: data?.jobCardId?.jobCardNumber ? data?.jobCardId?.jobCardNumber : '-',
      taskCompletionDate: data?.taskCompletionDate
        ? moment(data?.taskCompletionDate)?.format('DD/MM/YYYY, hh:mm A')
        : '-'
    }
  })

  const TaskColumns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'name', headerName: 'Task Name ', flex: 1 },
    { field: 'taskType', headerName: 'Task Type ', flex: 1 },
    { field: 'taskTaken', headerName: 'Task Taken', flex: 1 },
    { field: 'taskCompletionDate', headerName: 'Completed On', flex: 1 },
    { field: 'partName', headerName: 'Part Name ', flex: 1 },
    { field: 'itemCode', headerName: 'Part Code', flex: 1 },
    { field: 'jobCardNumber', headerName: 'JC Number', flex: 1 }
  ]
  const BreakdownList = singleResource?.breckDownHistory
  const BreakdownRows = BreakdownList?.map((data, ind) => {
    return {
      id: data?.id,
      no: ind + 1,
      breakdownReason: data?.breakdownReason ? data?.breakdownReason : '-',
      breakDownDate: moment(data?.breakDownDate).format('DD/MM/YY, hh:mm a') ? moment(data?.breakDownDate).format('DD/MM/YY, hh:mm a') : '-',
      breakDownDuration: data?.breakDownDuration ? data?.breakDownDuration : '-'
    }
  })
  const Breakdowncolumns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'breakDownDuration', headerName: 'Breakdown Duration', flex: 1 },
    { field: 'breakDownDate', headerName: 'Breakdown Occurred', flex: 1 },
    { field: 'breakdownReason', headerName: 'Breakdown Reason', flex: 1 }
  ]
  const breadCrumbData = [
    {
      title: isDeleted ? 'Delete Resources' : 'Resources',
      link: '/resource'
    },
    {
      title: singleResource?.name,
      link: ''
    }
  ]
  useEffect(() => {
    if (error) {
      setIsErrorModalOpen(true)
      setErrorMessage(error?.data?.message)
    }
  }, [error])
  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={_.get(singleResource, 'name', '-')}
        backToLink={'/resource'}
        activeTab="1"
        isRestore={true}
        isDeleted={true}
        handleDelete={handlePartDelete}
        handleRestore={handlePartRestore}
      />
     {!isGetResourceDetails
       ? <Box className="common-parent-class-overflow-machine">
              {/* <Grid container spacing={2} mt={'20px'} > */}
              {/* <Grid item xs={12}> */}
        <Grid item xs={12} md={12} container display={'flex'} mt={'20px'}>
          <Grid item xs={6} md={6}>
            <Box className="details-box-border padding-bottom" mr={'10px'}>
              <Typography id="modal-modal-title" className="details-sub-title">
                Basic Details
                </Typography>
                {/* <Grid container spacing={0}> */}
                {/* <Grid item xs={2}> */}
              <Grid container display={'flex'}>
                <Grid item xs={4} md={4}>
                  <TextFiled>Resource Type </TextFiled>
                  <Typography className="span-color pl-sub-text">
                    {_.get(singleResource, 'type', '-')}
                  </Typography>
                  </Grid>
  {/* <Grid item xs={2}> */}
                <Grid item xs={4} md={4}>
                  <TextFiled>Name </TextFiled>
                  <Typography className="span-color pl-sub-text">
                    {_.get(singleResource, 'name', '-')}
                  </Typography>
                </Grid>
                {/* <Grid item xs={4} md={4}>
                  <TextFiled>Nick Name </TextFiled>
                  <Typography className="span-color pl-sub-text">
                    {_.get(singleResource, 'nickName', '-')}
                  </Typography>
                </Grid> */}
  {/* <Grid item xs={2}> */}
                <Grid item xs={4} md={4}>
                  <TextFiled>Location</TextFiled>
                  <Typography className="span-color pl-sub-text">
                    location
                  </Typography>
                  </Grid>
  {/* <Grid item xs={2}> */}
                <Grid item xs={4} md={4}>
                  <TextFiled>Code </TextFiled>
                  <Typography className="span-color pl-sub-text">
                    {_.get(singleResource, 'code', '-')}
                  </Typography>
                </Grid>
                {/* <Grid item xs={4} md={4}>
                  <TextFiled>Installed at</TextFiled>
                  <Typography className="span-color pl-sub-text">
                    {_.get(singleResource, 'department.name', '-')}
                  </Typography>
                </Grid> */}
              </Grid>
            </Box>
          </Grid>
          {/* <Grid item xs={12} > */}
          <Grid item xs={6} md={6}>
            <Box className="details-box-border padding-bottom" mr={'10px'}>
              <Typography id="modal-modal-title" className="details-sub-title">
                Utilization & Plan details
                </Typography>
      {/* <Grid container spacing={0}> */}
              <Grid container display={'flex'}>
                <Grid item xs={4} md={5}>
                  <TextFiled>24 hr Utilization </TextFiled>
                  <Typography className="span-color pl-sub-text">
                    10%
                  </Typography>
                  </Grid>
        {/* <Grid item xs={2}> */}
                <Grid item xs={4} md={5}>
                  <TextFiled>1 Week Utilization</TextFiled>
                  <Typography className="span-color pl-sub-text">
                    10%
                  </Typography>
                  </Grid>
        {/* <Grid item xs={2}> */}
                <Grid item xs={4} md={2}></Grid>
                <Grid item xs={4} md={5}>
                  <TextFiled>24 hr Plan</TextFiled>
                  <Typography className="span-color pl-sub-text">
                    10%
                  </Typography>
                  </Grid>
        {/* <Grid item xs={2}> */}
                <Grid item xs={4} md={5}>
                  <TextFiled>1 Week Plan</TextFiled>
                  <Typography className="span-color pl-sub-text">
                    10%
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          </Grid>
        {/* <Grid item xs={12}> */}
        <Grid item xs={12} md={12} container display={'flex'} mt={'20px'}>
          <Grid item xs={6} md={6}>
            <Box className="details-box-border padding-bottom" mr={'10px'}>
              <Typography id="modal-modal-title" className="details-sub-title">
                Other Details
              </Typography>
              <Grid container display={'flex'}>
                {/* <Grid item xs={2} md={2}> */}
                <Grid item xs={4} md={4}>
                  <TextFiled>Critical MC</TextFiled>
                  <Typography className="span-color pl-sub-text">
                    {singleResource?.criticalMc ? singleResource?.criticalMc : '-' }
                  </Typography>
                </Grid>
                {/* <Grid item xs={4} md={4}>
                  <TextFiled>Schedule </TextFiled>
                  <Typography className="span-color pl-sub-text">
                    {_.get(singleResource, 'schedule', '-')}
                  </Typography>
                </Grid> */}
                {/* <Grid item xs={2} md={2}> */}
                <Grid item xs={4} md={4}>
                  <TextFiled>PM Frequency</TextFiled>
                  <Typography className="span-color pl-sub-text">
                    {singleResource?.maintenanceDetails?.[0]?.pmFrequency ? singleResource?.maintenanceDetails?.[0]?.pmFrequency : '-'}
                  </Typography>
                </Grid>
                {/* <Grid item xs={2} md={2}> */}
                <Grid item xs={4} md={4}>
                  <TextFiled>Capacity </TextFiled>
                  <Typography className="span-color pl-sub-text">
                    {singleResource?.capacity ? singleResource?.capacity : '-' }
                  </Typography>
                </Grid>
                {/* <Grid item xs={2} md={2}> */}
                <Grid item xs={4} md={4}>
                  <TextFiled>Capacity unit</TextFiled>
                  <Typography className="span-color pl-sub-text">
                    job card
                  </Typography>
                </Grid>
                {/* <Grid item xs={2} md={2}> */}
                <Grid item xs={4} md={4}>
                  <TextFiled>Specification</TextFiled>
                  <Typography className="span-color pl-sub-text">
                    {singleResource?.specification ? singleResource?.specification : '-' }
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Box className="">
          <Grid container spacing={2} className="form-wrapper" wrap="nowrap">
            <Grid item lg={12} md={12} >
            {/* {rows?.length > 0 &&
            <> */}
              <Box className="font-size-18" marginBottom={'20px'}>Maintenance Schedule</Box>
              <TableComponent rows={rows} columns={columns} />
              {/* </>} */}
            </Grid>
          </Grid>
          </Box>
          <Box className="">
          <Grid container spacing={2} className="form-wrapper" wrap="nowrap">
            <Grid item lg={12} md={12} >
            {/* {TaskRows?.length > 0 &&
            <> */}
              <Box className="font-size-18" marginBottom={'20px'}>Task History</Box>
              <TableComponent rows={TaskRows} columns={TaskColumns} />
              {/* </>} */}
            </Grid>
          </Grid>
        </Box>
        <Box className="">
          <Grid container spacing={2} className="form-wrapper" wrap="nowrap">
            <Grid item lg={12} md={12} >
            {/* {BreakdownRows?.length > 0 &&
            <> */}
              <Box className="font-size-18" marginBottom={'20px'}>Breakdown History</Box>
              <Box className="department-table-width">
               <TableComponent rows={BreakdownRows} columns={Breakdowncolumns} />
              </Box>
              {/* </>} */}
            </Grid>
          </Grid>
        </Box>
      </Box>
       : <Box className='progress-parent-machine-main'>
                <CircularProgress />
              </Box>}

      {open && (
        <MaintenanceScheduleModal
          basicDetailForm={singleResource?.maintenanceDetails}
          open={open}
          DetailsHandleClose={handleDetailsClose}
          setOpen={() => setOpen(false)}
          onSubmit={handleMaintenanceFormSubmit}
          isEdit={true}
        />
      )}
      <DeleteWarningModal
        open={deleteRecord}
        handleOpen={handleDeleteOpen}
        handleClose={handleDeleteClose}
      />
         {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
         )}
    </Box>
  )
}

export default ResourceDetails
