import * as React from 'react'
import Box from '@mui/material/Box'
import './approvedmodal.css'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import CustomizedModal from '../../leastcomponent/custommodal/commonmodal'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { useUpdateSalesMember } from '../../hooks/jobcardHooks'
// import { useNavigate } from 'react-router-dom'

const JobcardChengeConfirmModel = (props) => {
  // const navigate = useNavigate()
  const theme = useTheme()
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'))
  const {
    open,
    handleClose,
    id,
    dirty,
    handleUpdate,
    changedCount
    // number
  } = props
  console.log('changedCount11===>', changedCount)

  // console.log('newId===>', id)
  // console.log('newdirty===>', dirty)
  // console.log('handleUpdate===>', handleUpdate)
  const { mutate: updateSalesMember } = useUpdateSalesMember()
  return (
    <React.Fragment>
      <CustomizedModal
        isHeadingName={false}
        // heading={'Approve Job Card'}
        open={open}
        onClick={handleClose}
        // width="30%"
        width={isMdScreen ? '40%' : '30%'}
      >
                <Box className="approved-padding">
          <Box className="approved-job-card-box">
            {/* TODO: for future use */}

                   {/* {dirty
                     ? <Box className="approved-job-card-main-box">
                    Changes have been made.
                    Are you sure you want to approve the Job Card?
                    </Box>
                     : <Box className="approved-job-card-main-box">
                    No changes have been made.
                    Are you sure you want to approve the Job Card?
              </Box>} */}
              {dirty
                ? <Box className="approved-job-card-main-box">
                    Changes have been made. Are you sure you want to  change {changedCount} field(s)?
                    {/* Changes have been made. Are you sure you want to change ${changedCount} ${changedCount > 1 ? 'fields' : 'field'}?` */}
                    </Box>
                : <Box className="approved-job-card-main-box">
                    No changes have been made.
                    Are you sure you want to update the Job Card?
                    </Box>}
                  </Box>
                </Box>

              <Grid className="approved-yes-btn1">
                <CustomButton
                  className="button-box"
                  type="button"
                  title="Confirm"
                  onClick={() => {
                    if (dirty) {
                      handleUpdate()
                      // setTimeout(() => { updateSalesMember({ id }) }, 500)
                    } else {
                      updateSalesMember({ id })
                    }
                    handleClose()
                    // navigate(`/edit-job-card/${id}`)
                  }}
                />
              </Grid>

      </CustomizedModal>
    </React.Fragment>
  )
}
export default JobcardChengeConfirmModel
