import * as React from 'react'
import { Box } from '@mui/system'
import { Modal, Typography, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
// TODO : if need in  fututre
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
// import { Button } from '@mui/base'
// import dayjs from 'dayjs'
// import TextField from '@mui/material/TextField'
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import { ErrorMessage, Form, Formik } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
import CustomSelect from '../../leastcomponent/customselect/customselect'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import JobCardConfirmModal from './jobCardConfirmModal'
import { useCompleteTask } from '../../hooks/jobcardHooks'
import * as Yup from 'yup'
import PushToPendingModal from './pushToPendingModal'
import { useGetfullListOperator } from '../../hooks/resourceHooks'
import TimePic from '../../leastcomponent/TimePic/TimePic'
import Required from '../../pages/commoncomponents/required'
// import { AiOutlineInfoCircle } from 'react-icons/ai'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  height: 500,
  overflow: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  overFlow: 'auto',
  padding: '20px 20px 20px 20px'
}
const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px'
}))
const HeadBox = styled(Box)(() => ({
  '.btn-box': {
    display: 'flex',
    justifyContent: 'end'
    // margin: '3px 20px 30px 0px'
  },
  '.customFormControl': {
    fontSize: '1px !important',
    fontWeight: '800'
  },
  '.customRadioGroup': {
    fontSize: '14px',
    fontWeight: '400',
    display: 'flex'
  },
  '.css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
    borderRadius: '32px !important'
  },
  '.validation-error': {
    color: 'red'
  },
  '.validation-errors': {
    color: 'red',
    paddingTop: '20px !important',
    fontSize: '12px !important'
  },
  '.jobcardform': {
    // padding: '24px 0px 0px 24px',
    display: 'flex',
    justifyContent: 'end'
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A'
      }
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px !important'
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px 12px !important'
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important'
    }
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px 12px'
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px'
    }
  },
  '.btn-text-transform': {
    textTransform: 'capitalize !important'
  }
}))
// TODO : if need in  fututre
// function ActionList (props) {
//   const { onAccept, onCancel, onSetToday } = props
//   const actions = [
//     { text: 'Apply', method: onAccept },
//     { text: 'Cancel', method: onCancel },
//     // { text: 'Clear', method: onClear },
//     { text: 'Today', method: onSetToday }
//   ]
//   return (
//     <Box className='datepicker-btn-wrapper-factory-layout'>
//       <Button className='blue-color datepicker-btn-factory-layout' onClick={() => onCancel()}>
//         {actions[1].text}
//       </Button>
//       <Button className='blue-bg datepicker-btn' variant='outlined' onClick={() => onAccept()}>
//         {actions[0].text}
//       </Button>
//     </Box>
//   )
// }
const JobCardDetailsModal = (props) => {
  const { mutate: completeTask } = useCompleteTask()
  // const { processData } = props
  const [newopen, setNewOpen] = React.useState(false)
  const handleDetailsClose = () => {
    setNewOpen(false)
  }
  const { open, DetailsHandleClose, tasks, data2 } = props
  const selectDepartmentid = localStorage.getItem('selectDepartmentid')
  const { data: opratorData } = useGetfullListOperator()
  const newData = props?.data2?.[0]?.bomprocess?.find(
    (data) => data?.department?.id === selectDepartmentid
  )
  const initialValue = tasks?.history?.map((item) => {
    return {
      taskName: item?.taskNickName,
      producedQuantity: '',
      machiningTime: '',
      operator: '',
      actualSetUpTime: '',
      breakdownTime: '',
      breakdownReason: '',
      taskId: item?.taskId
    }
  })
  const options = opratorData?.data?.map((item) => {
    return {
      value: item?.id,
      label: item?.name
    }
  })
  // TODO : if need in  fututre
  // const icon = <AiOutlineInfoCircle color="red" />

  // const validationSchema = Yup.object().shape({

  //   dateTime: Yup.date().required('Date and time are required'),
  //   duration: Yup.number()
  //     .required('Duration is required')
  //     .min(1, 'Duration must be at least 1 minute')
  //     .max(1440, 'Duration cannot be more than 1440 minutes')

  // })
  const validationSchema = Yup.object().shape({
    task: Yup.array().of(
      Yup.object().shape({
        operator: Yup.string().required('Name is required'),
        producedQuantity: Yup.string().required(
          'produced Quantity is required'
        ),
        machiningTime: Yup.string().required('Machining Time  is required'),
        actualSetUpTime: Yup.string().required('Setup Time is required')
      })
    )
  })
  const [isCnfModalOpen, setIsCnfModalOpen] = React.useState(false)
  const [pushToPendingId, setPushToPendingId] = React.useState('')
  const [jcNum, setJcNum] = React.useState('')

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={DetailsHandleClose}
        aria-labelledby=""
        aria-describedby="modal-modal-description"
      >
        <HeadBox sx={style}>
          <Box className="title-head-box">
            <Typography className="top-up-name">Update Task Status</Typography>
            <CloseIcon className="close-icon" onClick={DetailsHandleClose} />
          </Box>
          <Box mx={'25px !important'}></Box>
          <Grid item xs={12}>
            <Box>
              <Grid
                container
                spacing={2}
                paddingLeft={'25px'}
                paddingTop={'5px'}
              >
                {/* Row 1 */}
                <Grid item xs={6} container alignItems="center">
                  <Typography variant="subtitle2" color="textSecondary">
                    Part Name
                  </Typography>
                  <Typography
                    className="span-color pl-sub-text"
                    fontWeight="bold"
                  >
                    {data2?.[0]?.partName}
                  </Typography>
                </Grid>
                <Grid item xs={6} container alignItems="center">
                  <Typography variant="subtitle2" color="textSecondary">
                    Part Code
                  </Typography>
                  <Typography
                    className="span-color pl-sub-text"
                    fontWeight="bold"
                  >
                    {data2?.[0]?.itemCode}
                  </Typography>
                </Grid>

                {/* Row 2 */}
                <Grid item xs={6} container alignItems="center">
                  <Typography variant="subtitle2" color="textSecondary">
                    Process/ Department
                  </Typography>
                  <Typography
                    className="span-color pl-sub-text"
                    fontWeight="bold"
                  >
                    {tasks?.name}
                  </Typography>
                </Grid>
                <Grid item xs={6} container alignItems="center">
                  <Typography variant="subtitle2" color="textSecondary">
                    Sequence Number
                  </Typography>
                  <Typography
                    className="span-color pl-sub-text"
                    fontWeight="bold"
                  >
                    {newData?.numericIndex}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Box padding={'10px 24px'} sx={{
            borderBottom: '1px solid #dedede',
            padding: '10px 10px 20px 10px'
          }}>
            {/* < hr style={{ color: '#dedede' }}/> */}
          </Box>
          <Formik
            initialValues={{
              task: initialValue
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
              completeTask(values)
            }}
          >
            {({ handleChange, values, setFieldValue, touched, errors }) => (
              <Form>
                {values?.task?.map((task, index) => {
                  return (
                    <Box
                      key={`task-${index}`}
                      sx={{
                        borderBottom: '1px solid #dedede',
                        padding: '10px 10px 20px 10px'
                      }}
                    >
                      <Grid
                        key={`task-${index}`}
                        spacing={2}
                        container
                        className="jobcardform"
                      >
                        <Grid
                          item
                          xs={12}
                          className="title-head-box"
                          display={'flex'}
                          flexDirection={'column'}
                        >
                          <Typography className="top-up-name">
                            {index + 1}. {task.taskName}
                          </Typography>
                          <Grid container spacing={0} display={'flex'} >
                            <Grid item xs={6}>
                              <Box display="flex" alignItems="center">
                                <TextFiled className="pl-sub-text_new">
                                  Machine/Workstation:
                                </TextFiled>
                                <Typography className="span-color pl-sub-text">
                                  {newData?.task?.[index]?.resource?.name}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box display="flex" alignItems="start">
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                  }}
                                >
                                  <TextFiled className="pl-sub-text">
                                    Machine/Workstation
                                  </TextFiled>
                                  <TextFiled className="pl-sub-text">
                                    code:
                                  </TextFiled>
                                </Box>
                                <Typography className="span-color pl-sub-text">
                                  {newData?.task?.[index]?.resource?.code}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box display="flex" alignItems="center">
                                <TextFiled className="pl-sub-text_new">
                                  Total Quantity Required:
                                </TextFiled>
                                <Typography className="span-color pl-sub-text">
                                  {data2?.[0]?.quantity}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* // TODO : if need in  fututre */}
                        {/* <Grid item xs={12} md={3.9} xl={3.9}>
                          <Typography className='field-title1'>
                          End date and time
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              orientation='portrait'
                              dayOfWeekFormatter={(day) => `${day}`}
                              className='date-picker'
                              disablePast
                              views={['year', 'day', 'month']}
                              closeOnSelect={false}
                              value={task.dateTime || dayjs(new Date())}
                              inputFormat='DD/MM/YYYY'
                              name={`task.${index}.dateTime`}
                              onChange={(newValue) => {
                                setFieldValue(`task.${index}.dateTime`, newValue?.toDate())
                              }}
                              components={{
                                ActionBar: ActionList
                                // OpenPickerIcon: ClockIcon,
                              }}
                              componentsProps={{
                                actionBar: {
                                  actions: ['today', 'cancel', 'clear']
                                }
                              }}
                              renderInput={(params) => {
                                if (task?.dateTime) {
                                  return (
                                    <TextField
                                      {...params}
                                      placeholder='Select date'
                                      className='date-picker-fieldset'
                                    />
                                  )
                                } else {
                                  delete params.inputProps
                                  return (
                                    <TextField
                                      {...params}
                                      disabled={true}
                                      placeholder='Select date'
                                      className='date-picker-fieldset'
                                      error={false}
                                    />
                                  )
                                }
                              }}
                            />

                          </LocalizationProvider>
                          {errors?.task?.[index]?.dateTime && (
                      <Typography className='validation-errors'>{errors?.task?.[index]?.dateTime}</Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={3.9} xl={3.9}>
                          <Box paddingRight={'10px'}>
                            <CustomInput
                              label="Remarks"
                              placeholder="Enter Remarks"
                              handleChange={handleChange}
                              value={task?.remarks}
                              name={`task.${index}.remarks`}
                              isRequired={false}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={3.9} xl={3.9}>
                          <Box paddingRight={'10px'}>
                            <CustomInput
                              label="Duration (mins)"
                              placeholder="Enter duration"
                              handleChange={handleChange}
                              value={task?.duration}
                              name={`task.${index}.duration`}
                              isRequired={false}
                              // InputProps={{
                              //   endAdornment:
                              //   touched?.task?.[index]?.duration && errors?.task?.[index]?.duration
                              // }}
                              errors={errors?.task?.[index]?.duration}
                              touched={touched?.task?.[index]?.duration}
                            />
                          </Box>
                          {/* {errors?.duration && (
                       <Typography className='validation-error'>{errors?.duration}</Typography>
                          )} */}
                        {/* </Grid> */}
                        {/* TODO */}
                        {/* <Grid item xs={12} md={3.9} xl={3.9} mr={'15px'}>
                          <Typography className='field-title'>
                            Durarion
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              orientation='portrait'
                              dayOfWeekFormatter={(day) => `${day}`}
                              className='date-picker'
                              disablePast
                              views={['year', 'day', 'month']}
                              closeOnSelect={false}
                              value={values.duration || dayjs(new Date())}
                              name={`task.${index}.dateTime`}
                              inputFormat='DD/MM/YYYY'
                              onChange={(newValue) => {
                                setFieldValue('dateTime', newValue?.toDate())
                              }}
                              components={{
                                ActionBar: ActionList
                                // OpenPickerIcon: ClockIcon,
                              }}
                              componentsProps={{
                                actionBar: {
                                  actions: ['today', 'cancel', 'clear']
                                }
                              }}
                              renderInput={(params) => {
                                if (values?.dateTime) {
                                  return (
                                    <TextField
                                      {...params}
                                      placeholder='Select date'
                                      className='date-picker-fieldset'
                                    />
                                  )
                                } else {
                                  delete params.inputProps
                                  return (
                                    <TextField
                                      {...params}
                                      disabled={true}
                                      placeholder='Select date'
                                      className='date-picker-fieldset'
                                      error={false}
                                    />
                                  )
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </Grid> */}
                        {/* TODO */}
                        {/* <Grid item xs={12} md={2} xl={2} >
                          <Box paddingRight={"10px"}>
                            <CustomInput
                              label="Completion %"
                              color='gray'
                              placeholder="Enter Remarks"
                              handleChange={handleChange}
                              value={values?.remarks}
                              name="remarks"
                              isRequired={false}
                            />
                          </Box>
                        </Grid> */}
                        <Grid item xs={12} md={6}>
                          <Box paddingRight={'10px'}>
                            <CustomSelect
                              label="Operator Name"
                              placeholder="Enter Operator Name"
                              handleChange={handleChange}
                              name={`task.${index}.operator`}
                              value={values?.task?.[index]?.operator}
                              handleSelectChange={(e) => {
                                setFieldValue(
                                  `task.${index}.operator`,
                                  e?.target?.value
                                )
                              }}
                              isRequired={true}
                              options={options}
                            />
                          </Box>
                          <ErrorMessage name={`task.${index}.operator`}>
                            {(message) => (
                              <Typography
                                className="validation-error"
                                sx={{ fontSize: '14px' }}
                              >
                                {' '}
                                {message}
                              </Typography>
                            )}
                          </ErrorMessage>
                          {/* {errors?.task?.[index]?.operator && (
                       <Typography className='validation-error' sx={{ fontSize: '14px' }}>{errors?.task?.[index]?.operator}</Typography>
                          )} */}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box paddingRight={'10px'}>
                            <CustomInput
                              label="Quantity Produced"
                              placeholder="Enter Quantity Produced"
                              handleChange={handleChange}
                              value={task?.producedQuantity}
                              name={`task.${index}.producedQuantity`}
                              isRequired={true}
                            />
                          </Box>
                          <ErrorMessage name={`task.${index}.producedQuantity`}>
                            {(message) => (
                              <Typography
                                className="validation-error"
                                sx={{ fontSize: '14px' }}
                              >
                                {' '}
                                {message}
                              </Typography>
                            )}
                          </ErrorMessage>

                          {/* {errors?.task?.[index]?.producedQuantity && (
                       <Typography className='validation-error' sx={{ fontSize: '14px' }}>{errors?.task?.[index]?.producedQuantity}</Typography>
                          )} */}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box paddingRight={'10px'}>
                            <Typography className="field-title">
                              Machining Time [hh:mm]
                              <Required />
                            </Typography>
                            <TimePic
                              key={index}
                              time={values.task[index].machiningTime}
                              setFieldValue={setFieldValue}
                              setFieldName={`task.${index}.machiningTime`}
                              customWidth={true}
                            />
                          </Box>
                          <ErrorMessage name={`task.${index}.machiningTime`}>
                            {(message) => (
                              <Typography
                                className="validation-error"
                                sx={{ fontSize: '14px' }}
                              >
                                {' '}
                                {message}
                              </Typography>
                            )}
                          </ErrorMessage>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box paddingRight={'10px'}>
                            <Typography className="field-title">
                              Setup Time [hh:mm]
                              <Required />
                            </Typography>
                            <TimePic
                              key={index}
                              time={values.task[index].actualSetUpTime}
                              setFieldValue={setFieldValue}
                              setFieldName={`task.${index}.actualSetUpTime`}
                              customWidth={true}
                            />
                          </Box>
                          <ErrorMessage name={`task.${index}.actualSetUpTime`}>
                            {(message) => (
                              <Typography
                                className="validation-error"
                                sx={{ fontSize: '14px' }}
                              >
                                {' '}
                                {message}
                              </Typography>
                            )}
                          </ErrorMessage>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box paddingRight={'10px'}>
                            <Typography className="field-title">
                              Breakdown Time [hh:mm]
                            </Typography>
                            <TimePic
                              key={index}
                              time={values.task[index].breakdownTime}
                              setFieldValue={setFieldValue}
                              setFieldName={`task.${index}.breakdownTime`}
                              customWidth={true}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box paddingRight={'10px'}>
                            <CustomInput
                              label="Breakdown Reason"
                              placeholder="Enter Breakdown Reason"
                              handleChange={handleChange}
                              value={task?.breakdownReason}
                              name={`task.${index}.breakdownReason`}
                              isRequired={false}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )
                })}
                <Box className="btn-box" paddingTop={'70px'} gap={1}>
                  <CustomButton
                    variant="outlined"
                    title="Push back to pending"
                    type="button"
                    onClick={() => {
                      setIsCnfModalOpen(true)
                      setPushToPendingId(newData.id)
                      setJcNum(newData.number)
                    }}
                  />
                  <CustomButton
                    title="Confirm"
                    type="submit"
                    // onClick={() => setNewOpen(true)}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </HeadBox>
      </Modal>
      {newopen && (
        <JobCardConfirmModal
          open={newopen}
          DetailsHandleClose={handleDetailsClose}
          setOpen={() => setNewOpen(false)}
        />
      )}
      {isCnfModalOpen && (
        <PushToPendingModal
          id={pushToPendingId}
          jcNum={jcNum}
          open={isCnfModalOpen}
          handleClose={() => setIsCnfModalOpen(false)}
        />
      )}
    </React.Fragment>
  )
}
export default JobCardDetailsModal
