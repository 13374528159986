import { deleteRequest, get } from '../utils/index'

export const GetAllTaskPending = async (data) => {
  const page = data?.queryKey[1] || 1
  const departmentId = data?.queryKey[2]
  const partName = data?.queryKey?.[3] || ''
  const payload = { page, limit: 10, departmentId, partName }
  return get('/task/department-wise-all-task?taskStatus=pending', payload).then((res) => res.data)
}
export const GetAllTaskOngoing = async (data) => {
  const page = data?.queryKey[1] || 1
  const departmentId = data?.queryKey[2]
  const partName = data?.queryKey?.[3] || ''
  const payload = { page, limit: 10, departmentId, partName }
  return get('/task/department-wise-all-task?taskStatus=ongoing', payload).then((res) => res.data)
}
export const deleteTask = async (id) => {
  return deleteRequest(`/task/${id}`)
}
export const alltaskApi = {
  GetAllTaskPending,
  GetAllTaskOngoing,
  deleteTask
}
