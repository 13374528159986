/* eslint-disable array-callback-return */
import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import JobCardDetailsModal from '../../modals/jobcardmodal/jobCardDetailsModal'
import { useLocation, useParams } from 'react-router-dom'
import { useGetDeletedJobCard, useGetJobDetailsSingle, useHardDeleteJobCard, useRestoreJobCard } from '../../hooks/jobcardHooks'
import _ from 'lodash'
import NestedTableDeparment from '../../leastcomponent/customtable/nestedTableDepartment'
import moment from 'moment'
import ErrorPopup from '../../modals/errorpopup'

const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  fontSize: '14px !important'
}))
const BorderFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '0px',
  fontSize: '14px !important',
  padding: '4px 0px 4px 4px'
}))
const DetailsCard = () => {
  const [open, setOpen] = useState(false)
  const [tasks, setTasks] = useState({})
  const [processData, setProcessData] = useState({})
  const handleDetailsClose = () => {
    setOpen(false)
  }
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const { id, partId } = useParams()
  const location = useLocation()
  const isShowOptions = location.pathname.startsWith('/job-card-details')
  const isShowOptionsSidebar = location.state?.sidebarTab !== 3

  //  delete items jobcard
  // const { state } = location
  const isDeleted = location.pathname.startsWith('/deleted-items')
  const isRestore = location.pathname.startsWith('/deleted-items')
  // TODO:: delete task and restore task
  // const isDeletedTask = location.pathname.startsWith('/deleted-items')
  // const isRestoreTask = location.pathname.startsWith('/deleted-items')
  // const [isEdit, setIsEdit] = useState(false)
  const { mutate: hardDeleteJobCard } = useHardDeleteJobCard()
  const { mutate: restoreJobCard } = useRestoreJobCard()
  // TODO:: delete task and restore task
  // const { mutate: hardDeleteTask } = useHardDeleteTask()
  // const { mutate: restoreTask } = useRestoreTask()

  const handlePartDelete = () => {
    if (isDeleted) {
      hardDeleteJobCard(id)
    } else {
      // deleteSinglePart(id)
    }
  }
  const handlePartRestore = () => {
    if (isRestore) {
      restoreJobCard(id)
    } else {
      // nothing to restore
    }
  }

  const { data: getJobDetails, error } = useGetJobDetailsSingle(id, !!id && !isDeleted)
  const { data: getDeletedJobCard } = useGetDeletedJobCard(id, !!id && isDeleted)
  // const { data: getDeleteTask } = useGetDeleteTask(id, !!id && isDeletedTask)
  const selectDepartmentid = localStorage.getItem('selectDepartmentid')
  const getJobCardData = isDeleted ? getDeletedJobCard : getJobDetails
  const singleJobDetails = getJobCardData?.data
  const deptWiseJobDetails = singleJobDetails?.bompart?.filter((val) => {
    const bomProcessDeptIds = val?.bomprocess?.map((data) => data?.department?.id)
    return bomProcessDeptIds?.includes(selectDepartmentid?.toString())
  })
  useEffect(() => {
    if (error) {
      setIsErrorModalOpen(true)
      setErrorMessage(error?.data?.message)
    }
  }, [error])
  // const queryClient = useQueryClient()
  // const userData = queryClient.getQueryData([user.LOGGED_IN_USER])
  // const departments = userData?.data?.data?.user?.roleId?.departmentGroup?.departments?.[0]

  const mainCols = [
    { field: '#', headerName: '#', flex: 0.2 },
    { field: 'Process/depart Name', headerName: 'name', flex: 1 },
    { field: 'Process/department Code', headerName: 'Job Card Name', flex: 1 },
    { field: 'Department Group', headerName: 'Department Group', flex: 1 },
    { field: 'Action', headerName: 'Qty', flex: 1 }
  ]

  const finalDataColumns = [
    { field: 'id', headerName: '#', flex: 0.2 },
    { field: 'Task Name', headerName: 'Task Name', flex: 1 },
    { field: ' Task Type', headerName: 'Task Type', flex: 1 },
    { field: 'Description', headerName: 'Description', flex: 1 },
    { field: 'Status', headerName: 'Status', flex: 1 }
  ]
  // let finalDataOne = singleJobDetails?.bompart?.map((data, ind) => {
  //   let history = data.bomprocess?.flatMap(process =>
  //     process?.task?.map(task => {
  //       return {
  //         taskId: task.id,
  //         taskNickName: task.taskNickName,
  //         taskType: task.taskType?.name,
  //         description: task.description,
  //         taskStatus: task.taskStatus,
  //         department: process.department.id
  //       }
  //     })
  //   ) || []
  //   const singleProcess = data?.bomprocess?.find(process => process?.department?.id === selectDepartmentid)
  //   // console.log('dept', history, selectDepartmentid)
  //   history = history?.filter(his => his.department === selectDepartmentid)
  //   return {
  //     no: ind + 1,
  //     name: singleProcess?.department?.name,
  //     code: singleProcess?.department?.code,
  //     title: singleProcess?.department?.departmentgroups?.title,
  //     partId: data?.id,
  //     history
  //   }
  // }) || []
  let finalDataOne = singleJobDetails?.bompart?.flatMap((data, ind) => {
    return data?.bomprocess?.map(process => {
      const history = process?.task?.map(task => ({
        taskId: task.id,
        taskNickName: task.taskNickName,
        taskType: task.taskType?.name,
        description: task.description,
        taskStatus: task.taskStatus,
        department: process.department.id
      })) || []

      return {
        no: ind + 1,
        name: process?.department?.name,
        code: process?.department?.code,
        title: process?.department?.departmentgroups?.title,
        partId: data?.id,
        history
      }
    }) || []
  })

  if (partId) {
    finalDataOne = finalDataOne?.filter(item => item.partId === partId)
  }
  const breadCrumbData = [
    {
      title: isDeleted ? 'Delete Job Cards' : isShowOptionsSidebar ? 'Create Job Cards' : 'Job Cards',
      link: isShowOptionsSidebar ? '/job-card' : '/job-card-department'
    },
    {
      title: singleJobDetails?.name || 'Job Card Name',
      link: '#'
    }
  ]

  return (
    <React.Fragment>
      <Box className="production-team-head-overflow-hidden">
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={ isDeleted ? ` Delete Job Card ${singleJobDetails?.jobCardNumber ? '#' + singleJobDetails?.jobCardNumber : '-'}` : ` Job Card ${singleJobDetails?.jobCardNumber ? '#' + singleJobDetails?.jobCardNumber : '-'}`}
          backToLink={isShowOptionsSidebar ? '/job-card' : '/job-card-department'}
          activeTab="3"
          isPendingStatus
          status={singleJobDetails?.jobCardStatus}
          onClick={() => {
            // setOpen(true)
          }}
          isRestore={true}
        isDeleted={true}
        handleDelete={handlePartDelete}
        handleRestore={handlePartRestore}
        />

        <Box className="common-parent-class-overflow-auto-padding-10-50">
          <Box
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '3px',
              marginBottom: '25px'
            }}
          >
            <Box
              style={{
                fontSize: '16px',
                fontWeight: 600,
                background: '#F6F6F6',
                padding: '10px 20px'
              }}
            >
              Basic Details
            </Box>
            <Box style={{ borderTop: '1px solid #CDCDCD' }}></Box>
            <Box
              style={{
                padding: '20px'
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={3} md={3}>
                  <TextFiled>OC Number</TextFiled>
                  <Typography className="span-color">
                    {singleJobDetails?.ocNumber ? singleJobDetails?.ocNumber : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <TextFiled>Date Created</TextFiled>
                  <Typography className="span-color">
                    {' '}
                    {singleJobDetails?.createdAt ? moment(singleJobDetails?.createdAt).format('MM/DD/YYYY') : '-'}
                  </Typography>
                </Grid>
                {/* TODO:: for future use Customer reference */}
                {/* <Grid item xs={3}>
                <Typography className="details-label">
                  Customer reference
                </Typography>
                <Typography className="span-color">
                  098-9273-1783-111-018
                </Typography>
              </Grid> */}
                <Grid item xs={3} md={3}>
                  <TextFiled>Sale Order Number</TextFiled>
                  <Typography className="span-color">
                    {singleJobDetails?.saleOrderNumber ? singleJobDetails?.saleOrderNumber : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <TextFiled>Customer Name</TextFiled>
                  <Typography className="span-color">
                    {' '}
                    {singleJobDetails?.customerName ? singleJobDetails?.customerName : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <TextFiled>Customer Reference</TextFiled>
                  <Typography className="span-color">
                    {' '}
                    {singleJobDetails?.customerReference ? singleJobDetails?.customerReference : '-'}

                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <TextFiled>Product Code</TextFiled>
                  <Typography className="span-color">
                    {singleJobDetails?.productCode ? singleJobDetails?.productCode : '-'}

                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <TextFiled>Product Name</TextFiled>
                  <Typography className="span-color">
                    {singleJobDetails?.productName ? singleJobDetails?.productName : '-'}

                  </Typography>
                </Grid>
                 <Grid item xs={3} md={3}>
                  <TextFiled>Priority</TextFiled>
                  <Typography className="span-color">
                    {singleJobDetails?.priority ? singleJobDetails?.priority : '-'}

                  </Typography>
                </Grid>
                <Grid item xs={9} md={9}>
                  <TextFiled>Description</TextFiled>
                  <Typography className="span-color">
                    {singleJobDetails?.description ? singleJobDetails?.description : '-'}

                  </Typography>
                </Grid>
                <Grid item xs={2.3} md={2.3}>
                  <Box border={'1px solid #8A8A8A'} display={'flex'} mb={'6px'}>
                    <Grid item xs={9} md={9}>
                      <BorderFiled>Quantity </BorderFiled>
                    </Grid>
                    <Typography
                      className="span-color pl-sub-text"
                      alignItems={'center'}
                      mt={'3px'}
                    >
                    {singleJobDetails?.quantity ? singleJobDetails?.quantity : '-'}

                    </Typography>
                  </Box>
                  {/* <Box border={'1px solid #8A8A8A'} display={'flex'} mb={'6px'}>
                    <Grid item xs={9} md={9}>
                      <BorderFiled>Produced Quantity </BorderFiled>
                    </Grid>
                    <Typography
                      className="span-color pl-sub-text"
                      alignItems={'center'}
                      mt={'3px'}
                    >
                      {_.get(singleJobDetails, 'producedQuantity', '')}
                    </Typography>
                  </Box>
                  <Box border={'1px solid #8A8A8A'} display={'flex'} mb={'6px'}>
                    <Grid item xs={9} md={9}>
                      <BorderFiled>Balance Quantity </BorderFiled>
                    </Grid>
                    <Typography
                      className="span-color pl-sub-text"
                      alignItems={'center'}
                      mt={'3px'}
                    >
                      {_.get(singleJobDetails, 'balanceQuantity', '')}
                    </Typography>
                  </Box> */}
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* {deptWiseJobDetails?.map((data, ind) => ( */}
           {singleJobDetails?.bompart?.map((data, ind) => (
             (data?.id === partId || isShowOptions) && (
            <Box
              style={{
                border: '1px solid #CDCDCD',
                borderRadius: '3px',
                marginBottom: '26px'
              }}
              key={ind}
            >
              <Box
                style={{
                  fontSize: '16px',
                  fontWeight: 600,
                  background: '#F6F6F6',
                  padding: '10px 20px'
                  // cursor: 'pointer'
                }}
                // onClick={() => {
                //   setTasks(finalDataOne?.find(item => item.partId === partId))
                //   if (partId) {
                //     setOpen(true)
                //   }
                // }}
              >
                {ind + 1}. {_.get(data, 'partName', '-')}
              </Box>
              <Box style={{ borderTop: '1px solid #CDCDCD' }}></Box>
              <Box
                style={{
                  padding: '20px'
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={3} md={3}>
                    <TextFiled>Item Code</TextFiled>
                    <Typography className="span-color">
                      {data?.itemCode ? data?.itemCode : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <TextFiled>Number of</TextFiled>
                    <Typography className="span-color">{data?.numberOf ? data?.numberOf : '-'}</Typography>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <TextFiled>Drawing number</TextFiled>
                    <Typography className="span-color">
                      {data?.drawingNumber ? data?.drawingNumber : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <TextFiled>Weight (gms)</TextFiled>
                    <Typography className="span-color">
                      {data?.weight ? data?.weight : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <TextFiled> Final Size - OD (mm)</TextFiled>
                    <Typography className="span-color">
                      {data?.sizeOd ? data?.sizeOd : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <TextFiled>Final Size - ID (mm)</TextFiled>
                    <Typography className="span-color">
                      {data?.sizeId ? data?.sizeId : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <TextFiled>Final Size - L (mm)</TextFiled>
                    <Typography className="span-color">
                      {data?.sizeL ? data?.sizeL : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3}>

                  </Grid>
                  <Grid item xs={9} md={9}>
                    <TextFiled>Description</TextFiled>
                    <Typography className="span-color">
                      {data?.description ? data?.description : '-'}
                    </Typography>
                  </Grid>
                  {
                      partId &&
                  <Grid item xs={12} md={12}>
                    <Box>
                      <NestedTableDeparment
                        rows={finalDataOne?.filter(item => item.partId === partId)}
                        columns={finalDataColumns}
                        mainCols={mainCols}
                        tableName="Process details"
                        isPagination={false}
                        setTasks={setTasks}
                        partId={partId}
                        data={finalDataOne?.find(item => item.partId === partId)}
                        openModal={setOpen}
                        setProcessData={setProcessData}
                      />
                    </Box>
                  </Grid>
                    }
                </Grid>
              </Box>
            </Box>
             )
           ))}
        </Box>
        {open && (
          <JobCardDetailsModal
            tasks={tasks}
            processData={processData}
            open={open}
            DetailsHandleClose={handleDetailsClose}
            setOpen={() => setOpen(false)}
            data1={finalDataOne}
            data2={deptWiseJobDetails}
          />
        )}
         {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
         )}
      </Box>
    </React.Fragment>
  )
}
export default DetailsCard
