import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import {
  Box,
  Grid,
  Typography,
  Select,
  CircularProgress,
  FormControl,
  MenuItem
} from '@mui/material'
import { ErrorMessage, Form, Formik } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import * as Yup from 'yup'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import { useLocation, useParams } from 'react-router-dom'
import ErrorPopup from '../../modals/errorpopup'
import Required from '../commoncomponents/required'
import { useGetFullDepartments } from '../../hooks/departmentHooks'
import { useAddOperator, useGetOperatorSingle, useUpdateOperator, useValidateOprator } from '../../hooks/resourceHooks'
const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '8.5px 14px',
    fontSize: '14px !important'

  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A'
  }
}))
const CreateOperator = () => {
  const location = useLocation()
  const isDeleted = location.pathname.startsWith('/deleted-items')
  const { id } = useParams()
  const [isEdit, setIsEdit] = useState(false)
  const { data: departmentData } = useGetFullDepartments()
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const icon = <AiOutlineInfoCircle color="red" />

  const { data: getOperatorSingle, error: getOperatorError, isFetching: isGetsingleOperator, isSuccess } = useGetOperatorSingle(id, !!id)
  const singleOperator = getOperatorSingle?.data
  useEffect(() => {
    if (id) {
      setIsEdit(true)
    }
  }, [id])
  const {
    mutate: addOperator,
    isError: isAddUserOperator,
    error: addOperatorError,
    isLoading
  } = useAddOperator()

  const {
    mutate: editOperator,
    isError: isEditOperatorError,
    error: updateOperatorError
  } = useUpdateOperator()

  const {
    mutate: validateOprator,
    data: validateData,
    isLoading: isValidating
  } = useValidateOprator()

  React.useEffect(() => {
    if (isAddUserOperator || isEditOperatorError || getOperatorError) {
      setErrorMessage(
        isAddUserOperator
          ? addOperatorError?.data?.message
          : isEditOperatorError
            ? updateOperatorError?.data?.message
            : getOperatorError?.data?.message
      )
      setIsErrorModalOpen(true)
    }
  }, [isAddUserOperator, isEditOperatorError, getOperatorError, addOperatorError, updateOperatorError])

  React.useEffect(() => {
    if (id && isSuccess) {
      if (singleOperator?.name) {
        validateOprator({
          name: singleOperator?.name,
          id
        })
      }
    }
  }, [
    id,
    isSuccess,
    singleOperator?.name,
    id
  ])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Operator Name is required'),
    department: Yup.string().required('Department is required')
  })

  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={id ? 'Edit Operator' : 'Create Operator'}
        backToLink={'/resource'}
        activeTab="3"
      />

      {!isGetsingleOperator
        ? <Box className="" paddingTop={'50px'}>
        <Typography className="form-heading-edit">
        Create Operator{' '}
        </Typography>
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: isEdit ? singleOperator?.name : '',
            department: isEdit ? singleOperator?.department?.id : ''
          }}
          onSubmit={(values) =>
            id ? editOperator({ ...values, id }) : addOperator(values)
          }
          validationSchema={validationSchema}
        >
          {({ errors, handleChange, touched, values }) => (
            <React.Fragment>
              <Form>
                <Box className="user-managegement-form-child1">
                  <Box className="padding-y-50">
                    <Grid spacing={2} container paddingTop={2}>
                      <Grid item xs={12} md={4} xl={4}>
                        <CustomInput
                          label="Operator Name"
                          placeholder="Enter Operator Name"
                            // handleChange={handleChange}
                            handleChange={(e) => {
                              validateOprator({
                                name: e.target.value,
                                ...(id && { id })
                              })
                              handleChange(e)
                            }}
                          value={values?.name}
                          name="name"
                          InputProps={{
                            endAdornment:
                              touched?.name && errors?.name && icon
                          }}
                          errors={errors?.name}
                          touched={touched?.name}
                          />
                              {!validateData?.data.isValid && values?.name && !isValidating
                                ? (
                      <Typography className='validation-text'>
                        {'Operator Name already taken'}
                      </Typography>
                                  )
                                : (
                      <ErrorMessage
                        name='name'
                        render={(msg) => <Typography className='validation-text'>{msg}</Typography>}
                      />
                                  )}
                      </Grid>
                      <Grid item xs={4} md={4} lg={4}>
                        <Typography className="field-title" mt={'2px'}>
                          Department
                          <Required />
                        </Typography>
                        <FormControl fullWidth>
                          <Selects
                            name="department"
                            variant="outlined"
                            value={
                              departmentData?.data?.length
                                ? values?.department
                                : ''
                            }
                            onChange={handleChange}
                            displayEmpty
                          >
                            <MenuItem value="">
                              <span className="menu-item-span">
                                Select Department
                              </span>
                            </MenuItem>

                            {departmentData?.data?.map((data, ind) => {
                              return (
                                <MenuItem value={data?.id} key={ind}>
                                  {data?.name}
                                </MenuItem>
                              )
                            })}
                          </Selects>
                        </FormControl>

                        {touched.department && errors.department && (
                          <Typography className="validation-text">
                            {typeof errors.department === 'string'
                              ? errors.department
                              : ''}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    {/* <Divider /> */}
                  </Box>
                </Box>
               { !isDeleted && <Box className="button-child">
                  <CustomButton
                    disabled={isLoading}
                  title="Submit" type="submit" />
                </Box>}
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </Box>
        : <Box className='machine-kanban-progress'>
       <CircularProgress />
     </Box>}
      {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
      )}
    </Box>
  )
}

export default CreateOperator
