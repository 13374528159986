import { Box, InputAdornment, useMediaQuery, useTheme } from '@mui/material'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import styled from '@emotion/styled'
import SearchIcon from '@mui/icons-material/Search'
// import { useNavigate } from 'react-router-dom'
import { useSearchData } from '../hooks/searchoptionhook'
import React, { useEffect, useState } from 'react'
import { useGetFullresourceMachine } from '../hooks/resourceHooks'
import { useJobSlipsSingleById } from '../hooks/jobslipshooks'
function SearchJobslip ({ setSelectMachine, selectMachine }) {
  const [touched, setTouched] = useState(false)
  const [data, setData] = useState([])
  const [enableSearchAPI, setenableSearchAPI] = useState(true)

  const { data: getAllSearchData, isLoading } = useSearchData(touched && data?.length === 0)

  // const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading) {
      if (enableSearchAPI) {
        setData(getAllSearchData)
        setenableSearchAPI(false)
      }
      setenableSearchAPI(false)
    }
  }, [data.length, getAllSearchData?.length])

  const [reso, setReso] = useState('')
  const [selectMachineData, setSelectMachineData] = useState('')
  const selectDepartmentid = localStorage.getItem('selectDepartmentid')
  const [selectPriority, setSelectePriority] = useState('')

  const { data: GetJobSlipsSingleById } = useJobSlipsSingleById(selectDepartmentid, selectMachineData, selectPriority)

  const handleSelectChange = (selectedOption) => {
    setSelectMachineData(selectedOption)
  }
  const listData = GetJobSlipsSingleById?.data?.results
  console.log(listData, setSelectePriority, setReso, handleSelectChange)
  const { data: resourcesData } = useGetFullresourceMachine(reso, !!reso)
  const options = resourcesData?.data?.map((item) => {
    return {
      value: item?.id,
      label: item?.name
    }
  })
  const theme = useTheme()
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'))
  const isLgScreen = useMediaQuery(theme.breakpoints.only('lg'))
  const BoxWrapper = styled(Box)(() => ({
    display: 'inline-flex',
    boxSizing: 'border-box',
    height: '40px',
    borderRadius: '4px',
    border: '1px solid rgba(205, 205, 205, 1)',
    // background: 'rgba(246, 246, 246, 1)',
    // boxShadow: '0px 2px 10px rgba(40, 40, 40, 0.1)',
    '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '0 !important'
    },
    '.dropdown': {
      background: '#F4F9FF',
      borderRadius: '0 4px 4px 0px',
      border: 'none !important',
      padding: '0px !important',
      // width: 'personName?.length' ? '160px' : '80px',
      width: '160px',
      height: '40px !important',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    },
    '.autocomplete': {
      '& .MuiOutlinedInput-root': {
        padding: '0px !important'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      },
      '& .MuiAutocomplete-inputFocused': {},
      // width: isMdScreen ? (isChangeWidth ? '220px' : '300px') : '300px',
      // width: (isMdScreen) ? '250px' : isLgScreen ? '285px' : '300px',
      width: (isMdScreen) ? '220px' : isLgScreen ? '250px' : '300px',
      '& .MuiAutocomplete-input': {
        padding: '8px 0px 0px 0px !important'
      }
    },

    '.search-input': {
      // width: isMdScreen ? (isChangeWidth ? '220px' : '300px') : '300px',
      width: (isMdScreen) ? '250px' : isLgScreen ? '285px' : '300px',
      fontSize: '16px',
      color: 'black'
    },
    '.search-icon': {
      padding: '8px 0.5px 0px 6.5px',
      color: 'gray'
    },
    '.custom-checkbox': {
      '& .MuiSvgIcon-root': {
        fontSize: '70px !important',
        borderRadius: '50px !important'
      }
    },
    '.all-set': {
      border: '1px solid red',
      PaddingBottom: '10px'
    }
  }))

  return (
    <BoxWrapper display={'flex'}>
      <Autocomplete
        className="autocomplete"
        forcePopupIcon={false}
        id="grouped-demo"
        options={options}
        value={options?.find((ele) => ele?.value === selectMachine)?.label}
        // groupBy={(option) => option?.options}
        getOptionLabel={(option) => {
          return option?.label || ''
        }}
        onChange={(e, value) => {
          const path = value?.value
          // handleSelectChange(path)
          setSelectMachine(path)
          // setVal(value);
        }}
        onBlur={() => setTouched(false)}
        // onOpen={() => setTouched(true)}
        renderInput={(params) => (
          <TextField
            {...params}
            className="search-input"
            InputProps={{
              ...params.InputProps,
              value: options?.label,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className="search-icon" />
                </InputAdornment>
              )
            }}
            placeholder={`${selectMachine ? options?.find((ele) => ele?.value === selectMachine)?.label : 'Select Machine'}`}
          />
        )}

      />

    </BoxWrapper>
  )
}

export default SearchJobslip
