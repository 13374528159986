import React from 'react'
// RC Time Picker
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import moment from 'moment'
import { Box } from '@mui/material'
import styled from '@emotion/styled'

const TimePic = ({ time, setFieldValue, setFieldName = 'time', customWidth = false }) => {
  const MainBox = styled(Box)(() => ({
    '.time-picker': {
      '.rc-time-picker-input': {
        height: '42px !important',
        fontSize: '14px',
        color: '#000',
        width: customWidth ? 'width-100' : '270px !important',
        borderColor: '#8A8A8A',
        '.rc-time-picker-panel-inner': {
          width: '200px'
        }
      }
    },
    '.width-100': {
      width: '100% !important'
    }
  }))

  return (
    <MainBox>
      {time
        ? (
        <TimePicker
          value={time ? moment(time) : moment(new Date().setHours(0, 0, 0, 0))}
          onChange={(val) => {
            setFieldValue(setFieldName, val)
          }}
          showSecond={false}
          allowEmpty
          clearIcon={<></>}
          className={`time-picker ${customWidth ? 'width-100' : ''}`}
          format='HH:mm'
          placeholder='HH:MM'
        />
          )
        : (
        <TimePicker
          onChange={(val) => {
            setFieldValue(setFieldName, val)
          }}
          defaultOpenValue={time ? moment(time) : moment(new Date().setHours(0, 0, 0, 0))}
          showSecond={false}
          allowEmpty
          clearIcon={<></>}
          className={`time-picker ${customWidth ? 'width-100' : ''}`}
          format='HH:mm'
          placeholder='HH:MM'
        />
          )}
    </MainBox>
  )
}

export default TimePic
