import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'

import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import CommonKanbanView from './CommonKanbanView'
import { useMachineWiseKanban } from '../../hooks/task'
import { useGetFullresourceMachine } from '../../hooks/resourceHooks'
import NoData from '../../leastcomponent/noAddedYet'

const MachineWiseKanbanView = () => {
  const [first, setfirst] = useState('')
  const selectDepartmentid = localStorage.getItem('selectDepartmentid')
  const { data: resourcesData } = useGetFullresourceMachine()
  const [machineId, setMachineId] = useState(resourcesData?.data?.[0]?.id)
  const { data: machineWiseData, isFetching: isMachineWiseData } = useMachineWiseKanban(machineId, (!!machineId) && (!!selectDepartmentid), selectDepartmentid)
  // useEffect(() => {
  //   setMachineId(resourcesData?.data?.[0]?.id)
  // }, [resourcesData?.data?.length])
  useEffect(() => {
    setMachineId(resourcesData?.data?.id)
  }, [resourcesData?.data?.length])
  const kanbanData = machineWiseData?.data?.results?.[0]

  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={'Machine Kanban View '}
        backToLink={null}
        setfirst={setfirst}
        first={first}
        isDepartment={true}
        selectedDepartment={selectDepartmentid}
      />
      <>
      {
        !selectDepartmentid
          ? <Box paddingTop={'350px'}><NoData label='Please select department'/></Box>
          : <>
 {!isMachineWiseData
   ? <Box padding={'10px 0px 10px 50px'}>
      <CommonKanbanView
      machineId={machineId}
      isSelect={true}
  machineName={machineWiseData?.data?.results?.[0]?.name ? machineWiseData?.data?.results?.[0]?.name : 'Select machine'}
  handleChange={(val) => setMachineId(val)}
  options={resourcesData?.data?.map((machine) => {
    return {
      value: machine?.id,
      label: machine?.name
    }
  })}

  taskData={[
    {
      title: 'Upcoming',
      backgroundColor: '#E5CEFF',

      task: kanbanData?.upcoming?.map((data) => {
        return {
          taskName: data?.taskNickName,
          taskNumber: data?.taskNumber,
          taskType: data?.taskType?.name,
          jobCardNumber: data?.jobCardId?.jobCardNumber,
          code: kanbanData?.code,
          text: 'text'
        }
      })
    },
    {
      title: 'Queue',
      backgroundColor: '#FFE282',

      task: kanbanData?.queue?.map((data) => {
        return {
          taskName: data?.taskNickName,
          taskNumber: data?.taskNumber,
          taskType: data?.taskType?.name,
          jobCardNumber: data?.jobCardId?.jobCardNumber,
          code: kanbanData?.code,
          text: 'text'
        }
      })
    },
    {
      title: 'Overdue (from yesterday)',
      backgroundColor: '#FCB488',

      task: kanbanData?.overdue?.map((data) => {
        return {
          taskName: data?.taskNickName,
          taskNumber: data?.taskNumber,
          taskType: data?.taskType?.name,
          jobCardNumber: data?.jobCardId?.jobCardNumber,
          code: kanbanData?.code,
          text: 'text'
        }
      })
    },
    {
      title: 'Completed',
      backgroundColor: '#A7E1B7',

      task: kanbanData?.completed?.map((data) => {
        return {
          taskName: data?.taskNickName,
          taskNumber: data?.taskNumber,
          taskType: data?.taskType?.name,
          jobCardNumber: data?.jobCardId?.jobCardNumber,
          code: kanbanData?.code,
          text: 'text'
        }
      })
    }
  ]}
/>
      </Box>
   : <Box className='machine-kanban-progress'>
                <CircularProgress />
              </Box>}
        </>
      }

      </>
    </Box>
  )
}

export default MachineWiseKanbanView
