import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { alltask } from '../querykeys/alltask'
import { alltaskApi } from '../api/alltask'
import { useNavigate } from 'react-router-dom'

export const useGetallTaskList = (isEnabled = true, page, departmentId, partName) => {
  return useQuery([alltask.ALLTASKPENDING, page, departmentId, partName], alltaskApi.GetAllTaskPending, {
    enabled: isEnabled,
    select: (res) => res.data,
    refetchOnWindowFocus: false

  })
}
export const useGetallTaskListOngoing = (isEnabled = true, page, departmentId, partName) => {
  return useQuery([alltask.ALLTASKPONGOING, page, departmentId, partName], alltaskApi.GetAllTaskOngoing, {
    enabled: isEnabled,
    select: (res) => res.data,
    refetchOnWindowFocus: false

  })
}
export const useDeleteTask = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(alltaskApi.deleteTask, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([alltask.ALLTASKPENDING])
      queryClient.invalidateQueries([alltask.ALLTASKPONGOING])
      queryClient.removeQueries([alltask.ALLTASKPENDING, id])
      queryClient.removeQueries([alltask.ALLTASKPONGOING, id])
      navigate('/dept-wise-task')
    }
  })
}
