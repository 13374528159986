import * as React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Pagination, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useLocation, useNavigate } from 'react-router-dom'
import Pendings from '../../assets/svg/Pending'
import Arrows from '../../assets/svg/Arrows'
import ArrowsActive from '../../assets/svg/ArrowsActive'
import PendingsActive from '../../assets/svg/PendingActive'
import { useQueryClient } from '@tanstack/react-query'
import { user as user2 } from '../../querykeys/usermanagement'
import PushToPendingModal from '../../modals/jobcardmodal/pushToPendingModal'
import NoAddedYet from '../../assets/svg/NoAddedYet'
// import Copy from '../../assets/svg/Copy'
// import CopyJobcardConfirmModel from '../../modals/jobcardmodal/copyJobcardConfirmModel'
import Kanban from '../../assets/svg/Kanban'
// import Kanban from '../../assets/svg/Kanban'

const PaginationWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
  alignItems: 'center',
  width: '100%',
  padding: '50px 0px'
})

function Row (props) {
  let { row } = props
  const { columns, user, isCompleted = false, isOngoing = false, value } = props
  console.log('value-kanban', value)
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [isCnfModalOpen, setIsCnfModalOpen] = React.useState(false)
  const [pushToPendingId, setPushToPendingId] = React.useState('')
  const [jcNum, setJcNum] = React.useState('')
  // const [copyId, setCopyId] = React.useState('')

  const queryClient = useQueryClient()
  const userData = queryClient.getQueryData([user2.LOGGED_IN_USER])
  const selectDepartmentid = localStorage.getItem('selectDepartmentid')
  const userRoleAccess = userData?.data?.data?.user
  const AdminUser = userData?.data?.data?.user?.userPermissions?.find((data) => data?.userModule === 'all' && data)
  const isScheduleTasksPermission = userRoleAccess?.userPermissions?.find((data) => data?.userModule === 'Schedule Job Cards')
  const isAddTasksPermission = userRoleAccess?.userPermissions?.find((data) => data?.userModule === 'Add tasks to Job Cards')

  const handleClick = (rowId) => {
    navigate(`/job-card-details-view/${rowId}?department`, { state: { sidebarTab: location.pathname.includes('job-card-department') ? 3 : 2 } })
  }
  const newItem = row?.history?.map((itm) => {
    const finalBOMProcess = itm?.bomprocesses?.find((item) => item.department.id === selectDepartmentid)
    return { ...itm, bomprocesses: finalBOMProcess }
  })
  row = { ...row, history: newItem }
  return (
    <React.Fragment>
      <TableRow
        sx={{
          borderTop: '1px solid #DEDEDE',
          '&:hover': {
            backgroundColor: '#F6F6F6',
            m: 1
          }
        }}
      >
        <TableCell>{row?.no}</TableCell>
        <TableCell component="th"
          scope="row"
          sx={{ color: '#0160B9', fontWeight: 600, cursor: 'pointer' }}
          onClick={() => handleClick(row?.id)}>
          {row.number}
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              setOpen(!open)
            }}
              // sx={{
    //   color: '#1976d2',
    //   '&:hover': {
    //     color: '#1976d2'
    //   }
    // }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>{' '}
        </TableCell>

        <TableCell
          // component="th"
          // scope="row"
          // sx={{ color: '#0160B9', fontWeight: 600, cursor: 'pointer' }}
          // onClick={() => handleClick(row?.id)}
        >
          {row.name}
        </TableCell>
        <TableCell align="left">{row.productName ? row.productName : '-'}</TableCell>
        <TableCell align="left">{row.quantity ? row.quantity : '-'}</TableCell>
        {value !== '4' && <TableCell align="left">{row.customerName ? row.customerName : '-'}</TableCell>}
        <TableCell align="left">{row.submitted ? row.submitted : '-'}</TableCell>
        {value !== '1' && value !== '4' && value !== '3' && <TableCell align="left">{row.priority ? row.priority : '-'}</TableCell>}
        {value !== '4' && value !== '3' && <TableCell align="left">{row.approveDate ? row.approveDate : '-'}</TableCell>}
       {value === '4' && <> <TableCell align="left">{row.productCode ? row.productCode : '-'}</TableCell>
          <TableCell align="left">{row.completionDate ? row.completionDate : '-'}</TableCell>
        </>
        }
        {isCompleted ? <TableCell align="left">{row.overallCompletion ? row.overallCompletion : '-'}</TableCell> : []}
        {/* {isCompleted ? <TableCell align="left">{row.actions ? row.actions : '-'}</TableCell> : []} */}
        {/* <TableCell align="left">{row.description ? row.description : '1'}</TableCell> */}
        {/* TODO:: kanban view */}
        <TableCell align="left">
          {isCompleted
            ? <Box className="table-btn-main">
              <span
                className="process-delete"
                onClick={() => navigate(`/schedule/${row.id}?department`, { state: { sidebarTab: location.pathname.includes('job-card-department') ? 3 : 2 } })}
              >
                <Kanban />
              </span>
            </Box>
          // TODO: IF need to duplicate completed copy
          // <Box className="table-btn-main">
          //     <span
          //       className="process-delete"
          //       onClick={() => {
          //         setIsCnfModalOpen(true)
          //         setCopyId(row.id)
          //         // addjobcardClone(params.row.id)
          //       } }
          //     >
          //       <Copy />
          //     </span>
          // </Box>

            : <></>}
        </TableCell>
      </TableRow>
      {open && (
        <TableRow sx={{ padding: '10px' }}>
          <TableCell
            style={{
              paddingBottom: '0 !important',
              paddingTop: '0 !important'
            }}
            colSpan={9}
            sx={{
              paddingLeft: '1000px',
              marginLeft: '50px',
              '& > .MuiTableCell-root': {
                paddingLeft: '200px'
              }
            }}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box className='parent-nested'>
              <Table aria-label="collapsible table">
                <TableHead
                  sx={{
                    color: '#020202',
                    fontSize: '14px',
                    fontWeight: 600,
                    marginLeft: '10rem',
                    '& > .MuiTableCell-root': {
                      paddingLeft: '200px'
                    }
                  }}>
                  <TableRow sx={{
                    height: '55px !important',
                    backgroundColor: '#FFFFFF',
                    paddingLeft: '1000px',
                    marginLeft: '10px',
                    '& > .MuiTableCell-root': {
                      paddingLeft: '20px'
                    }
                  }}
                    >
                    {columns?.map((innerColumn, index) => {
                      return (
                        <TableCell key={index} sx={{
                          color: '#020202',
                          fontSize: '14px',
                          fontWeight: 600,
                          paddingLeft: '1000px',
                          marginLeft: '50rem',
                          '& > .MuiTableCell-root': {
                            paddingLeft: '200px'
                          }
                        }}>
                          {' '}
                          {innerColumn?.headerName}{' '}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                {row?.history
                  ? (
                      row?.history?.map((historyRow, ind) => (
                      <TableRow key={ind} sx={{ }}>
                          <TableCell>{ind + 1}</TableCell>
                          {/* TODO : future use */}
                          {/* <TableCell sx={{ color: isOngoing && '#0160B9' }}
                            style={{ cursor: isOngoing ? 'pointer' : '' }}
                            onClick={() => {
                              if (isOngoing) {
                                if (isAddTasksPermission?.edit || AdminUser?.userModule === 'all') {
                                  navigate(`/job-details/${historyRow.jobCardId}/${historyRow.id}`, {
                                    state: { partId: historyRow.id, sidebarTab: location.pathname.includes('job-card-department') ? 3 : 2 }
                                  })
                                }
                              }
                            }}
                          > */}
                          <TableCell align="left">
                            {historyRow.partName ? historyRow.partName : '-'}</TableCell>
                          <TableCell align="left">
                            {historyRow.itemCode ? historyRow.itemCode : '-'}
                          </TableCell>
                        <TableCell align="left">
                            {historyRow.drawingNumber ? historyRow.drawingNumber : '-'}
                        </TableCell>

                        <TableCell align="left">{historyRow?.qty ? historyRow?.qty : '-'}</TableCell>
                        <TableCell align="left">
                            {historyRow.departmentSequence ? historyRow.departmentSequence : '-'}
                        </TableCell>
                        <TableCell align="left">
                            {historyRow.prevDeptName ? historyRow.prevDeptName : '-' }
                        </TableCell>
                        <TableCell align="left">
                            {historyRow.nextDeptName ? historyRow.nextDeptName : '-'}
                        </TableCell>
                        {
                         historyRow?.isShow === true &&
                         <TableCell align="left">
                         {!isCompleted && (
                           <div className="nested-actions">
                             {isOngoing
                               ? (
                               <>
                                 <div
                                   style={{ cursor: 'pointer' }}
                                   onClick={() => {
                                     if (isAddTasksPermission?.edit || AdminUser?.userModule === 'all') {
                                       navigate(`/job-details/${historyRow.jobCardId}/${historyRow.id}`, {
                                         state: { partId: historyRow.id, sidebarTab: location.pathname.includes('job-card-department') ? 3 : 2 }
                                       })
                                     }
                                   }}
                                 >
                                  Update Tasks
                                 </div>
                               </>
                                 )
                               : (
                               <>
                                 {user === 'productionTeamHead'
                                   ? (
                                   <div
                                     style={{ cursor: AdminUser?.userModule === 'all' || isScheduleTasksPermission?.edit ? 'pointer' : 'default' }}
                                     className={(AdminUser?.userModule === 'all' || isScheduleTasksPermission?.edit) ? '' : 'disabled-only-div'}
                                     onClick={() => {
                                       if (AdminUser?.userModule === 'all' || isScheduleTasksPermission?.edit) {
                                         navigate(`/job-dep-wise/${historyRow.jobCardId}/${historyRow.id}`, { state: { partId: historyRow.id } })
                                       }
                                     }}
                                   >
                                     Schedule Tasks
                                   </div>
                                     )
                                   : (
                                   <>
                                     <div
                                       style={{ cursor: isAddTasksPermission?.edit || AdminUser?.userModule === 'all' ? 'pointer' : 'default', display: 'flex', alignItems: 'center' }}
                                       className={isAddTasksPermission?.edit || AdminUser?.userModule === 'all' ? '' : 'disabled-only-div'}
                                       onClick={() => {
                                         if (isAddTasksPermission?.edit || AdminUser?.userModule === 'all') {
                                           navigate(`/add-task-production-team-member/${historyRow.jobCardId}/${historyRow.id}`, {
                                             state: { partId: historyRow.id }
                                           })
                                         }
                                       }}
                                     >
                                       Add Tasks
                                     </div>
                                     <div
                                       className={(historyRow?.taskLength > 0 && (AdminUser?.userModule === 'all' || isAddTasksPermission?.edit))
                                         ? 'nested-actions-push-cursor-pointer'
                                         : 'nested-actions-push'}
                                       onClick={() => {
                                         if ((historyRow?.taskLength > 0 && AdminUser?.userModule === 'all') || (historyRow?.taskLength > 0 && isAddTasksPermission?.edit)) {
                                           setIsCnfModalOpen(true)
                                           setPushToPendingId(historyRow?.bomprocesses.id)
                                           setJcNum(row?.number)
                                         }
                                       }}
                                     >
                                       {((historyRow?.taskLength > 0 && AdminUser?.userModule === 'all') || (historyRow?.taskLength > 0 && isAddTasksPermission?.edit))
                                         ? (
                                         <>
                                           <ArrowsActive />
                                           <PendingsActive />
                                         </>
                                           )
                                         : (
                                         <>
                                           <Arrows style={{ marginLeft: '10px', marginRight: '0px' }} />
                                           <Pendings />
                                         </>
                                           )}
                                     </div>
                                   </>
                                     )}
                               </>
                                 )}
                           </div>
                         )}
                       </TableCell>

                      }
                      </TableRow>
                      ))
                    )
                  : (
                    <div>
                    </div>
                    )}
              </Table>

                {isCnfModalOpen && <PushToPendingModal id={pushToPendingId} jcNum={jcNum} open={isCnfModalOpen} handleClose={() => setIsCnfModalOpen(false)} />}
               {/* TODO: IF need to duplicate completed copy */}
                {/* {isCnfModalOpen &&
            <CopyJobcardConfirmModel
              //  jcNum={jcNum}
              open={isCnfModalOpen}
          id={copyId}
          value={value}
              handleClose={() => setIsCnfModalOpen(false)}/>} */}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

const NestedTable = ({
  rows,
  columns,
  mainCols,
  setPage,
  tableName,
  isPagination = true,
  paginationData,
  page,
  totalPages,
  totalResults,
  user,
  isCompleted = false,
  isOngoing = false,
  value
}) => {
  const rowsPerPage = 10
  const start = (page - 1) * rowsPerPage + 1
  const end = Math.min(page * rowsPerPage, totalResults)
  return (
    <React.Fragment>
      <Box className="details-label-text">
        {tableName}
      </Box>
      <TableContainer
        component={Paper}
      >
       <Table aria-label="collapsible table">
          <TableHead sx={{ height: '55px', background: '#F6F6F6', borderRadius: '8px 8px 0px 0px' }}>
            <TableRow>
              {mainCols?.map((Column, index) => {
                return <TableCell key={index}> {Column?.field} </TableCell>
              })}
            </TableRow>
          </TableHead>

             <TableBody>
           { rows.map((row, index) => {
             return <Row key={index} row={row} columns={columns} user={user} isOngoing={isOngoing} isCompleted={isCompleted} value={value} onCellClick={(e) => {
             }} />
           })

            }
          </TableBody>

            </Table>

      </TableContainer>

      {rows?.length <= 0 &&
             <Box display={'flex'} color= {'#000000DE'} fontSize= {'14px'} justifyContent={'center'} alignItems={'center'} maxHeight={'5000px'} padding= {'20px'}>
             <Box textAlign={'center'}>
               <NoAddedYet />
               <Typography>{'No Rows'}</Typography>
             </Box>
           </Box>
          }

      {isPagination && rows?.length > 0 &&
        (
          <PaginationWrapper>
            <Typography sx={{ color: '#8A8A8A', fontSize: '14px' }}>
            {/* Showing 1 to 10 of {totalResults} */}
            Showing {start} to {end} of {totalResults}
            </Typography>
            <Pagination
            page={page}
            color="primary"
            count={totalPages || paginationData?.totalPages}
            variant="outlined"
            onChange={(e, page) => {
              setPage(page)
            }}
            />
          </PaginationWrapper>
        )}

    </React.Fragment>
  )
}

export default NestedTable
