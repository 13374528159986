import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import NoData from '../../leastcomponent/noAddedYet'
// import EditIconNew from '../../assets/svg/EditIconNew'
// import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import { useJobSlipsSingleById } from '../../hooks/jobslipshooks'
// import { useParams } from 'react-router-dom'
import TableComponent from '../../leastcomponent/customtable/customtable'
import JobCardTableModal from '../../modals/jobcardmodal/jobcardtablemodal'
// import NewShareIcon from '../../assets/svg/NewShareicon'
import CustomSelect from '../../leastcomponent/customselect/customselect'
// import { useGetFullresourceMachine } from '../../hooks/resourceHooks'
// TODO: over /pending task to use that
// import JobSlipsRadioWithSelectModal from '../../modals/jobcardmodal/jobslipsradiowithselect'
// import ConfirmJobSlip from '../../modals/jobcardmodal/confirmjobslip'
// import ConfirmOverdue from '../../modals/jobcardmodal/confirmoverdue'
// // import { useLocation } from 'react-router-dom'
// import DeleteWarningModal from '../../modals/deletewarningmodal'
import AlertIcon from '../../assets/svg/AlertIcon'
import JobSlipsDetailsModal from '../../modals/jobcardmodal/jobSlipsDetailsModal'
import SearchJobslip from '../../generalcomponents/searchcomponentJobSlip'

const JobSlipsList = () => {
  const theme = useTheme()
  // TODO:- use in future
  // const { id } = useParams()
  // const location = useLocation()
  // const jobSlipsId = location?.state?.jobSlipsId
  // const selectMachineid = localStorage.getItem('selectMachineid')
  // const { data: getjobSlipsSingle } = useJobSlipsSingle(id, !!id, selectMachineid)
  // const [currJobCardId, setCurrJobCardId] = useState('')
  // const [jobId, setJobId] = useState('')
  const [first, setfirst] = useState('')
  const selectDepartmentid = localStorage.getItem('selectDepartmentid')
  const matches = useMediaQuery(theme.breakpoints.up('lg'))
  const [jobCardTableModal, setJobCardTableModal] = useState(false)
  const [selectMachine, setSelectMachine] = useState('')
  const [selectPriority, setSelectePriority] = useState('')
  const { data: GetJobSlipsSingleById } = useJobSlipsSingleById(selectDepartmentid, selectMachine, selectPriority)
  // const { data: GetJobSlipsSingleById } = useJobSlipsSingleById(jobSlipsId, !!jobSlipsId, selectedDepartment, selectPriority)
  const listData = GetJobSlipsSingleById?.data?.results
  console.log('list--', listData)
  // const handleSelectChange = (selectedOption) => {
  //   setSelectMachine(selectedOption?.target?.value)
  // }
  const handleListChange = (selectedOption) => {
    setSelectePriority(selectedOption?.target?.value)
  }
  // const [reso, setReso] = useState('')
  // TODO: over /pending task to use that
  // const [jobSlipsRadioWithSelectModal, setJobSlipsRadioWithSelectModal] =
  //   useState(false)
  // const [confirmJobSlip, setConfirmJobSlip] = React.useState(false)
  // const [confirmOverdue, setConfirmOverdue] = React.useState(false)
  // const [jobIdValues, setJobIdValues] = useState({})
  // const [deleteRecord, setDeleteRecord] = useState(false)
  // const handleDeleteOpen = () => setDeleteRecord(true)
  // const handleDeleteClose = () => setDeleteRecord(false)
  // console.log(setReso)
  const [jobName, setJobName] = useState({ name: '', code: '' })
  const [tasks, setTasks] = useState({})
  const [jobCardModel, setJobCardModel] = useState('')
  const handleDetailsClose = () => {
    setJobCardModel(false)
  }
  // machine full list api
  // const { data: resourcesData } = useGetFullresourceMachine(reso, !!reso)

  // const options = resourcesData?.data?.map((item) => {
  //   return {
  //     value: item?.id,
  //     label: item?.name
  //   }
  // })
  const priorities = [
    { label: 'High', value: 'High' },
    { label: 'Regular', value: 'Regular' },
    { label: 'Immediate', value: 'Immediate' }
  ]

  return (
        <React.Fragment>
            <Box className="production-team-head-overflow-hidden">
        <Box>
        <BreadcrumbNavigation
            pageTitle={`Job Slip: ${listData?.jobSlipNumber ? listData?.jobSlipNumber : ''}`}
            setfirst={setfirst}
            first={first}
            isDepartment={true}
            selectedDepartment={selectDepartmentid}
              // isPrinter={selectDepartmentid}
/>
        </Box>
        <Box sx={{ padding: '16px 50px', display: 'flex', gap: '85px' }}>
        <Box sx={{ width: '228px' }}>
        {/* <CustomSelect
        name="Machine"
        isRequired={false}
        islabel={false}
        placeholder="Select Machine"
        className="default-select-new"
        options={options}
        value={selectMachine}
        handleSelectChange={handleSelectChange}
      /> */}
            <SearchJobslip setSelectMachine={setSelectMachine} selectMachine={selectMachine} />
          </Box>
          <Box>
            <CustomSelect
        name="priority"
        isRequired={false}
        islabel={false}
        placeholder="Select Priority"
              className="default-select-new"
              options={priorities}
              value={selectPriority}
              handleSelectChange={handleListChange}
      />
            </Box>
          </Box>

        {
          !selectDepartmentid
            ? <Box paddingTop={'350px'}><NoData label='Please select Department' /></Box>

            : <Box
              sx={{
                typography: 'body1'
              }}
              className="job-slips-overflow-auto"
            >
              {listData?.map((data, ind) => {
                const rows = data?.tasks?.map((data, ind) => {
                  return {
                    no: ind + 1,
                    id: data?.id,
                    name: data?.taskNickName ? data.taskNickName : '-',
                    taskNumber: data?.taskNumber ? data.taskNumber : '-',
                    taskType: data?.taskType?.name ? data?.taskType?.name : '-',
                    partName: data?.bomPartId?.partName
                      ? data?.bomPartId?.partName
                      : '-',
                    qty: data?.jobCardId?.quantity ? data?.jobCardId.quantity : '-',
                    description: data?.bomPartId?.description ? data?.bomPartId?.description : '-',
                    jobCardNo: data?.jobCardId?.jobCardNumber
                      ? data?.jobCardId?.jobCardNumber
                      : '-',
                    allocatedTime: data?.allocatedTime,
                    jobName: data?.name,
                    jobCode: data?.code,
                    priority: data?.priority,
                    drawing: data?.bomPartId?.drawingNumber
                      ? data?.bomPartId?.drawingNumber
                      : '-'
                  }
                })
                const col = [
                  { field: 'no', headerName: '#', flex: 0.3 },
                  {
                    field: 'name',
                    headerName: 'Task Name ',
                    flex: 1
                    // cellClassName: 'blue-font'
                    //  {/* TODO : future use to task complete */}
                    // renderCell: (params) => {
                    //   return (
                    //     <Box className="table-icon-new">
                    //       <span
                    //         className="process-delete"
                    //         onClick={() => {
                    //           setJobName({ ...jobName, name: params?.row?.jobName, code: params?.row?.jobCode })
                    //           // setTasks(finalDataOne?.find(item => item.partId === partId))
                    //           // if (partId) {
                    //           setTasks({ history: [{ taskId: params?.id, taskNickName: params?.row?.name }] })
                    //           // setProcessData({ })
                    //           setJobCardModel(true)
                    //           // }
                    //         }}
                    //       >
                    //         {params?.row?.name ? params?.row?.name : '-'}
                    //       </span>
                    //     </Box>
                    //   )
                    // }
                  },
                  {
                    field: 'priority',
                    headerName: 'Priority',
                    flex: 1,
                    renderCell: (params) => {
                      return (
                        <>
                          <Box>
                            {params?.row?.priority === 'High' && (
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <AlertIcon color="#F4C614" />
                                {params.row.priority}
                              </Box>
                            )}
                            {params?.row?.priority === 'Regular' && (
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <AlertIcon color="#6DB918" />
                                {params.row.priority}
                              </Box>
                            )}
                            {params?.row?.priority === 'Immediate' && (
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <AlertIcon color="#EB5757" /> {params.row.priority}
                              </Box>
                            )}

                          </Box>
                        </>
                      )
                    }
                  },
                  { field: 'taskNumber', headerName: 'Task Number', flex: 1 },
                  { field: 'taskType', headerName: 'Task type', flex: 1 },
                  { field: 'partName', headerName: 'Part Name ', flex: 1 },
                  { field: 'qty', headerName: 'Qty ', flex: matches ? 1 : 0.7 },
                  { field: 'description', headerName: 'Part Description ', flex: 1 },
                  { field: 'jobCardNo', headerName: 'Job Card Number ', flex: 1 },
                  {
                    field: 'allocatedTime',
                    headerName: 'Allocated time (mins)',
                    flex: matches ? 1 : 0.7
                  },
                  {
                    field: 'drawing',
                    headerName: 'Drawing',
                    flex: matches ? 1 : 0.7
                  },
                  {
                    field: 'actions',
                    headerName: 'Actions',
                    sortable: false,
                    disableClickEventBubbling: true,
                    flex: 1,
                    renderCell: (params) => {
                      return (
                        <Box className="resource-table-btn-main">
                          {/* // TODO: over /pending task to use that */}
                          {/* <span
                            className="process-delete"
                            onClick={() => {
                              setJobId(params?.id)
                              setJobSlipsRadioWithSelectModal(true)
                            }}
                          >
                            <EditIconNew />
                          </span> */}
                          {/* <span
                            onClick={handleDeleteOpen}
                            className="process-delete"
                          >
                            <DeleteIconNew />
                          </span> */}
                            <span
                            className="process-delete"
                            onClick={() => {
                              setJobName({ ...jobName, name: params?.row?.jobName, code: params?.row?.jobCode })
                              // setTasks(finalDataOne?.find(item => item.partId === partId))
                              // if (partId) {
                              setTasks({ history: [{ taskId: params?.id, taskNickName: params?.row?.name }] })
                              // setProcessData({ })
                              setJobCardModel(true)
                              // }
                            }}
                          >
                            Update
                          </span>
                        </Box>
                      )
                    }
                  }
                ]
                return (
                  //    <React.Fragment>
                  <>
                    <Box className="job-slips-card-box">
                    { !selectMachine
                      ? ' '
                      : data?.tasks?.[ind]?.resource?.name || 'Default Name'}

                      {/* <Box
                                     onClick={() => {
                                       setJobCardTableModal(true)
                                       setCurrJobCardId(data?.id)
                                     }}
                                     sx={{ cursor: 'pointer' }}
                                   >
                                     <NewShareIcon />
                                   </Box> */}
                    </Box>
                    <Grid container className="job-slips-main-margin">
                      <Grid
                        item
                        lg={3}
                        md={3.5}
                        className="job-slips-task-assigned"
                      >
                        <Box className="job-slips-add-task-header">
                          No. of Tasks assigned:
                        </Box>
                        <Box className="job-slips-with-semibold">
                          {' '}
                          {data?.numberOfTasks}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3.5}
                        className="job-slips-task-assigned"
                      >
                        <Box className="job-slips-add-task-header">
                          Total Qty assigned:
                        </Box>
                        <Box className="job-slips-with-semibold">
                          {data?.totalQty}
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={5} className="job-slips-task-assigned">
                        <Box className="job-slips-add-task-header">
                          Total time assigned:
                        </Box>
                        <Box className="job-slips-with-semibold">{data?.totalTimeAssigned} minutes</Box>
                      </Grid>
                    </Grid>
                    <Box>
                    <TableComponent rows={rows} columns={col} hidePagination={true} />
                    </Box>
                  </>
                  //    </React.Fragment>

                )
              })}
              {jobCardTableModal && (
                <JobCardTableModal
                  open={jobCardTableModal}
                  basicDetail={GetJobSlipsSingleById?.data?.results}
                  handleClose={() => setJobCardTableModal(false)}
                // mainData={GetJobSlipsSingleById?.data?.taskList?.filter((data) => {
                //   return data?.id === currJobCardId
                // })}
                // jobId={jobId}
                />
              )}
              {/* TODO: over /pending task to use that  */}
              {/* {jobSlipsRadioWithSelectModal && (
                <JobSlipsRadioWithSelectModal
                  open={jobSlipsRadioWithSelectModal}
                  handleClose={() => setJobSlipsRadioWithSelectModal(false)}
                  slipOpen={() => setConfirmJobSlip(true)}
                  overdueOpen={() => setConfirmOverdue(true)}
                  setJobIdValues={setJobIdValues}
                />
              )}
              {confirmJobSlip && (
                <ConfirmJobSlip
                  open={confirmJobSlip}
                  handleClose={() => setConfirmJobSlip(false)}
                  openJobSlip={() => setJobSlipsRadioWithSelectModal(true)}
                  jobId={jobId}
                  jobIdValues={jobIdValues}
                />
              )}
              {ConfirmOverdue && (
                <ConfirmOverdue
                  open={confirmOverdue}
                  handleClose={() => setConfirmOverdue(false)}
                  openJobSlip={() => setJobSlipsRadioWithSelectModal(true)}
                  jobId={jobId}
                />
              )} {deleteRecord && (
                <DeleteWarningModal
                  open={deleteRecord}
                  handleOpen={handleDeleteOpen}
                  handleClose={handleDeleteClose}
                />
              )} */}
              {jobCardModel && (
          <JobSlipsDetailsModal
            tasks={tasks}
            jobName={jobName}
            processData={GetJobSlipsSingleById}
            open={jobCardModel}
            DetailsHandleClose={handleDetailsClose}
            setOpen={() => setJobCardModel(false)}
          />
              )}

            </Box>
        }
                    </Box>
             </React.Fragment>

  )
}
export default JobSlipsList
