import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/Close'
import { useParams } from 'react-router-dom'
import * as _ from 'lodash'
import { useAddRole, useUpdateRole } from '../hooks/userRoleHook'
import './../assets/css/modal.css'
import { Grid } from '@mui/material'
import CustomButton from '../leastcomponent/custombutton/custombutton'
import ErrorPopup from './errorpopup'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24
}

const CustomTypography = styled(Typography)(() => ({
  color: 'gray',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px'
}))

const HeadBoxWrapper = styled(Box)(() => ({}))

const AddRoles = (props) => {
  const { isOpen, handleClose, values } = props
  const { id } = useParams()
  const { mutate: addRole, isError: isAddRoleError, error: addRoleError } = useAddRole()
  const {
    mutate: updateRole,
    isError: isUpdateRoleError,
    error: updateRoleError
  } = useUpdateRole()
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const handleErrorModalClose = () => setIsErrorModalOpen(false)

  React.useEffect(() => {
    if (isAddRoleError || isUpdateRoleError) {
      isAddRoleError
        ? setErrorMessage(addRoleError?.data?.message)
        : setErrorMessage(updateRoleError?.data?.message)
      setIsErrorModalOpen(true)
    }
  }, [isAddRoleError, isUpdateRoleError])

  const moduleMapping = {
    'Create Job Cards': 'Create Job Cards',
    'Approve Job Cards': 'Approve Job Cards',
    'Add tasks to Job Cards': 'Add task to job Card',
    'Schedule Job Cards': 'Schedule job Card',
    Schedule: 'Schedule',
    Resources: 'Resources',
    Dashboard: 'Dashboard',
    'edit job card after approval': 'Edit JC after approval',
    'All Tasks': 'All Tasks'
  }
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby=''
      aria-describedby='modal-modal-description'
    >
      <HeadBoxWrapper sx={style}>
        <Box>
          <Box className='title-head-box'>
            <Typography className='top-up-name'>Please confirm the user details</Typography>
            <CloseIcon onClick={handleClose} className='close-icon' />
          </Box>
          <Box className='modal-body'>
            <Box className='section-one'>
              <Typography ml={'25px'}>Basic Details</Typography>
              <CustomTypography>Role</CustomTypography>
              <span className='span-color pl-sub-text'>{_.get(values, 'name', '-')}</span>
            </Box>
            <Box className='section-one'>
              <Typography ml={'25px'}>Module Access</Typography>
              <Grid container spacing={0}>
                {values?.rights?.map((right, ind) => {
                  const accessedModules = {
                    Create: right?.add,
                    Read: right?.view,
                    Update: right?.edit,
                    Delete: right?.delete
                  }
                  const displayModules = Object.entries(accessedModules)
                    .filter((data) => data[1])
                    .map((data) => data[0])
                  return (
                    <Grid item xs={6} key={ind}>
                      <CustomTypography>{moduleMapping[right?.userModule]}: </CustomTypography>
                      <Typography className='span-color pl-sub-text'>
                        {displayModules?.length === 0 ? '-' : displayModules?.join(', ')}
                      </Typography>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className="button-child ">
            <CustomButton title="Edit Details" type="submit"
              onClick={() => {
                handleClose()
              }}
            >
            </CustomButton>
            <CustomButton title="Save Role" type="submit"
              className='ml-20'
              onClick={() => {
                id ? updateRole({ ...values, id }) : addRole(values)
              }} />
          </Box>
        </Box>
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
      </HeadBoxWrapper>
    </Modal>
  )
}
export default AddRoles
