import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import TableComponent from '../../leastcomponent/customtable/customtable'
import { useParams } from 'react-router-dom'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import { useGetSetterSingle } from '../../hooks/resourceHooks'
import ErrorPopup from '../../modals/errorpopup'
import moment from 'moment'
// import EditIconNew from '../../assets/svg/EditIconNew'
// import DeleteIconNew from '../../assets/svg/DeleteIconNew'
const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px'
}))
const SetterDetails = () => {
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const { id } = useParams()
  const { data: getSetterSingle, error, isFetching: isGetsingleSetter } = useGetSetterSingle(id)
  const singleSetter = getSetterSingle?.data

  const rows = singleSetter?.task?.map((data, ind) => {
    return {
      id: data?.id,
      no: ind + 1,
      taskNumber: data?.taskNumber ? data?.taskNumber : '-',
      jobCardNumber: data?.jobCardId?.jobCardNumber ? data?.jobCardId?.jobCardNumber : '-',
      taskCompletionDate: moment(data?.taskCompletionDate).format('DD/MM/YY') ? moment(data?.taskCompletionDate).format('DD/MM/YY') : '-'
    }
  })
  const columns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'taskNumber', headerName: 'Task No.', flex: 1 },
    { field: 'jobCardNumber', headerName: 'Job Card No.', flex: 1 },
    { field: 'taskCompletionDate', headerName: 'Completed Date', flex: 1 }
    // TODO:: action field temporary comment
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   sortable: false,
    //   disableClickEventBubbling: true,
    //   flex: 1,
    //   renderCell: () => {
    //     return (
    //       <Box className="resource-table-btn-main">
    //         <span className="process-delete"
    //         // onClick={() => setOpen(true)}
    //         >
    //           <EditIconNew />
    //         </span>
    //         <span
    //           // onClick={() => {
    //           //   handleDeleteOpen()
    //           // }}
    //           className="process-delete"
    //         >
    //           <DeleteIconNew />
    //         </span>
    //       </Box>
    //     )
    //   }
    // }
  ]
  const breadCrumbData = [
    {
      title: 'Setters',
      link: '/resource'
    },
    {
      title: singleSetter?.firstName,
      link: ''
    }
  ]
  useEffect(() => {
    if (error) {
      setIsErrorModalOpen(true)
      setErrorMessage(error?.data?.message)
    }
  }, [error])
  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={singleSetter?.firstName ? singleSetter?.firstName : '-'}
        backToLink={'/resource'}
        activeTab="2"
      />
     {!isGetsingleSetter
       ? <Box className="common-parent-class-overflow-machine">
        <Grid item xs={12} md={12} container display={'flex'} mt={'20px'}>
          <Grid item xs={12} md={12}>
            <Box className="details-box-border padding-bottom" mr={'10px'}>
              <Typography id="modal-modal-title" className="details-sub-title">
                Basic Details
              </Typography>
              <Grid container display={'flex'}>

                <Grid item xs={2} md={2}>
                  <TextFiled>Name </TextFiled>
                  <Typography className="span-color pl-sub-text">
                 { singleSetter?.firstName ? singleSetter?.firstName : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <TextFiled>ID </TextFiled>
                  <Typography className="span-color pl-sub-text">
                  { singleSetter?.uniqId ? singleSetter?.uniqId : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <TextFiled>Department</TextFiled>
                  <Typography className="span-color pl-sub-text">
                  { singleSetter?.department?.name ? singleSetter?.department?.name : '-'}
                  </Typography>
                </Grid>

              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Box className="">
          <Grid container spacing={2} className="form-wrapper" wrap="nowrap">
            <Grid item lg={10} md={10} >
            {/* {rows?.length > 0 &&
            <> */}
              <Box className="font-size-18" marginBottom={'20px'}>Department</Box>
              <Box className="department-table-width">
              <TableComponent rows={rows} columns={columns} />
              </Box>
              {/* </>} */}
            </Grid>
          </Grid>
        </Box>
      </Box>
       : <Box className='progress-parent-machine-main'>
                <CircularProgress />
              </Box>}

   {/* TODO::  temporary comment */}
      {/* <DeleteWarningModal
        open={deleteRecord}
        handleOpen={handleDeleteOpen}
        handleClose={handleDeleteClose}
      /> */}
         {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
         )}
    </Box>
  )
}

export default SetterDetails
