import { Box, CircularProgress, Tab, Tabs, Typography } from '@mui/material'
import React, { useState } from 'react'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import TableComponent from '../../leastcomponent/customtable/customtable'
import AddIcon from '@mui/icons-material/Add'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import './resource.css'
import EditIconNew from '../../assets/svg/EditIconNew'
import { useLocation, useNavigate } from 'react-router-dom'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import {
  useDeleteOperator,
  useDeleteresource,
  useDeleteSetter,
  useGetBreakdown,
  useGetOperator,
  useGetResource,
  useGetSetter
} from '../../hooks/resourceHooks'
import DeleteWarningModal from '../../modals/deletewarningmodal'
import ErrorPopup from '../../modals/errorpopup'
import { TabContext, TabPanel } from '@mui/lab'
import moment from 'moment'

const Resource = () => {
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  // const [errorMessage, setErrorMessage] = React.useState('')
  // const handleErrorModalOpen = () => setIsErrorModalOpen(true)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const navigate = useNavigate()
  const [deleteRecord, setDeleteRecord] = useState(false)
  const [deleteRecordSetter, setDeleteRecordSetter] = useState(false)
  const [deleteRecordOperator, setDeleteRecordOperator] = useState(false)
  // const [deleteRecordBreakdown, setDeleteRecordBreakdown] = useState(false)
  const [currentSetterId, setCurrentSetterId] = useState('')
  const [currentOperatorId, setCurrentOperatorId] = useState('')
  // const [currentBreakdownId, setCurrentBreakdownId] = useState('')
  const [currentId, setCurrentId] = useState('')
  const handleDeleteOperatorOpen = () => setDeleteRecordOperator(true)
  const handleDeleteOperatorClose = () => setDeleteRecordOperator(false)
  const handleDeleteSetterOpen = () => setDeleteRecordSetter(true)
  const handleDeleteSetterClose = () => setDeleteRecordSetter(false)
  // const handleDeleteBreakdownOpen = () => setDeleteRecordBreakdown(true)
  // const handleDeleteBreakdownClose = () => setDeleteRecordBreakdown(false)
  const handleDeleteOpen = () => setDeleteRecord(true)
  const handleDeleteClose = () => setDeleteRecord(false)
  const [page, setPage] = useState(1)
  const location = useLocation()
  const [value, setValue] = useState(location?.state?.activeTab || '1')
  const { data: resourceListInfo, isFetching: isResourceListInfo } =
    useGetResource(value === '1', page, value === '1')
  const { data: getSetter, isFetching: isSetterListInfo } = useGetSetter(
    value === '2',
    page
  )
  const { data: getOperator, isFetching: isOperatorListInfo } = useGetOperator(
    value === '3',
    page
  )
  const { data: getBreakdown, isFetching: isBrakdownListInfo } =
    useGetBreakdown(value === '4', page)

  const handleChange = (event, newValue) => {
    setPage(1)
    setValue(newValue)
  }
  const resourceList = resourceListInfo?.results
  const rows = resourceList?.map((data, ind) => {
    const startIndex = (page - 1) * 10
    return {
      id: data?.id,
      no: startIndex + ind + 1,
      name: data?.name ? data?.name : '-',
      code: data?.code ? data?.code : '-',
      uniqId: data?.uniqId ? data?.uniqId : '-',
      currentStatus: data?.currentStatus ? data?.currentStatus : '-',
      nextAvailableOn: data?.nextAvailableOn ? data?.nextAvailableOn : '-',
      updatedOn: data?.updatedOn ? data?.updatedOn : '-',
      // location: data?.location ? data?.location : '-',
      taskCount: data?.taskCount,
      type: data?.type ? data?.type : '-',
      tasktype: data?.tasktype ? data?.tasktype : '-',
      capacity: data?.capacity ? data?.capacity : '-',
      hrutilization: data?.hrutilization ? data?.hrutilization : '-',
      weekutilization: data?.weekutilization ? data?.weekutilization : '-',
      hrplan: data?.hrplan ? data?.hrplan : '-',
      weekplan: data?.weekplan ? data?.weekplan : '-'
    }
  })
  const { mutate: deleteresource } = useDeleteresource()
  const { mutate: deleteSetter } = useDeleteSetter()
  const { mutate: deleteOperator } = useDeleteOperator()
  // const { mutate: deleteBreakdown } = useDeleteBreakdown()
  const columns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'code', headerName: 'Machine code ', flex: 1 },
    { field: 'uniqId', headerName: 'ID', flex: 1 },
    { field: 'currentStatus', headerName: 'Current Status', flex: 1 },
    { field: 'nextAvailableOn', headerName: 'Next Available On', flex: 1 },
    { field: 'updatedOn', headerName: 'Updated On', flex: 1 },
    // { field: 'location', headerName: 'Location', flex: 1 },
    // { field: 'type', headerName: 'Type', flex: 1 },
    // { field: 'capacity', headerName: 'Capacity', flex: 1 },
    // { field: 'hrutilization', headerName: '24-hr utilization', flex: 0.7 },
    // { field: 'weekutilization', headerName: '1-week utilization', flex: 0.7 },
    // { field: 'hrplan', headerName: '24-hr plan', flex: 0.7 },
    // { field: 'weekplan', headerName: '1-week plan', flex: 0.7 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="resource-table-btn-main">
            <span
              className="process-delete"
              onClick={() => navigate(`/edit-resource/${params.row.id}`)}
            >
              <EditIconNew />
            </span>
            <span
              className={ params.row.taskCount !== 0 ? 'process-delete_disable' : 'process-delete'}
              onClick={() => {
                if (params.row.taskCount === 0) {
                  setCurrentId(params.row.id)
                  handleDeleteOpen()
                }
                // else {
                //   setErrorMessage(
                //     `You can not able to delete this resource, this resource is assigned to many ${params.row.taskCount} tasks`
                //   )
                //   handleErrorModalOpen()
                // }
              }}
            >
              {/* {console.log("params.row.taskCount===", params.row.taskCount)} */}
              {params.row.taskCount !== 0
                ? (
                <DeleteIconNew color={'#ccc'} />
                  )
                : (
                <DeleteIconNew />
                  )}
            </span>
          </Box>
        )
      }
    }
  ]
  const setterList = getSetter?.results
  const setterRows = setterList?.map((data, ind) => {
    const startIndex = (page - 1) * 10
    return {
      id: data?.id,
      no: startIndex + ind + 1,
      firstName: data?.firstName ? data?.firstName : '-',
      department: data?.department?.name ? data?.department?.name : '-',
      uniqId: data?.uniqId ? data?.uniqId : '-'
    }
  })
  const Settercolumns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'firstName', headerName: 'Name', flex: 1 },
    { field: 'uniqId', headerName: 'ID', flex: 1 },
    { field: 'department', headerName: 'Department', flex: 1 },
    // TODO:: action field temporary comment
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="resource-table-btn-main">
            <span
              className="process-delete"
              onClick={() => navigate(`/edit-setter/${params.row.id}`)}
            >
              <EditIconNew />
            </span>
            <span
              onClick={() => {
                //  if (params.row.taskCount === 0) {
                setCurrentSetterId(params.row.id)
                handleDeleteSetterOpen()
                //  } else {
                //    setErrorMessage(
                //     `You can not able to delete this resource, this resource is assigned to many ${params.row.taskCount} tasks`
                //    )
                //  handleErrorModalOpen()
                //  }
              }}
              className="process-delete"
            >
              <DeleteIconNew />
            </span>
          </Box>
        )
      }
    }
  ]
  const OperatorList = getOperator?.results
  const OperatorRows = OperatorList?.map((data, ind) => {
    const startIndex = (page - 1) * 10
    return {
      id: data?.id,
      no: startIndex + ind + 1,
      name: data?.name ? data?.name : '-',
      department: data?.department?.name ? data?.department?.name : '-',
      uniqId: data?.uniqId ? data?.uniqId : '-',
      tasksCompletedYesterday: data?.tasksAssignedYesterday
        ? data?.tasksAssignedYesterday
        : '-',
      tasksAssignedYesterday: data?.tasksAssignedYesterday
        ? data?.tasksAssignedYesterday
        : '-'
    }
  })
  const Operatorcolumns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'uniqId', headerName: 'ID', flex: 1 },
    { field: 'department', headerName: 'Department', flex: 1 },
    // TODO::  fields temporary comment
    // { field: 'tasksCompletedYesterday', headerName: 'Tasks completed yesterday', flex: 1 },
    // { field: 'tasksAssignedYesterday', headerName: 'Tasks assigned yesterday', flex: 1 },
    // TODO:: action field temporary comment
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="resource-table-btn-main">
            <span
              className="process-delete"
              onClick={() => navigate(`/edit-operator/${params.row.id}`)}
            >
              <EditIconNew />
            </span>
            <span
              onClick={() => {
                //  if (params.row.taskCount === 0) {
                setCurrentOperatorId(params.row.id)
                handleDeleteOperatorOpen()
                //  } else {
                //    setErrorMessage(
                //     `You can not able to delete this resource, this resource is assigned to many ${params.row.taskCount} tasks`
                //    )
                //  handleErrorModalOpen()
                //  }
              }}
              className="process-delete"
            >
              <DeleteIconNew />
            </span>
          </Box>
        )
      }
    }
  ]

  const BreakdownList = getBreakdown?.results
  const BreakdownRows = BreakdownList?.map((data, ind) => {
    const startIndex = (page - 1) * 10
    return {
      // id: data?.id,
      id: data?.id ? data?.id : '-',
      machineId: data?.resource?.id ? data?.resource?.id : '-',
      no: startIndex + ind + 1,
      machineName: data?.resource?.name ? data?.resource?.name : '-',
      machineCode: data?.resource?.code ? data?.resource?.code : '-',
      breakdownReason: data?.breakdownReason ? data?.breakdownReason : '-',
      breakDownOccurred: moment(data?.breakDownOccurred).format(
        'DD/MM/YY hh:mm a'
      )
        ? moment(data?.breakDownOccurred).format('DD/MM/YY hh:mm a')
        : '-',
      // breakdownTime: moment(data?.breakdownTime).format('DD/MM/YY hh:mm a') ? moment(data?.breakdownTime).format('DD/MM/YY hh:mm a') : '-'
      breakdownTime: '-'
    }
  })
  const Breakdowncolumns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'machineName', headerName: 'Machine Name', flex: 1.5 },
    { field: 'machineCode', headerName: 'Machine Code', flex: 1.5 },
    { field: 'breakdownTime', headerName: 'BreakDown Duration', flex: 1.7 },
    { field: 'breakDownOccurred', headerName: 'BreakDown Occurred', flex: 1.7 },
    { field: 'breakdownReason', headerName: 'BreakDown Reason', flex: 2.5 }
    // TODO :- if needed in future action
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   sortable: false,
    //   disableClickEventBubbling: true,
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <Box className="resource-table-btn-main">
    //         {/* <span className="process-delete"
    //         onClick={() => navigate(`/edit-operator/${params.row.id}`)}
    //         >
    //           <EditIconNew />
    //         </span> */}
    //         <span
    //            onClick={() => {
    //              //  if (params.row.taskCount === 0) {
    //              setCurrentBreakdownId(params.row.id)
    //              handleDeleteBreakdownOpen()
    //              //  } else {
    //              //    setErrorMessage(
    //              //     `You can not able to delete this resource, this resource is assigned to many ${params.row.taskCount} tasks`
    //              //    )
    //              //  handleErrorModalOpen()
    //              //  }
    //            }}
    //           className="process-delete"
    //         >
    //           <DeleteIconNew />
    //         </span>
    //       </Box>
    //     )
    //   }
    // }
  ]
  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={'Resource'}
        backToLink={null}
      />
      <Box
      // className="common-parent-class-overflow-auto-padding-10-50"
      >
        <Box>
          <TabContext value={value}>
            <Box
              sx={{
                typography: 'body1'
              }}
              className="tab-content-padding"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab className="tab-head" label="Machine" value="1" />
                <Tab className="tab-head" label="Setter" value="2" />
                <Tab className="tab-head" label="Operator" value="3" />
                <Tab className="tab-head" label="Breakdown" value="4" />
              </Tabs>
            </Box>
            <TabPanel value="1" className="tab-panel-padding">
              <Box className="common-parent-haeding-btn-div">
                <Typography className="common-tabel-heading">
                  Machine List
                </Typography>
                <CustomButton
                  variant="contained"
                  title="Add Machine"
                  onClick={() => navigate('/create-resource')}
                  type="button"
                  endIcon=""
                  startIcon={<AddIcon />}
                />
              </Box>
              {!isResourceListInfo
                ? (
                <TableComponent
                  rows={rows}
                  columns={columns}
                  setPage={setPage}
                  page={page}
                  totalResults={resourceListInfo?.totalResults}
                  paginationData={resourceListInfo}
                  detailsRedirection={'/resource-details'}
                />
                  )
                : (
                <Box className="progress-parent">
                  <CircularProgress />
                </Box>
                  )}
            </TabPanel>
            <TabPanel value="2" className="tab-panel-padding">
              <Box className="common-parent-haeding-btn-div">
                <Typography className="common-tabel-heading">
                  Setter List
                </Typography>
                <CustomButton
                  variant="contained"
                  title="Add Setter"
                  onClick={() => navigate('/create-setter')}
                  type="button"
                  endIcon=""
                  startIcon={<AddIcon />}
                />
              </Box>
              {!isSetterListInfo
                ? (
                <Box className="department-table-width">
                  <TableComponent
                    rows={setterRows}
                    columns={Settercolumns}
                    setPage={setPage}
                    page={page}
                    totalResults={getSetter?.totalResults}
                    paginationData={getSetter}
                    detailsRedirection={'/setter-details'}
                  />
                </Box>
                  )
                : (
                <Box className="progress-parent">
                  <CircularProgress />
                </Box>
                  )}
            </TabPanel>
            <TabPanel value="3" className="tab-panel-padding">
              <Box className="common-parent-haeding-btn-div">
                <Typography className="common-tabel-heading">
                  Operator List
                </Typography>
                <CustomButton
                  variant="contained"
                  title="Add Operator"
                  onClick={() => navigate('/create-operator')}
                  type="button"
                  endIcon=""
                  startIcon={<AddIcon />}
                />
              </Box>
              {!isOperatorListInfo
                ? (
                <Box className="department-table-width">
                  <TableComponent
                    rows={OperatorRows}
                    columns={Operatorcolumns}
                    setPage={setPage}
                    page={page}
                    totalResults={getOperator?.totalResults}
                    paginationData={getOperator}
                    detailsRedirection={'/operator-details'}
                  />
                </Box>
                  )
                : (
                <Box className="progress-parent">
                  <CircularProgress />
                </Box>
                  )}
            </TabPanel>
            <TabPanel value="4" className="tab-panel-padding">
              <Box className="common-parent-haeding-btn-div">
                <Typography className="common-tabel-heading">
                  Breakdown List
                </Typography>
                {/* <CustomButton
              variant="contained"
              title="Add Operator"
              onClick={() => navigate('/create-operator')}
              type="button"
              endIcon=""
              startIcon={<AddIcon />}
            /> */}
              </Box>
              {!isBrakdownListInfo
                ? (
                <Box className="department-table-width">
                  <TableComponent
                    rows={BreakdownRows}
                    columns={Breakdowncolumns}
                    setPage={setPage}
                    page={page}
                    totalResults={getBreakdown?.totalResults}
                    paginationData={getBreakdown}
                    detailsRedirection={'/resource-details'}
                    isBreakdown={value === '4'}
                  />
                </Box>
                  )
                : (
                <Box className="progress-parent">
                  <CircularProgress />
                </Box>
                  )}
            </TabPanel>
          </TabContext>
        </Box>

        {/* <DeleteWarningModal
          open={deleteRecord}
          handleOpen={handleDeleteOpen}
          handleClose={handleDeleteClose}
          handleDelete={deleteresource}
          id={currentId}
        /> */}
        {value === '1' && deleteRecord && currentId && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={deleteresource}
            id={currentId}
          />
        )}
        {value === '2' && deleteRecordSetter && currentSetterId && (
          <DeleteWarningModal
            open={deleteRecordSetter}
            handleOpen={handleDeleteSetterOpen}
            handleClose={handleDeleteSetterClose}
            handleDelete={deleteSetter}
            id={currentSetterId}
          />
        )}
        {value === '3' && deleteRecordOperator && currentOperatorId && (
          <DeleteWarningModal
            open={deleteRecordOperator}
            handleOpen={handleDeleteOperatorOpen}
            handleClose={handleDeleteOperatorClose}
            handleDelete={deleteOperator}
            id={currentOperatorId}
          />
        )}
        {/* TODO :- if use to open breakdown delete */}
        {/* {value === '4' && deleteRecordBreakdown && currentBreakdownId && (
          <DeleteWarningModal
            open={deleteRecordBreakdown}
            handleOpen={handleDeleteBreakdownOpen}
            handleClose={handleDeleteBreakdownClose}
            handleDelete={deleteBreakdown}
            id={currentBreakdownId}
          />
        )} */}
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            // errorDetails={errorMessage}
          />
        )}
      </Box>
    </Box>
  )
}

export default Resource
