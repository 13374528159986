import React from 'react'

const AlertIcon = ({ color = '#F4C614' }) => {
  return (
        <>
            <div>
   <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.00033 0.666748C13.6028 0.666748 17.3337 4.39758 17.3337 9.00008C17.3354 11.188 16.4766 13.2888 14.9427 14.8489C13.4089 16.4091 11.323 17.3035 9.13534 17.3389C6.94774 17.3744 4.83394 16.5481 3.25032 15.0384C1.66671 13.5288 0.740316 11.4569 0.671159 9.27008L0.666992 9.00008L0.670325 8.76675C0.793659 4.27258 4.47533 0.666748 9.00033 0.666748ZM9.00866 11.5001L8.90283 11.5059C8.70028 11.53 8.51361 11.6275 8.37817 11.7801C8.24273 11.9326 8.16792 12.1294 8.16792 12.3334C8.16792 12.5374 8.24273 12.7343 8.37817 12.8868C8.51361 13.0393 8.70028 13.1368 8.90283 13.1609L9.00033 13.1667L9.10616 13.1609C9.3087 13.1368 9.49537 13.0393 9.63081 12.8868C9.76626 12.7343 9.84106 12.5374 9.84106 12.3334C9.84106 12.1294 9.76626 11.9326 9.63081 11.7801C9.49537 11.6275 9.3087 11.53 9.10616 11.5059L9.00866 11.5001ZM9.00033 4.83341C8.79621 4.83344 8.59921 4.90838 8.44668 5.04401C8.29415 5.17964 8.19671 5.36654 8.17283 5.56925L8.16699 5.66675V9.00008L8.17283 9.09758C8.19692 9.30012 8.29446 9.48679 8.44696 9.62224C8.59947 9.75768 8.79636 9.83249 9.00033 9.83249C9.20429 9.83249 9.40118 9.75768 9.55369 9.62224C9.7062 9.48679 9.80373 9.30012 9.82783 9.09758L9.83366 9.00008V5.66675L9.82783 5.56925C9.80394 5.36654 9.7065 5.17964 9.55397 5.04401C9.40144 4.90838 9.20444 4.83344 9.00033 4.83341Z" fill={color} />
</svg>

</div>
        </>
  )
}
export default AlertIcon
