import React, { useEffect, useState } from 'react'
import './resource.css'
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Select,
  MenuItem
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Form, Formik } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import * as Yup from 'yup'
import CustomSelect from '../../leastcomponent/customselect/customselect'
import Plus from '../../assets/svg/Plus'
import MaintenanceScheduleModal from '../../modals/resourcemodal/maintenanceModal'
import { useParams } from 'react-router-dom'
import FormControl from '@mui/material/FormControl'
import TableComponent from '../../leastcomponent/customtable/customtable'
import EditIconNew from '../../assets/svg/EditIconNew'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import FormGroup from '@mui/material/FormGroup'
// import Checkbox from '@mui/material/Checkbox'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import moment from 'moment'

import {
  useAddResource,
  useGetResourcesSingle,
  useUpdateResource
} from '../../hooks/resourceHooks'
import Required from '../commoncomponents/required'
import { useGetFullTasks } from '../../hooks/tasktype'
import { useGetFullDepartments } from '../../hooks/departmentHooks'
import DeleteWarningModal from '../../modals/deletewarningmodal'

const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  fontSize: '14px !important',
  height: '40px !important',
  '.MuiOutlinedInput-input': {
    padding: '8.5px 14px',
    fontSize: '14px !important'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A'
  }
}))
const CreateResource = () => {
  // const scheduleData = [
  //   {
  //     value: 'Schedule 1',
  //     label: 'Schedule 1'
  //   },
  //   {
  //     value: 'Schedule 2',
  //     label: 'Schedule 2'
  //   },
  //   {
  //     value: 'Schedule 3',
  //     label: 'Schedule 3'
  //   },
  //   {
  //     value: 'Schedule 4',
  //     label: 'Schedule 4'
  //   },
  //   {
  //     value: 'Schedule 5',
  //     label: 'Schedule 5'
  //   }
  // ]
  const [deleteRecord, setDeleteRecord] = useState(false)
  const handleDeleteOpen = () => setDeleteRecord(true)
  const handleDeleteClose = () => setDeleteRecord(false)
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [arrow, setArrow] = useState(false)
  // const [schedule, setSchedule] = useState(false)
  const [selectedId, setSelectedId] = useState('')

  // const [scheduleAll, setScheduleAll] = useState([])
  const [taskError, setTaskError] = useState(false)

  const { id } = useParams()
  // ! here  Api
  const { data: tasktypetData } = useGetFullTasks()
  const [searchData, setSearchData] = useState([])
  const [search, setSearch] = useState([])
  // const [searchDataMulti, setSearchDataMulti] = useState([])
  const [error, setError] = useState(false)
  console.log('searchDataMulti====', error)
  // const [searchNew, setSearchNew] = useState([])
  const [rowId, setRowId] = useState('')
  const { data: departmentData } = useGetFullDepartments()
  const { data: getResources, isSuccess } = useGetResourcesSingle(id, !!id)

  // useEffect(() => {
  //   setSearchDataMulti(scheduleData)
  //   setSearchNew(scheduleData)
  // }, [])

  const {
    mutate: updateResource
    // isSuccess: isUpdateSuccess
    // isError: isUpdateResourceError,
    // error: updateResourceError
  } = useUpdateResource(id, !!id)
  const singleResources = getResources?.data
  // const singleMaintenanceDetails = singleResources?.maintenanceDetails
  const selectedType = searchData?.find((data) => data?.id === selectedId)
  useEffect(() => {
    if (singleResources?.taskType?.id) {
      setSelectedId(singleResources?.taskType?.id)
    }
  }, [singleResources?.taskType?.id])
  // useEffect(() => {
  //   if (singleResources?.schedule && isSuccess) {
  //     setScheduleAll(singleResources?.schedule?.map((data, index) => ({ label: data, value: data })))
  //   }
  // }, [isSuccess])
  useEffect(() => {
    if (tasktypetData?.data?.length > 0) {
      setSearchData(tasktypetData?.data)
      setSearch(tasktypetData?.data)
    }
  }, [tasktypetData?.data?.length])
  useEffect(() => {
    if (id) {
      setIsEdit(true)
    }
  }, [id])

  useEffect(() => {
    if (!id) {
      localStorage.removeItem('taskData')
    }
  }, [id])

  useEffect(() => {
    if (isSuccess && singleResources?.maintenanceDetails?.length > 0) {
      localStorage.setItem(
        'taskData',
        JSON.stringify(singleResources?.maintenanceDetails)
      )
    } else {
      localStorage.removeItem('taskData')
    }
  }, [isSuccess, isEdit])

  const { mutate: addResource } = useAddResource()

  const taskData = JSON.parse(localStorage.getItem('taskData'))

  // const list = formValues?.tasks || []
  const rows = (isEdit ? taskData : taskData)?.map((item, index) => {
    return {
      name: item.name ? item.name : '-',
      pmFrequency: item.pmFrequency ? item.pmFrequency : '-',
      lastmaintenancedate: item?.lastDate
        ? moment(item?.lastDate).format('MM/DD/YYYY')
        : '-',
      maintenanceCode: item.maintenanceCode ? item.maintenanceCode : '-',
      nextDate: item?.nextDate,
      id: index + 1
    }
  })

  const columns = [
    { field: 'id', headerName: '#', flex: 0.3 },
    { field: 'name', headerName: 'Maintenance Name', flex: 1 },
    {
      field: 'maintenanceCode',
      headerName: 'Maintenance Code',
      flex: 1
    },
    { field: 'pmFrequency', headerName: 'PM Frequency', flex: 1 },
    {
      field: 'lastmaintenancedate',
      headerName: 'Last performed date',
      flex: 1
    },
    {
      field: 'nextDate',
      headerName: 'Next due date ',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="resource-table-btn-main">
            {moment(params?.row?.nextDate).format('MM/DD/YYYY')}
          </Box>
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="resource-table-btn-main">
            <span
              className="process-delete"
              onClick={() => {
                setOpen(true)
                setEdit(true)
              }}
            >
              <EditIconNew />
            </span>
            <span
              className="process-delete"
              onClick={() => {
                handleDeleteOpen()
                setRowId(params?.row?.name)
              }}
            >
              <DeleteIconNew />
            </span>
          </Box>
        )
      }
    }
  ]
  const breadCrumbData = [
    {
      title: 'Resource',
      link: '/resource'
    },
    {
      title: 'Create Resource',
      link: ''
    }
  ]

  const icon = <AiOutlineInfoCircle color="red" />
  const handleDetailsClose = () => {
    setOpen(false)
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    code: Yup.string().required('Code is required'),
    // nickName: Yup.string().required('Nick Name is required'),
    department: Yup.string().required('Department is required'),
    capacity: Yup.string(),
    criticalMc: Yup.string(),
    costing: Yup.string(),
    specification: Yup.string()
  })
  const options = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ]

  const handleMaintenanceFormSubmit = (maintenanceDetails) => {}
  const handleSubmit = (values, maintenanceDetails) => {
    if (selectedId?.length > 0) {
      if (id) {
        updateResource({
          ...values,
          // schedule: scheduleAll.map(item => item.label),
          taskType: selectedId,
          maintenanceDetails: taskData || [],
          id
        })
      } else {
        addResource({
          ...values,
          // schedule: scheduleAll.map(item => item.label),
          taskType: selectedId,
          maintenanceDetails: taskData || []
        })
      }
    } else {
      !selectedId?.length >= 0 && setTaskError(true)
    }
  }

  const handleSearch = (event) => {
    const query = event.target.value
    const updatedList = search?.filter((data) => {
      return data?.name?.toLowerCase()?.includes(query?.toLowerCase())
    })
    setSearchData(updatedList)
  }
  // !----------------------------------------------------------------
  // const handleSchedule = (event) => {
  //   const query = event.target.value
  //   const updatedList = searchNew?.filter((data) => {
  //     return data?.label?.toLowerCase()?.includes(query?.toLowerCase())
  //   })
  //   setSearchDataMulti(updatedList)
  // }

  const handleChangeCheck = (id) => {
    setSelectedId(id === selectedId ? null : id) // Toggle the selectedId state
  }

  // !-------------------------------
  return (
    <Box
      className="production-team-head-overflow-hidden"
      onClick={() => setArrow(false)}
    >
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={id ? 'Edit Resource' : 'Create Resource'}
        backToLink={'/resource'}
        activeTab="1"

      />
      <Box className="">
        <Typography className="form-heading-new">Enter Details</Typography>
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: isEdit ? singleResources?.name : '',
            code: isEdit ? singleResources?.code : '',
            // nickName: isEdit ? singleResources?.nickName : '',
            department: isEdit ? singleResources?.department?.id : '',
            capacity: isEdit ? singleResources?.capacity : '',
            specification: isEdit ? singleResources?.specification : '',
            criticalMc: isEdit ? singleResources?.criticalMc : 'No',
            // schedule: isEdit ? singleResources?.schedule : [],
            type: isEdit ? singleResources?.type : 'machine',
            maintenanceDetails: []
          }}
          onSubmit={(values) => handleSubmit({ ...values, maintenanceDetails: values?.maintenanceDetails || [] }
          )
          }
          validationSchema={validationSchema}
        >
          {({
            errors,

            handleChange,
            touched,
            values,
            setFieldValue
          }) => (
            <React.Fragment>
              <Form className="resource-form-perent">
                <Box className="resource-form-child">
                  <Box className="padding-y-50">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="type"
                        onChange={handleChange}
                        value={values?.type}
                        defaultChecked={true}
                      >
                        <FormControlLabel
                          value="machine"
                          control={<Radio />}
                          label="Machine"
                        />
                        <FormControlLabel
                          value="workstation"
                          control={<Radio />}
                          label="Workstation"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Grid spacing={2} container paddingTop={2}>
                      <Grid item xs={12} md={4} xl={4}>
                        <CustomInput
                          label="Name"
                          placeholder="Enter Name"
                          handleChange={handleChange}
                          value={values?.name}
                          name="name"
                          InputProps={{
                            endAdornment: touched?.name && errors?.name && icon
                          }}
                          errors={errors?.name}
                          touched={touched?.name}
                        />
                      </Grid>
                      {/* <Grid item xs={12} md={4} xl={4}>
                        <CustomInput
                          label="Nick Name"
                          placeholder="Enter Nick Name"
                          handleChange={handleChange}
                          value={values?.nickName}
                          name="nickName"
                          InputProps={{
                            endAdornment:
                              touched?.nickName && errors?.nickName && icon
                          }}
                          errors={errors?.nickName}
                          touched={touched?.nickName}
                        />
                      </Grid> */}
                      <Grid item xs={12} md={4} xl={4}>
                        <CustomInput
                          label="Code"
                          placeholder="Enter Code"
                          handleChange={handleChange}
                          name="code"
                          value={values?.code}
                          InputProps={{
                            endAdornment: touched?.code && errors?.code && icon
                          }}
                          errors={errors?.code}
                          touched={touched?.code}
                        />
                      </Grid>
                      <Grid item xs={4} md={4} lg={4}>
                        <Typography className="field-title">
                          Installed at
                          <Required />
                        </Typography>

                        <FormControl fullWidth>
                          <Selects
                            name="department"
                            variant="outlined"
                            value={
                              departmentData?.data?.length > 0
                                ? values?.department
                                : ''
                            }
                            onChange={handleChange}
                            displayEmpty
                          >
                            <MenuItem value="">
                              <span className="menu-item-span">
                                Select Department
                              </span>
                            </MenuItem>

                            {departmentData?.data?.map((data, ind) => {
                              return (
                                <MenuItem value={data?.id} key={ind}>
                                  {data?.name}
                                </MenuItem>
                              )
                            })}
                          </Selects>
                        </FormControl>

                        {touched.department && errors.department && (
                          <Typography className="validation-text">
                            {typeof errors.department === 'string'
                              ? errors.department
                              : ''}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Grid spacing={2} paddingTop={2} container>

                      <Grid item xs={12} md={4} xl={4}>
                        <CustomInput
                          label="Specification"
                          placeholder="Enter Specification"
                          handleChange={handleChange}
                          value={values?.specification}
                          name="specification"
                          isRequired={false}
                          InputProps={{
                            endAdornment:
                              touched?.specification &&
                              errors?.specification &&
                              icon
                          }}
                          errors={errors?.specification}
                          touched={touched?.specification}
                        />
                      </Grid>
                      <Grid item xs={12} md={4} xl={4}>
                        <CustomSelect
                          name="criticalMc"
                          label="Critical MC"
                          placeholder="Select Critical MC"
                          className="default-select-new"
                          options={options}
                          isRequired={false}
                          value={values?.criticalMc}
                          handleSelectChange={(e) =>
                            setFieldValue('criticalMc', e?.target?.value)
                          }
                          errors={errors?.criticalMc}
                          touched={touched?.criticalMc}
                        />
                      </Grid>
                      <Grid item xs={12} md={4} xl={4}>
                        <CustomInput
                          label="Capacity"
                          placeholder="Enter Capacity"
                          handleChange={handleChange}
                          name="capacity"
                          isRequired={false}
                          value={values?.capacity}
                          InputProps={{
                            endAdornment:
                              touched.capacity && errors.capacity && icon
                          }}
                          errors={errors.capacity}
                          touched={touched.capacity}
                        />
                      </Grid>
                    </Grid>
                    {/* <Grid
                      spacing={2}
                      paddingTop={3}
                      container
                      // paddingBottom={2.5}
                    > */}
                    {/* <Grid item xs={12} md={4} xl={4}>
                        <CustomSelect
                          name="schedule"
                          label="Schedule"
                          className="default-select-new"
                          placeholder="Select Schedule"
                          options={schedule}
                          value={values?.schedule}
                          handleSelectChange={(e) =>
                            setFieldValue('schedule', e?.target?.value)
                          }
                          errors={errors?.schedule}
                          touched={touched?.schedule}
                        />
                      </Grid> */}
                    {/* //! start*------------------------------------------------------------------ */}
                    <Grid spacing={2} paddingTop={2} container>
                      {/* <Grid
                        item
                        xs={4}
                        md={4}
                        lg={4}
                        sx={{ position: 'relative' }}
                      >
                        <Typography className="field-title-new">
                          <Box paddingBottom={'8px'}>
                          Schedule
                          <Required />
                          </Box>
                          {scheduleAll?.length > 0
                            ? (
                            <Box
                              className="selected-search-box1"
                              onClick={(e) => {
                                e.stopPropagation()
                                setSchedule(!schedule)
                              }}
                              sx={{ display: 'flex', alignItems: 'center', position: 'relative', height: '40px', gap: '10px' }}
                            >
                              {scheduleAll?.map((data, ind) => {
                                return (
                                  <Box key={ind}>
                                  <div >
                                    {data?.label}
                                    {ind < scheduleAll.length - 1 && ','}
                                  </div>
                                  </Box>
                                )
                              })}
                              <Box sx={{ position: 'absolute', right: '10px' }}>
                              <Typography className="arrow">
                                {schedule
                                  ? (
                                  <IoIosArrowUp />
                                    )
                                  : (
                                  <IoIosArrowDown />
                                    )}
                              </Typography>
                              </Box>
                            </Box>
                              )
                            : (
                            <Box
                            style={{ position: 'relative', height: '40px', gap: '10px' }}
                              className="parent-search-box1 menu-item-span "
                              onClick={(e) => {
                                e.stopPropagation()
                                setSchedule(!schedule)
                              }}
                            >
                              Select Schedule
                              <Box sx={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                              <Typography className="arrow">
                                {schedule
                                  ? (
                                  <IoIosArrowUp />
                                    )
                                  : (
                                  <IoIosArrowDown />
                                    )}
                              </Typography>
                            </Box>
                            </Box>
                              )}
                        </Typography>

                        {schedule && (
                          <Box
                            className="parent-check"
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                          >
                            <input
                              className="task-type-input"
                              type="text"
                              placeholder="Search Schedule"
                              // value={searchTerm}
                              onChange={handleSchedule}
                            />
                            <Box className="type-menu-scroll">
                              {searchDataMulti.map((data, index) => {
                                const isChecked = scheduleAll.find(
                                  (schedule) => schedule.value === data.value
                                )
                                // console.log('isChecked===1111===>', isChecked)
                                return (
                                  <div key={index} className="type_list">
                                    <FormGroup>

                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={scheduleAll?.find(schedule => schedule.value === data.value)}
                                            onChange={() => {
                                              if (scheduleAll?.length >= 0) {
                                                setError(false)
                                              } else {
                                                setError(true)
                                              }
                                              if (!isChecked) {
                                                setScheduleAll((prev) => [
                                                  ...prev,
                                                  data
                                                ])
                                              } else {
                                                setScheduleAll((prev) =>
                                                  prev.filter(
                                                    (newData) =>
                                                      newData.value !==
                                                      data.value
                                                  )
                                                )
                                              }
                                            }}
                                          />
                                        }
                                        label={data.label}
                                      />
                                    </FormGroup>
                                  </div>
                                )
                              })}
                            </Box>
                          </Box>
                        )}

                          {
                            error &&
                        <Typography sx={{ color: 'red', fontSize: '12px' }}> Schedule is Required </Typography>
                          }

                      </Grid> */}
                      {/* //!--------`  ----------------------------------------------------------------` */}
                      {/* </Grid> */}
                      {/* <Grid item xs={12} md={4} xl={4}>
                        <CustomInput
                          label="PM Frequency"
                          placeholder="Enter PM Frequency"
                          handleChange={handleChange}
                          value={values?.frequency}
                          name="frequency"
                          isRequired={false}
                          InputProps={{
                            endAdornment:
                              touched?.frequency && errors?.frequency && icon
                          }}
                          errors={errors?.frequency}
                          touched={touched?.frequency}
                        />
                      </Grid> */}

                    </Grid>
                    <Divider />
                    <Grid
                      spacing={2}
                      //  paddingTop={3}
                      container
                    >
                      <Grid
                        item
                        xs={4}
                        md={4}
                        lg={4}
                        sx={{ position: 'relative' }}
                      >
                        <Typography
                          className="field-title-new"
                          // mt={'2px'}
                        >
                          Add Task type
                          <Required />
                          {selectedType
                            ? (
                            <Box
                              className="selected-search-box"
                              onClick={(e) => {
                                e.stopPropagation()
                                setArrow(!arrow)
                              }}
                            >
                              {selectedType?.name}
                              <Typography className="arrow">
                                {arrow ? <IoIosArrowUp /> : <IoIosArrowDown />}
                              </Typography>
                            </Box>
                              )
                            : (
                            <Box
                              className="parent-search-box menu-item-span"
                              onClick={(e) => {
                                e.stopPropagation()
                                setArrow(!arrow)
                              }}
                            >
                              Search Type
                              <Typography className="arrow">
                                {arrow ? <IoIosArrowUp /> : <IoIosArrowDown />}
                              </Typography>
                            </Box>
                              )}
                        </Typography>
                        {arrow && (
                          <Box
                            className="parent-check"
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                          >
                            <input
                              className="task-type-input"
                              type="text"
                              placeholder="Search Type"
                              onChange={handleSearch}
                            />
                            <Box className="type-menu-scroll">
                              {searchData?.map((data, index) => {
                                return (
                                  <div key={index}>
                                    <div className="type_list">
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Radio
                                              checked={data.id === selectedId}
                                              onChange={() => {
                                                if (selectedId?.length >= 0) {
                                                  setTaskError(false)
                                                } else {
                                                  setTaskError(true)
                                                }
                                                handleChangeCheck(data.id)
                                                setArrow(false)
                                              }}
                                            />
                                          }
                                        />
                                      </FormGroup>
                                      {data?.name}
                                    </div>
                                  </div>
                                )
                              })}
                            </Box>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                      {
                      taskError &&
                        <Typography sx={{ color: 'red', fontSize: '12px' }}> Task Type is Required </Typography>
                          }
                    <Divider />
                  </Box>
                  <Typography
                    className="form-heading-1 padding-y-50"
                    marginBottom={'20px'}
                  >
                    Maintenance Schedule
                  </Typography>
                  <Box className="padding-y-50">
                    {/* //!------****************************************************************** */}
                    <TableComponent rows={rows} columns={columns} />
                  </Box>
                  <Box padding={'5px 50px 30px'}>
                    {' '}
                    <CustomButton
                      variant="outlined"
                      className="button-box"
                      type="button"
                      onClick={() => setOpen(true)}
                      title="Add New Schedule"
                      startIcon={<Plus fill={'#0160B9'} />}
                    />
                  </Box>
                </Box>

                <Box className="button-child">
                  <CustomButton
                    onClick={() => {
                      selectedId?.length > 0 ? setTaskError(false) : setTaskError(true)
                      if (selectedId?.length > 0) {
                        setError(false)
                      } else {
                        setError(true)
                      }
                    }}
                    title={id ? 'Update' : 'Submit'}
                    type="submit"
                  />
                </Box>
                {open && (
                  <MaintenanceScheduleModal
                    open={open}
                    DetailsHandleClose={handleDetailsClose}
                    setOpen={() => setOpen(false)}
                    onSubmit={handleMaintenanceFormSubmit}
                    basicDetailForm={taskData}
                    isEdit={edit}
                  />
                )}
                {deleteRecord && (
                  <DeleteWarningModal
                    open={handleDeleteOpen}
                    handleOpen={handleDeleteOpen}
                    handleClose={handleDeleteClose}
                    isDeleteOrRestoreAll={false}
                    isLocal={true}
                    taskData={taskData}
                    rowId={rowId}
                  />
                )}
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </Box>
    </Box>
  )
}

export default CreateResource
