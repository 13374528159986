import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'
import BookIcon from '../../assets/svg/BookIcon'
import { Collapse, List, ListItemText } from '@mui/material'
import _ from 'lodash'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

const RecentActivityCard = ({ cardData }) => {
  const CardWrapper = styled(Card)(() => ({
    border: '1px solid #DEDEDE',
    padding: '13px 20px 11px 16px',
    margin: '0px 5px 10px 4px',
    '.MuiCardContent-root': {
      padding: '0px'
    },
    '.MuiCardContent-root:last-child': {
      paddingBottom: '0px'
    },
    '.title': {
      color: '#1D1B1B',
      fontWeight: 400,
      fontSize: '14px',
      textTransform: 'capitalize'
    },
    '.cursor-pointer': {
      cursor: 'pointer'
    },
    '.comment': {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '11px'
    },
    '.description': {
      color: '#777676',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '18px',
      padding: 2
    },
    '.discussion-btn': {
      marginTop: '14px',
      borderRadius: '10px',
      border: '1px solid #265AA8'
    }
  }))
  const [open, setOpen] = React.useState(false)
  const handleClick = () => {
    setOpen(!open)
  }
  return (
        <CardWrapper elevation={0}
        >
            <CardContent>
                <Box onClick={handleClick} display='flex'>
                    <ListItemText>
                        <Typography className='title'>
                            <BookIcon />
                            {_.get(cardData, 'title', '-')}
                        </Typography>
                        {/* <Typography className='description'>{_.get(cardData, 'message', '-')}</Typography> */}
                    </ListItemText>
                    {open
                      ? (
                        <ExpandMore className='cursor-pointer' />
                        )
                      : (
                        <ExpandLess className='cursor-pointer' />
                        )}
                </Box>
        {/* //! collaps for future use this feature */}
               {open && <Typography className='description'>{_.get(cardData, 'message', '-')}</Typography>}
                <Collapse in={open} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        {/* <Typography className='description'>{_.get(cardData, 'message', '-')}</Typography> */}
                        {/* <Box className='comment'> */}
                        {/* <Typography>By {_.get(cardData?.createdBy, 'name', '-')}</Typography> */}
                        {/* <Box>
                <CommentIcon />
                20
              </Box> */}
                        {/* </Box> */}
                        {/* <Button variant='outlined' fullWidth className='discussion-btn'>
              View Discussion
            </Button> */}
                    </List>
                </Collapse>
            </CardContent>
        </CardWrapper>
  )
}

export default RecentActivityCard
