import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { resource } from '../querykeys/resource'
import { useNavigate } from 'react-router-dom'
import { resourceApi } from '../api/resource'
// import { DepartmentErrI, DepartmentListErrI } from '../Types/department';

export const useGetResource = (isEnabled = true, page) => {
  return useQuery([resource.RESOURCE, page], resourceApi.getResources, {
    enabled: isEnabled,
    select: (res) => res?.data,
    // ! TODO: for future implementation toast
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}

export const useAddResource = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(resourceApi.addResource, {
    onSuccess: () => {
      queryClient.invalidateQueries([resource.RESOURCE])
      navigate('/resource', { state: { activeTab: '1' } })
      localStorage.removeItem('taskData')
    },
    onError: () => {

      // ! TODO: for future implementation toast
      // toast.error(errData.message);
    }
  })
}
export const useUpdateResource = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(resourceApi.updateResource, {
    onSuccess: () => {
      queryClient.invalidateQueries([resource.UPDATERESOURCE])
      queryClient.invalidateQueries([resource.RESOURCE])
      localStorage.removeItem('taskData')
      navigate('/resource', { state: { activeTab: '1' } })
    },
    onError: () => {

      // ! TODO: for future implementation toast
      // toast.error(errData.message);
    }
  })
}
export const useGetResourcesSingle = (id, isEnabled = true) => {
  return useQuery([resource.RESOURCE, id], resourceApi.getSingleResources, {
    enabled: isEnabled,
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: (err) => {
      return err
    },
    refetchOnWindowFocus: false
  })
}

export const useDeleteresource = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(resourceApi.deleteResources, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([resource.RESOURCES])
      queryClient.removeQueries([resource.RESOURCE, id])
      // toast.success(data.message);
      navigate('/resource', { state: { activeTab: '1' } })
    }
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
  })
}
export const useGetFullresource = (taskTypeId = '', isEnabled = false) => {
  return useQuery(
    [resource.RESOURCE + 'FULL', taskTypeId],
    resourceApi.getFullResources,
    {
      enabled: isEnabled,
      select: (data) => data?.data,
      // onSuccess: () => {
      //   // const data = res?.data;
      // },
      onError: (err) => {
        return err
        // const errData = err?.data;
        // toast.error(errData.message);
      }
    }
  )
}
export const useGetFullresourceMachine = () => {
  return useQuery(
    [resource.RESOURCE + 'FULL'],
    resourceApi.getFullResourcesMachine,
    {
      select: (data) => data?.data,
      onError: (err) => {
        return err
      }
    }
  )
}
export const useRestoreResource = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(resourceApi.restoreResource, {
    onSuccess: (res, id) => {
      // const data = data
      queryClient.invalidateQueries([resource.RESTORERESOURCE])
      // queryClient.invalidateQueries([part.PART, id]);
      // toast.success(data.message);
      // const materialType = data?.part?.partMaterialType;
      navigate('/deleted-items')
    },
    onError: (err) => {
      return err
      // const errData = err?.data;
      // toast.error(errData.message);
    }
  })
}
export const useHardDeleteResource = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(resourceApi.hardDeleteResource, {
    onSuccess: (res, id) => {
      // const data = res.data
      queryClient.invalidateQueries([resource.RESTORERESOURCE])
      // queryClient.invalidateQueries([part.PART, id]);
      navigate('/deleted-items')
    },
    onError: (err) => {
      return err
      // const errData = err?.data;
      // toast.error(errData.message);
    }
  })
}
export const useGetDeletedResource = (id, isEnabled = true) => {
  return useQuery(
    [resource.RESTORERESOURCE + 'DELETED', id],
    resourceApi.getDeleteResource,
    {
      enabled: isEnabled,
      select: (data) => data.data,
      // ! TODO: for future implementation toast
      // onSuccess: (res: any) => {
      //   const data = res?.data;
      // },
      // onError: (err: any) => {
      //   const errData = err?.data;
      //   //toast.error(errData.message);
      // },
      refetchOnWindowFocus: false
    }
  )
}
export const useAddSetter = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(resourceApi.addSetter, {
    onSuccess: () => {
      queryClient.invalidateQueries([resource.SETTER])
      navigate('/resource', { state: { activeTab: '2' } })
      // localStorage.removeItem('taskData')
    },
    onError: () => {

      // ! TODO: for future implementation toast
      // toast.error(errData.message);
    }
  })
}
export const useUpdateSetter = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(resourceApi.updateSetter, {
    onSuccess: () => {
      queryClient.invalidateQueries([resource.UPDATESETTER])
      // queryClient.invalidateQueries([resource.RESOURCE])
      // localStorage.removeItem('taskData')
      navigate('/resource', { state: { activeTab: '2' } })
    },
    onError: () => {

      // ! TODO: for future implementation toast
      // toast.error(errData.message);
    }
  })
}
export const useGetSetter = (isEnabled = true, page) => {
  return useQuery(
    [resource.SETTER, page],
    resourceApi.getFullSetter,
    {
      enabled: isEnabled,
      select: (res) => res?.data,
      // onSuccess: () => {
      //   // const data = res?.data;
      // },
      onError: (err) => {
        return err
        // const errData = err?.data;
        // toast.error(errData.message);
      }
    }
  )
}
export const useDeleteSetter = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(resourceApi.deleteSetter, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([resource.SETTER])
      queryClient.removeQueries([resource.SETTER, id])
      // toast.success(data.message);
      navigate('/resource', { state: { activeTab: '2' } })
    }
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
  })
}
export const useGetSetterSingle = (id, isEnabled = true) => {
  return useQuery([resource.SETTER, id], resourceApi.getSingleSetter, {
    enabled: isEnabled,
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: (err) => {
      return err
    },
    refetchOnWindowFocus: false
  })
}
export const useValidateSetter = () => {
  // const queryClient = useQueryClient();
  // const navigate = useNavigate();
  return useMutation(resourceApi.validateSetter, {
    // onSuccess: (res) => {
    // queryClient.invalidateQueries([machine.MACHINES]);
    // const data = res.data;
    // toast.success(data?.message);
    // navigate(`/machine-details/${data?.machine.id}`);
    // navigate(`/resources`, { state: { activeTab: '1' } });
    // },
    // onError: (err: any) => {
    // const errData = err?.data;
    // },
  })
}
export const useAddOperator = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(resourceApi.addOperator, {
    onSuccess: () => {
      queryClient.invalidateQueries([resource.OPERATOR])
      navigate('/resource', { state: { activeTab: '3' } })
      // localStorage.removeItem('taskData')
    },
    onError: () => {

      // ! TODO: for future implementation toast
      // toast.error(errData.message);
    }
  })
}
export const useValidateOprator = () => {
  return useMutation(resourceApi.validateOperator, {
  })
}
export const useUpdateOperator = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(resourceApi.updateOperator, {
    onSuccess: () => {
      queryClient.invalidateQueries([resource.UPDATEOPERATOR])
      // queryClient.invalidateQueries([resource.RESOURCE])
      // localStorage.removeItem('taskData')
      navigate('/resource', { state: { activeTab: '3' } })
    },
    onError: () => {

      // ! TODO: for future implementation toast
      // toast.error(errData.message);
    }
  })
}
export const useDeleteOperator = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(resourceApi.deleteOperator, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([resource.OPERATOR])
      queryClient.removeQueries([resource.OPERATOR, id])
      // toast.success(data.message);
      navigate('/resource', { state: { activeTab: '3' } })
    }
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
  })
}
export const useGetOperator = (isEnabled = true, page) => {
  return useQuery(
    [resource.OPERATOR, page],
    resourceApi.getFullOperator,
    {
      enabled: isEnabled,
      select: (res) => res?.data,
      // onSuccess: () => {
      //   // const data = res?.data;
      // },
      onError: (err) => {
        return err
        // const errData = err?.data;
        // toast.error(errData.message);
      }
    }
  )
}
export const useGetfullListOperator = () => {
  return useQuery(
    [resource.OPERATOR],
    resourceApi.getFullListOperator,
    {
      select: (res) => res?.data,
      onError: (err) => {
        return err
      }
    }
  )
}
export const useGetOperatorSingle = (id, isEnabled = true) => {
  return useQuery([resource.OPERATOR, id], resourceApi.getSingleOperator, {
    enabled: isEnabled,
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: (err) => {
      return err
    },
    refetchOnWindowFocus: false
  })
}
export const useGetBreakdown = (isEnabled = true, page) => {
  return useQuery([resource.BREAKDOWN, page], resourceApi.getBreakdown, {
    enabled: isEnabled,
    select: (res) => res?.data,
    refetchOnWindowFocus: false
  })
}
export const useDeleteBreakdown = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(resourceApi.deleteBreakdown, {
    onSuccess: (res, id) => {
      // const data = res.data;1
      queryClient.invalidateQueries([resource.BREAKDOWN])
      queryClient.removeQueries([resource.BREAKDOWN, id])
      // toast.success(data.message);
      navigate('/resource', { state: { activeTab: '4' } })
    }
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
  })
}
