import { Box, CircularProgress, Tab, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import { TabContext, TabPanel } from '@mui/lab'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import DeleteWarningModal from '../../modals/deletewarningmodal'
// import EditIconNew from '../../assets/svg/EditIconNew'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import { useDeleteTask, useGetallTaskList, useGetallTaskListOngoing } from '../../hooks/alltaskHooks'
import TableComponent from '../../leastcomponent/customtable/customtable'
// import Instruction from '../../assets/svg/Instruction'
// import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import AllTaskSearch from './allTaskSearch'
import JobSlipsRadioWithSelectModal from '../../modals/jobcardmodal/jobslipsradiowithselect'
import ConfirmJobSlip from '../../modals/jobcardmodal/confirmjobslip'
import ConfirmOverdue from '../../modals/jobcardmodal/confirmoverdue'
import ArrowsActive from '../../assets/svg/ArrowsActive'
import PendingsActive from '../../assets/svg/PendingActive'
import JobcardSchedule from '../jobcards/jobcardSchedule'
import { useQueryClient } from '@tanstack/react-query'
import { user as user2 } from '../../querykeys/usermanagement'

// import JobCardDetailsModal from '../../modals/jobcardmodal/jobCardDetailsModal'

const AllTask = ({ user }) => {
  const [deleteRecord, setDeleteRecord] = useState(false)
  // const [open, setOpen] = useState(false)
  const [tasks, setTasks] = useState({})
  // const [processData, setProcessData] = useState({})
  // const handleDetailsClose = () => {
  //   setOpen(false)
  // }
  const [currentId, setCurrentId] = useState('')
  const [taskId, setTaskId] = useState('')
  console.log('currentId', taskId)
  const [partName, setPartName] = useState('')
  const handleDeleteOpen = () => setDeleteRecord(true)
  const handleDeleteClose = () => setDeleteRecord(false)
  const [page, setPage] = useState(1)
  const [value, setValue] = React.useState('1')
  const handleChange = (event, newValue) => {
    setValue(newValue) // Update the selected tab
    setPage(1)
  }
  const [processData, setProcessData] = useState({})
  const [jobName, setJobName] = useState({ name: '', code: '' })
  console.log('proo--', processData, setJobName)
  const [jobCardModel, setJobCardModel] = useState('')
  const handleDetailsClose = () => {
    setJobCardModel(false)
  }
  const [jobIdValues, setJobIdValues] = useState({})
  const [jobId, setJobId] = useState('')
  const [partId, setPartId] = useState('')
  const [jobSlipsRadioWithSelectModal, setJobSlipsRadioWithSelectModal] =
    useState(false)
  const [confirmJobSlip, setConfirmJobSlip] = React.useState(false)
  const [confirmOverdue, setConfirmOverdue] = React.useState(false)
  // const navigate = useNavigate()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('lg'))
  const [first, setfirst] = useState('')
  const selectDepartmentid = localStorage.getItem('selectDepartmentid')
  const { mutate: deleteTask } = useDeleteTask()
  // user role permission
  const queryClient = useQueryClient()
  const userData = queryClient.getQueryData([user2.LOGGED_IN_USER])
  const userRoleAccess = userData?.data?.data?.user
  const AdminUser = userData?.data?.data?.user?.userPermissions?.find((data) => data?.userModule === 'all' && data)
  const isScheduleTasksPermission = userRoleAccess?.userPermissions?.find((data) => data?.userModule === 'Schedule Job Cards')

  const mainCols = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'taskNickName', headerName: 'Task Name ', flex: matches ? 1 : 0.7 },
    { field: 'taskType', headerName: 'Task Type ', flex: matches ? 1 : 0.5 },
    {
      field: 'partName',
      headerName: 'Part Name ',
      flex: matches ? 1 : 0.7
    },
    {
      field: 'numberOf',
      headerName: 'Part Number',
      flex: matches ? 1 : 0.5
    },
    {
      field: 'customerName',
      headerName: 'Cutomer Name',
      flex: matches ? 1 : 0.5
    },
    { field: 'jobCardNumber', headerName: 'JobCard Number', flex: matches ? 1 : 0.5 },
    {
      field: 'createdAt',
      headerName: 'Created On',
      flex: matches ? 1 : 0.7
    },

    // {{/* TODO: future use */}
    //   field: 'submitted',
    //   headerName: 'Insturnction',
    //   flex: matches ? 1 : 0.7,
    //   renderCell: (params) => {
    //     return (
    //       <Box sx={{ display: 'flex', gap: '10px' }}>
    //       <Box className="table-btn-main">
    //           <span
    //             className="process-delete"
    //             // onClick={() => navigate(`/schedule/${params.row.id}`)}
    //           >
    //             <Instruction />
    //         </span>
    //         </Box>
    //         </Box>
    //     )
    //   }
    // },
    (AdminUser?.userModule === 'all' || isScheduleTasksPermission?.edit) && (
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        disableClickEventBubbling: true,
        flex: 1.5,
        renderCell: (params) => {
          return (
          <Box className="report-table-btn-main">
            {console.log('params', params)}

            {/* Conditionally render the Schedule button */}
            {(AdminUser?.userModule === 'all' || isScheduleTasksPermission?.edit) && (
              <span
                onClick={() => {
                  setJobName({
                    ...jobName,
                    name: params?.row?.jobName,
                    code: params?.row?.jobCode
                  })
                  setTasks({
                    history: [
                      {
                        taskId: params?.id,
                        taskNickName: params?.row?.name
                      }
                    ]
                  })
                  setJobCardModel(true)
                  setTaskId(params?.row?.jobCardId)
                  setPartId(params?.row?.partId)
                }}
              >
                Schedule
              </span>
            )}

            {/* Always render Delete button if allowed */}
            {(AdminUser?.userModule === 'all' || isScheduleTasksPermission?.delete) && (
              <Box className="table-btn-main">
                <span
                  onClick={() => {
                    setCurrentId(params.row.id)
                    handleDeleteOpen()
                  }}
                  className="process-delete"
                >
                  <DeleteIconNew />
                </span>
              </Box>
            )}
          </Box>
          )
        }
      }
    )

  ]
  const ongoingCols = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'taskNickName', headerName: 'Task Name ', flex: matches ? 1 : 0.7 },
    { field: 'taskType', headerName: 'Task Type ', flex: matches ? 1 : 0.5 },
    {
      field: 'partName',
      headerName: 'Part Name ',
      flex: matches ? 1 : 0.7
    },
    {
      field: 'numberOf',
      headerName: 'Part Number',
      flex: matches ? 1 : 0.5
    },
    {
      field: 'machinName',
      headerName: 'Machine Name ',
      flex: matches ? 1 : 0.7
    },
    {
      field: 'customerName',
      headerName: 'Cutomer Name',
      flex: matches ? 1 : 0.5
    },
    { field: 'jobCardNumber', headerName: 'JobCard Number', flex: matches ? 1 : 0.5 },
    {
      field: 'scheduledOn',
      headerName: 'Scheduled On',
      flex: matches ? 1 : 0.7
    },

    // {{/* TODO: future use */}
    //   field: 'submitted',
    //   headerName: 'Insturnction',
    //   flex: matches ? 1 : 0.7,
    //   renderCell: (params) => {
    //     return (
    //       <Box sx={{ display: 'flex', gap: '10px' }}>
    //       <Box className="table-btn-main">
    //           <span
    //             className="process-delete"
    //             // onClick={() => navigate(`/schedule/${params.row.id}`)}
    //           >
    //             <Instruction />
    //         </span>
    //         </Box>
    //         </Box>
    //     )
    //   }
    // },
    // {
    (AdminUser?.userModule === 'all' || isScheduleTasksPermission?.edit) && (
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        disableClickEventBubbling: true,
        flex: 1.5,
        renderCell: (params) => {
          return (
            <Box className="report-table-btn-main">
              {/* Checking permissions to render action buttons */}
              {(AdminUser?.userModule === 'all' || isScheduleTasksPermission?.edit) && (
                <span
                  className="process-delete"
                  onClick={() => {
                    setJobId(params?.id)
                    setJobSlipsRadioWithSelectModal(true)
                  }}
                >
                  {/* Action Icons */}
                  <>
                    <ArrowsActive />
                    <PendingsActive />
                  </>
                </span>
              )}

              {/* Future Use - Edit and Delete (commented for now) */}
              {/*
                <span
                  onClick={() => {
                    const { jobCardId, partId } = params.row;
                    if (jobCardId && partId) {
                      navigate(`/job-details/${jobCardId}/${partId}`, {
                        state: { partId, sidebarTab: location.pathname.includes('job-card-department') ? 3 : 2 }
                      });
                    } else {
                      console.error('Job Card ID or Task ID is missing');
                    }
                  }}
                >
                  Update
                </span>

                <span
                  onClick={() => {
                    setJobName({ ...jobName, name: params?.row?.jobName, code: params?.row?.jobCode });
                    setTasks({ history: [{ taskId: params?.id, taskNickName: params?.row?.name }] });
                    setJobCardModel(true);
                  }}
                >
                  Update
                </span>
                */}

              {/* Delete Action (currently active) */}
              {/* <Box className="table-btn-main">
                <span
                  onClick={() => {
                    setCurrentId(params.row.id)
                    handleDeleteOpen()
                  }}
                  className="process-delete"
                >
                  <DeleteIconNew />
                </span>
              </Box> */}
            </Box>
          )
        }
      }
    )

    // }

  ]
  const {
    data: allTaskList,
    isFetching: isAllTaskPending
  } = useGetallTaskList(!!(selectDepartmentid, value === '1'), page, selectDepartmentid, partName)
  const allTaskListData = allTaskList?.results
  const taskList = allTaskListData?.map((data, ind) => ({
    no: ind + 1,
    id: data.task.id ? data.task.id : '-',
    partId: data.id ? data.id : '-',
    jobCardId: data.task.jobCardId.id ? data.task.jobCardId.id : '-',
    customerName: data.task.jobCardId.customerName ? data.task.jobCardId.customerName : '-',
    taskType: data.task.taskType.name ? data.task.taskType.name : '-',
    taskNickName: data.task.taskNickName ? data.task.taskNickName : '-',
    partName: data.partName ? data.partName : '-',
    numberOf: data.numberOf ? data.numberOf : '-',
    jobCardNumber: data.task.jobCardId.jobCardNumber ? data.task.jobCardId.jobCardNumber : '-',
    createdAt: moment(data?.task.createdAt).format('DD MMM, YYYY') ? moment(data?.task.createdAt).format('DD MMM, YYYY') : '-',
    taskStatus: data.task.taskStatus ? data.task.taskStatus : '-'

  }))
  const {
    data: allTaskListOngoing,
    isFetching: isAllTaskOngoing
  } = useGetallTaskListOngoing(!!(selectDepartmentid, value === '2'), page, selectDepartmentid, partName)
  const allTaskListOngoingData = allTaskListOngoing?.results
  const ongoinglist = allTaskListOngoingData?.map((data, ind) => ({
    no: ind + 1,
    id: data.task.id ? data.task.id : '-',
    partId: data.id ? data.id : '-',
    jobCardId: data.task.jobCardId.id ? data.task.jobCardId.id : '-',
    customerName: data.task.jobCardId.customerName ? data.task.jobCardId.customerName : '-',
    taskType: data.task.taskType.name ? data.task.taskType.name : '-',
    taskNickName: data.task.taskNickName ? data.task.taskNickName : '-',
    partName: data.partName ? data.partName : '-',
    machinName: data.task.resource.name ? data.task.resource.name : '-',
    numberOf: data.numberOf ? data.numberOf : '-',
    jobCardNumber: data.task.jobCardId.jobCardNumber ? data.task.jobCardId.jobCardNumber : '-',
    scheduledOn: moment(data?.task.scheduledOn).format('DD MMM, YYYY') ? moment(data?.task.scheduledOn).format('DD MMM, YYYY') : '-',
    taskStatus: data.task.taskStatus ? data.task.taskStatus : '-'

  }))

  useEffect(() => {
    if (currentId && allTaskListOngoingData) {
      allTaskListOngoingData?.map((singleTask) => {
        if (singleTask?.task?.id === currentId) {
          setTasks(singleTask)
          return singleTask
        } else {
          return singleTask
        }
      })
    }
  }, [currentId])
  return (
    <React.Fragment>
      <Box className="production-team-head-overflow-hidden">
        <BreadcrumbNavigation
          breadCrumbData={null}
          setfirst={setfirst}
          first={first}
          pageTitle={'Job Cards -'}
          isDepartment={true}
          selectedDepartment={selectDepartmentid}
          backToLink={null}
        />
        <Box className="production-team-head-overflow-auto">
          <TabContext value={value}>
          <Box
              sx={{
                typography: 'body1',
                display: 'flex',
                justifyContent: 'space-between'
              }}
              className="tab-content-padding"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >

                <Tab className="tab-head" label="Pending" value="1" />
                <Tab className="tab-head" label="Ongoing" value="2" />
              </Tabs>
              <Box className= "set-search">
            <AllTaskSearch
            setPartName={setPartName}
            partName={partName}
            />
            </Box>
            </Box>
            <TabPanel value="1" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                  Pending
                  </Typography>

                </Box>
                {
                  !isAllTaskPending
                    ? <TableComponent
                  rows={taskList}
                  columns={mainCols}
                  mainCols={mainCols}
                  page={page}
                  setPage={setPage}
                  paginationData={allTaskList}
                  totalResults={allTaskList?.totalResults}
                  value={value}
                  // status={value}
                />
                    : <Box className='progress-parent'>
                      <CircularProgress />
                    </Box>}
                    { !selectDepartmentid && <Typography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#000000DE' }}>Please select department</Typography>
              }
              </Box>
            </TabPanel>
            <TabPanel value="2" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                  Ongoing
                  </Typography>

                </Box>
                {
                  !isAllTaskOngoing
                    ? <TableComponent
                  rows={ongoinglist}
                  columns={ongoingCols}
                  // mainCols={ongoingCols}
                  page={page}
                  setPage={setPage}
                  paginationData={allTaskListOngoing}
                  totalResults={allTaskListOngoing?.totalResults}
                      value={value}
                      setProcessData={setProcessData}
                  // status={value}
                />
                    : <Box className='progress-parent'>
                      <CircularProgress />
                    </Box>}
              </Box>
              { !selectDepartmentid && <Typography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#000000DE' }}>Please select department</Typography>
              }
            </TabPanel>
          </TabContext>
        </Box>
        {deleteRecord && currentId && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={deleteTask}
            id={currentId}
          />
        )}
        {/* TODO: future use */}
        {jobCardModel && (
          <JobcardSchedule
            tasks={tasks}
            id={taskId}
            partId={partId}
            jobName={jobName}
            processData={allTaskListData}
            open={jobCardModel}
            DetailsHandleClose={handleDetailsClose}
            setOpen={() => setJobCardModel(false)}
          />
        )}
          {jobSlipsRadioWithSelectModal && (
                <JobSlipsRadioWithSelectModal
                  open={jobSlipsRadioWithSelectModal}
                  handleClose={() => setJobSlipsRadioWithSelectModal(false)}
                  slipOpen={() => setConfirmJobSlip(true)}
                  overdueOpen={() => setConfirmOverdue(true)}
                  setJobIdValues={setJobIdValues}
                />
          )}
         {confirmJobSlip && (
                <ConfirmJobSlip
                  open={confirmJobSlip}
                  handleClose={() => setConfirmJobSlip(false)}
                  openJobSlip={() => setJobSlipsRadioWithSelectModal(true)}
                  jobId={jobId}
                  jobIdValues={jobIdValues}
                />
         )}
        {ConfirmOverdue && (
                <ConfirmOverdue
                  open={confirmOverdue}
                  handleClose={() => setConfirmOverdue(false)}
                  openJobSlip={() => setJobSlipsRadioWithSelectModal(true)}
                  jobId={jobId}
                />
        )}
      </Box>
    </React.Fragment>
  )
}

export default AllTask
