import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import {
  Box,
  Divider,
  Grid,
  Typography,
  Select,
  CircularProgress,
  FormControl,
  MenuItem,
  TextField,
  Switch
} from '@mui/material'
import { ErrorMessage, Form, Formik } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import * as Yup from 'yup'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import { useLocation, useParams } from 'react-router-dom'
import ErrorPopup from '../../modals/errorpopup'
import Required from '../commoncomponents/required'
import { useGetFullDepartments } from '../../hooks/departmentHooks'
import { useAddSetter, useGetSetterSingle, useUpdateSetter, useValidateSetter } from '../../hooks/resourceHooks'
const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '8.5px 14px',
    fontSize: '14px !important'

  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A'
  }
}))
const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '38px',
    padding: '1px 10px',
    fontSize: '14px !important'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px'
    }
  }
}))
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1890ff',
        ...theme.applyStyles('dark', {
          backgroundColor: '#177ddc'
        })
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)'
    })
  }
}))
const CreateSetter = () => {
  const location = useLocation()
  const isDeleted = location.pathname.startsWith('/deleted-items')
  const { id } = useParams()
  const [isEdit, setIsEdit] = useState(false)
  const { data: departmentData } = useGetFullDepartments()
  const { data: getSetterSingle, isGetsingleSetter, error: singleSetterError, isSuccess } = useGetSetterSingle(id, !!id)
  const singleSetter = getSetterSingle?.data

  useEffect(() => {
    if (id) {
      setIsEdit(true)
    }
  }, [id])
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const icon = <AiOutlineInfoCircle color="red" />

  const {
    mutate: addSetter,
    isError: isAddSetterError,
    error: addSetterError,
    isLoading
  } = useAddSetter()

  const {
    mutate: editSetter,
    isError: isEditUserError,
    error: updateUserError
  } = useUpdateSetter()
  const {
    mutate: validateSetter,
    data: validateData,
    isLoading: isValidating
  } = useValidateSetter()
  React.useEffect(() => {
    if (isAddSetterError || isEditUserError || singleSetterError) {
      setErrorMessage(
        isAddSetterError
          ? addSetterError?.data?.message
          : isEditUserError
            ? updateUserError?.data?.message
            : singleSetterError?.data?.message
      )
      setIsErrorModalOpen(true)
    }
  }, [isAddSetterError, isEditUserError, singleSetterError, addSetterError, updateUserError])
  React.useEffect(() => {
    if (id && isSuccess) {
      if (singleSetter?.firstName) {
        validateSetter({
          firstName: singleSetter?.firstName,
          id
        })
      }
    }
  }, [
    id,
    isSuccess,
    singleSetter?.firstName,
    id
  ])
  // New state for Set New Pin toggle
  const [isSetPinEnabled, setIsSetPinEnabled] = useState(!id)
  const handleSetPinToggle = () => {
    setIsSetPinEnabled(!isSetPinEnabled)
  }
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Setter Name is required'),
    department: Yup.string().required('Department is required')
    // pin: Yup.string()
    //   .required('Pin is required')
    //   .max(4, 'Pin must be at most 4 characters long'),
    // confirmPin: Yup.string()
    //   .required('Confirm pin is required')
    //   .oneOf([Yup.ref('pin'), null], 'Pins must match')
  })

  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={id ? 'Edit Setter' : 'Create Setter'}
        backToLink={'/resource'}
        activeTab="2"
      />

      {!isGetsingleSetter
        ? <Box className="" paddingTop={'50px'}>
        <Typography className="form-heading-edit">
        {id ? 'Edit Setter' : 'Create Setter'}{' '}
        </Typography>
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: isEdit ? singleSetter?.firstName : '',
            department: isEdit ? singleSetter?.department?.id : '',
            pin: isEdit ? singleSetter?.pin : '',
            confirmPin: isEdit ? singleSetter?.confirmPin : ''
          }}
          onSubmit={(values) =>
            id ? editSetter({ ...values, id }) : addSetter(values)
          }
          validationSchema={validationSchema}
        >
          {({ errors, handleChange, touched, values }) => (
            <React.Fragment>
              <Form>
                <Box className="user-managegement-form-child1">
                  <Box className="padding-y-50">
                    <Grid spacing={2} container paddingTop={2}>
                      <Grid item xs={12} md={4} xl={4}>
                        <Box>
                             <Typography className='field-title'>
                             Setter Name
                      <Required />
                    </Typography>
                           <Input
                      placeholder='Enter Setter Name'
                      fullWidth
                      onChange={(e) => {
                        validateSetter({
                          firstName: e.target.value,
                          ...(id && { id })
                        })
                        handleChange(e)
                      }}
                      value={values.firstName || ''}
                      name='firstName'
                      InputProps={{
                        style: { fontSize: 14, padding: '0px !important', height: '40px' },
                        endAdornment: touched.firstName && errors.firstName && icon
                      }}
                    />
                    {!validateData?.data.isValid && values?.firstName && !isValidating
                      ? (
                      <Typography className='validation-text'>
                        {'Setter Name already taken'}
                      </Typography>
                        )
                      : (
                      <ErrorMessage
                        name='firstName'
                        render={(msg) => <Typography className='validation-text'>{msg}</Typography>}
                      />
                        )}
                          </Box>
                      </Grid>
                      <Grid item xs={4} md={4} lg={4}>
                        <Typography className="field-title" mt={'2px'}>
                          Department
                          <Required />
                        </Typography>
                        <FormControl fullWidth>
                          <Selects
                            name="department"
                            variant="outlined"
                            value={
                              departmentData?.data?.length
                                ? values?.department
                                : ''
                            }
                            onChange={handleChange}
                            displayEmpty
                          >
                            <MenuItem value="">
                              <span className="menu-item-span">
                                Select Department
                              </span>
                            </MenuItem>

                            {departmentData?.data?.map((data, ind) => {
                              return (
                                <MenuItem value={data?.id} key={ind}>
                                  {data?.name}
                                </MenuItem>
                              )
                            })}
                          </Selects>
                        </FormControl>

                        {touched.department && errors.department && (
                          <Typography className="validation-text">
                            {typeof errors.department === 'string'
                              ? errors.department
                              : ''}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                      <Divider />
                      {id && (
                    <><Typography className="form-heading-edit1" marginBottom={2}>
                          View Pin{' '}
                        </Typography><Typography color={'#A6A6A6'}>
                            Pin
                          </Typography><Box sx={{ width: '500px', paddingBottom: '15px' }}>
                            <Input
                              fullWidth
                              value={values.pin || ''}
                              name='pin'
                              disabled />
                          </Box></>
                      )}
                      <Typography className="form-heading-edit1" marginBottom={2}>
                     Set Pin{' '}
                      </Typography>
                      <Box
                         display="flex"
                         alignItems="center"
                         gap={1}
                         mb={3}
                         px={2}
                         py={1}
                         border={1}
                         borderColor="grey.400"
                        borderRadius={1}
                        width={'fit-content'}
                      >
                        <Typography variant="body2">Set New Pin</Typography>
                        <AntSwitch
                          checked={isSetPinEnabled}
                          // value={isSetPinEnabled}
                             onChange={handleSetPinToggle}
                             color="primary"
                        />
                      </Box>
                      {isSetPinEnabled && (
                        <Grid spacing={2} paddingTop={3} container>
                          <Grid item xs={12} md={4} xl={4}>
                            <CustomInput
                              label="Enter Pin"
                              isRequired={false}
                              placeholder="Enter Pin"
                              handleChange={handleChange}
                              name="pin"
                              type='password'
                              value={values?.pin}
                              // InputProps={{
                              //   style: { fontSize: 14, padding: '0px !important', height: '40px' },
                              //   endAdornment:
                              //     touched.pin && errors.pin && icon
                              // }}
                              // errors={errors.pin}
                              // touched={touched.pin}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} xl={4}>
                            <CustomInput
                              label="Confirm Pin"
                              isRequired={false}
                              placeholder="Enter Pin"
                              handleChange={handleChange}
                              type='password'
                              name="confirmPin"
                              value={values?.confirmPin}
                              // InputProps={{
                              //   endAdornment:
                              //     touched.pin &&
                              //     errors.confirmPin &&
                              //     icon
                              // }}
                              // errors={errors.confirmPin}
                              // touched={touched.confirmPin}
                            />
                          </Grid>
                        </Grid>
                      )}
                  </Box>
                </Box>
               { !isDeleted && <Box className="button-child">
                  <CustomButton
                    disabled={isLoading}
                  title="Submit" type="submit" />
                </Box>}
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </Box>
        : <Box className='machine-kanban-progress'>
       <CircularProgress />
     </Box>}
      {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
      )}
    </Box>
  )
}

export default CreateSetter
