import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { taskApi } from '../api/tasktype'
import { task } from '../querykeys/tasktype'

export const useAddTask = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(taskApi.addTask, {
    onSuccess: () => {
      queryClient.invalidateQueries([task.TASK])
      // navigate(`/task-type`, { state: { activeTab: "1" } });
      navigate('/task-type')
    },
    onError: (err) => {
      return err
      // const errData = err?.data;
      // toast.error(errData.message);
    }
  })
}

export const useGetTask = (id, isEnable = true) => {
  return useQuery([task.TASK, id], taskApi.getTask, {
    select: (data) => data,
    enabled: isEnable,
    onSuccess: () => {
      // const data = res?.data;
    },
    onError: () => {
      // const errData = err.data;
      // toast.error(errData?.message);
    },
    refetchOnWindowFocus: false
  })
}
export const useGetSingleTasks = (id, isEnable) => {
  return useQuery([task.SINGLE_TASKS, id], taskApi.getSingleTask, {
    enabled: isEnable,
    select: (data) => data?.data,
    // ! TODO: for future implementation toast
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    onError: (err) => {
      return err
      // const errData = err?.data;
      // toast.error(errData.message);
    }
  })
}
export const useUpdateTasks = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(taskApi.updateTask, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([task.TASK])
      queryClient.invalidateQueries([task.TASK, id])
      // ! TODO: for future implementation toast
      // toast.success(data?.message);
      // navigate(`/factory-layout`, { state: { activeTab: "1" } });
      navigate('/task-type')
    },
    onError: (err) => {
      return err
      // ! TODO: for future implementation toast
      // const errData = err.data;
      // toast.error(errData?.message);
    }
  })
}
export const useGetFullTasks = () => {
  return useQuery(
    [task.TASK + 'FULL'],
    taskApi.getFullTasks,
    {
      select: (data) => data?.data,
      // onSuccess: () => {
      //   // const data = res?.data;
      // },
      onError: (err) => {
        return err
        // const errData = err?.data;
        // toast.error(errData.message);
      }
    }
  )
}
export const useGetMachineWiseTasks = () => {
  return useQuery(
    [task.TASK + 'FULL'],
    taskApi.getMachineWiseTasks,
    {
      select: (data) => data?.data,
      onError: (err) => {
        return err
      }
    }
  )
}
export const useDeleteTaskType = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(taskApi.deleteTaskType, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([task.TASK])
      queryClient.invalidateQueries([task.TASK, id])
      // ! TODO: for future implementation toast
      // toast.success(data?.message);
      navigate('/task-type', { state: { activeTab: '1' } })
    },
    onError: () => {
      // toast.error(errData?.message);
    }
  })
}
export const useRestoreTaskType = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(taskApi.restoreTaskType, {
    onSuccess: () => {
      queryClient.invalidateQueries([task.TASKS])
      // ! TODO: for future implementation toast
      // queryClient.invalidateQueries([part.PART, id]);
      // toast.success(data.message);
      navigate('/deleted-items')
    }
    // ! TODO: for future implementation toast
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  })
}

export const useHardDeleteTaskType = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(taskApi.hardDeleteTaskType
    , {
      onSuccess: () => {
        queryClient.invalidateQueries([task.TASKS])
        navigate('/deleted-items')
      }
    // ! TODO: for future implementation toast
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    })
}

export const useGetSingleTaskTypeDelete = (id, isEnable = true) => {
  return useQuery([task.TASKS + 'DELETED', id], taskApi.getSingleTaskTypeDelete, {
    enabled: isEnable,
    select: (data) => data.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}
